import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { do_url } from '../cnst/server.cnst';
import { colors } from '../cnst/colors.cnst';
import Button from './Button';
import { parseCustomerName } from '../utils/parseCustomerName.util';
import { request } from '../utils/request';
import { downloadPdf } from '../utils/downloadPdf.util';
import { getProtocolFilename } from '../utils/getProtocolFilename.util';
import { CustomerBM, ProtocolBM, ReservoirBM } from '@bm-js/h2o-shared';
import { Link } from 'react-router';

const StyledReservoirsCompressed = styled.div`
  .single-comp-res {
    display: grid;
    grid-template-columns: 3fr 2fr 2fr 2fr;
    gap: 1rem;
    padding: 0.5rem 0;
    position: relative;
    .res-name {
      a {
        button {
          text-align: left;
        }
      }
    }
    .res-year-content {
      justify-self: flex-end;
      display: flex;
      flex-direction: column;
      .res-year-buttons {
        flex-grow: 1;
        button {
          display: flex;
          margin-left: auto;
          margin-bottom: 0.3rem;
        }
      }
    }
    select {
      margin-top: auto;
      width: initial;
      margin-bottom: 0;
    }
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: ${colors.mediumGrey};
      content: '';
    }
    &.header {
      color: ${colors.mediumGrey};
      padding-bottom: 0.7rem;
      margin-top: -1rem;
    }
    .justify-right {
      justify-self: flex-end;
    }
  }
`;

type Props = {
  customer: CustomerBM;
  customerName: string;
  linkedReservoirs?: (ReservoirBM & {
    customerName: string;
    customerId: string;
  })[];
  linkedReservoirsLoading?: boolean;
};

const ReservoirsCompressed = (props: Props) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const { customer, linkedReservoirs, linkedReservoirsLoading } = props;
  const { reservoirs } = customer;

  const [pdfMatchArray, setPdfMatchArray] = useState<string[]>([]);
  const [sortedProtocolsNew, setSortedProtocolsNew] = useState({
    [new Date().getUTCFullYear()]: [],
    [new Date().getUTCFullYear() - 1]: [],
    [new Date().getUTCFullYear() - 2]: [],
  });
  const [reservoirsAlphabetical, setReservoirsAlphabetical] = useState<
    ReservoirBM[]
  >([]);

  const sortProtocols = () => {
    let tempSortedProtocols = {
      [new Date().getUTCFullYear()]: [],
      [new Date().getUTCFullYear() - 1]: [],
      [new Date().getUTCFullYear() - 2]: [],
    };
    reservoirsAlphabetical.forEach((reservoir) => {
      Object.keys(tempSortedProtocols).forEach((key) => {
        // @ts-ignore
        tempSortedProtocols[key][
          reservoir.basicInformation.name.split(' ').join('_')
        ] = [];
        reservoir.protocols.forEach((protocol) => {
          const year = new Date(protocol.dateForInspection).getFullYear();
          if (year.toString() === key)
            // @ts-ignore
            tempSortedProtocols[key][
              reservoir.basicInformation.name.split(' ').join('_')
            ].push(protocol);
        });
      });
    });
    setSortedProtocolsNew(tempSortedProtocols);
  };
  const checkPdfMatch = () => {
    const pdfset = new Set<string>(pdfMatchArray);
    reservoirsAlphabetical.forEach((reservoir) => {
      axios
        .get<string[]>(
          `${do_url}protocols/${
            state.token
          }/get-pdf-id-match/${props.customerName
            .split(' ')
            .join('_')}/${reservoir.basicInformation.name.split(' ').join('_')}`
        )
        .then((doRes) => {
          doRes.data.forEach((point) => {
            pdfset.add(point);
            const pdfarray = Array.from(pdfset);
            setPdfMatchArray(pdfarray);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const getProtocolPdf = async (
    protocol: ProtocolBM,
    reservoir: ReservoirBM
  ) => {
    const customerName = parseCustomerName(props.customerName);
    const reservoirName = parseCustomerName(reservoir.basicInformation.name);
    const { _id, type, dateForInspection } = protocol;
    const path = `protocols/{token}/protocol-pdf/${customerName}/${reservoirName}/${_id}/${type}`;
    const { data } = await request({
      state,
      dispatch,
      path,
      isDoServer: true,
    });
    const filename = getProtocolFilename(
      reservoir.basicInformation.name,
      props.customerName,
      protocol.typeFull,
      dateForInspection
    );
    // @ts-ignore
    downloadPdf(data.data, filename, true);
  };

  useEffect(() => {
    if (reservoirs.length) {
      setReservoirsAlphabetical(
        reservoirs.sort((a, b) =>
          a.basicInformation.name.localeCompare(b.basicInformation.name, 'sv')
        )
      );
    }
  }, [reservoirs]);

  useEffect(() => {
    if (state.token && reservoirsAlphabetical.length > 0) {
      if (reservoirsAlphabetical[0]?.basicInformation?.name) {
        sortProtocols();
        checkPdfMatch();
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservoirsAlphabetical, state.token]);

  return (
    <StyledReservoirsCompressed>
      {reservoirsAlphabetical.length > 0 ? (
        <>
          <div className="single-comp-res header">
            <p className="">Namn</p>
            <p className="justify-right">{new Date().getUTCFullYear() - 2}</p>
            <p className="justify-right">{new Date().getUTCFullYear() - 1}</p>
            <p className="justify-right">{new Date().getUTCFullYear()}</p>
          </div>
          {reservoirsAlphabetical.map((reservoir) => (
            <div className="single-comp-res" key={reservoir._id}>
              <div className="res-name">
                <Link
                  to={`/reservoirs/single/?customerId=${customer._id}&reservoirId=${reservoir._id}`}
                >
                  <Button noBg>{reservoir.basicInformation.name}</Button>
                </Link>
              </div>
              {Object.keys(sortedProtocolsNew).map((key, i) => (
                <div
                  className="res-year-content"
                  key={key + reservoir.basicInformation.name + i}
                >
                  <div className="res-year-buttons">
                    {/* @ts-ignore */}
                    {sortedProtocolsNew[key][
                      reservoir.basicInformation.name.split(' ').join('_')
                      // @ts-ignore
                    ]?.map((protocol, protocolIndex) => (
                      <Button
                        onClick={() => {
                          getProtocolPdf(protocol, reservoir);
                        }}
                        className="justify-right"
                        noBg
                        disabled={pdfMatchArray.indexOf(protocol._id) === -1}
                        key={protocol._id || key + protocolIndex}
                      >
                        {protocol.type}
                      </Button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
          {linkedReservoirsLoading ? (
            <p>Laddar...</p>
          ) : (
            <>
              {linkedReservoirs?.length ? (
                <LinkedReservoirs linkedReservoirs={linkedReservoirs} />
              ) : null}
            </>
          )}
        </>
      ) : (
        <>
          {linkedReservoirsLoading ? (
            <p>Laddar...</p>
          ) : (
            <>
              {linkedReservoirs?.length ? (
                <LinkedReservoirs linkedReservoirs={linkedReservoirs} />
              ) : (
                <p>Det finns inga reservoarer ännu</p>
              )}
            </>
          )}
        </>
      )}
    </StyledReservoirsCompressed>
  );
};

export default ReservoirsCompressed;

const LinkedReservoirs = ({
  linkedReservoirs,
}: {
  linkedReservoirs: (ReservoirBM & {
    customerId: string;
    customerName: string;
  })[];
}) => {
  return (
    <div>
      {linkedReservoirs.map((reservoir) => (
        <div className="linked-reservoir single-comp-res" key={reservoir._id}>
          <div className="res-name">
            <Link
              to={`/reservoirs/single/?customerId=${reservoir.customerId}&reservoirId=${reservoir._id}`}
            >
              <Button noBg>
                {reservoir.basicInformation.name} ({reservoir.customerName})
              </Button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};
