export const getFileFromBuffer = (args: {
  buffer: number[];
  filename?: string;
  download?: boolean;
  returnUrl?: boolean;
  type: string;
}) => {
  const { buffer, filename, download = false, type, returnUrl = false } = args;
  const arrayBufferView = new Uint8Array(buffer);
  const blob = new Blob([arrayBufferView], { type });
  const urlCreator = window.URL || window.webkitURL;
  const url = urlCreator.createObjectURL(blob);
  if (returnUrl) return url;
  if (download) {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename || '';
    link.click();
  } else window.open(url);
};
