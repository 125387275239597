import axios from 'axios';
import { FormEventHandler, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { do_url } from '../cnst/server.cnst';
import { colors } from '../cnst/colors.cnst';
import Button from './Button';
import { CustomerBM, ReservoirBM } from '@bm-js/h2o-shared';
import { ActionType } from '../types/dispatch.types';
import { envService } from '../services/services';

const StyledMoveReservoirModal = styled.div`
  .customers-container {
    margin-top: 1rem;
    .move-reservoir-single-customer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: ${colors.lightGrey};
      padding: 0.5rem;
      border-radius: 8px;
      margin-bottom: 0.5rem;
      button {
        margin: 0;
      }
      p {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .search-box {
    position: relative;
    button {
      border: 1px solid ${colors.darkGrey};
      position: absolute;
      top: 0.3rem;
      right: 0.3rem;
      margin: 0;
    }
    input {
      min-width: 250px;
    }
  }
  .mbottom05 {
    margin-bottom: 0.5rem !important;
  }
`;

type Props = {
  reservoir: ReservoirBM;
  customerId: string;
};

const MoveReservoirModal = ({ reservoir, customerId }: Props) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [customers, setCustomers] = useState<CustomerBM[]>([]);
  const [loading, setLoading] = useState(false);
  const [preselectedIndex, setPreselectedIndex] = useState<number | null>(null);
  const [searchString, setSearchString] = useState('');
  const [searchHelpText, setSearchHelpText] = useState('');

  const commitMove = (newCustomer: CustomerBM) => {
    dispatch({ type: ActionType.LOADING, value: true });
    axios
      .get(`${envService.API_URL}customers/single/${customerId}`, {
        headers: {
          Authorization: 'Bearer ' + state.token,
        },
      })
      .then(({ data: oldCustomer }) => {
        if (!oldCustomer) {
          dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });

          dispatch({ type: ActionType.LOADING, value: false });
          return;
        }
        const body = {
          reservoir,
          newCustomer,
          oldCustomer,
        };

        axios
          .post(
            `${envService.API_URL}customers/move-reservoir-to-another-customer`,
            body,
            {
              headers: {
                Authorization: 'Bearer ' + state.token,
              },
            }
          )
          .then(() => {
            axios
              .post(
                `${do_url}files/${state.token}/move-reservoir-to-another-customer`,
                body
              )
              .then(() => {
                dispatch({
                  type: ActionType.ALERT,
                  content: 'Reservoaren flyttades',
                });
                setTimeout(() => {
                  window.location.replace(
                    `/reservoirs/single/?customerId=${newCustomer._id}&reservoirId=${reservoir._id}`
                  );
                }, 1000);
                // dispatch({ type: "LOADING", value: false })
              })
              .catch((err) => {
                console.log(err);
                dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
                dispatch({ type: ActionType.LOADING, value: false });
              });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
            dispatch({ type: ActionType.LOADING, value: false });
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
        dispatch({ type: ActionType.LOADING, value: false });
      });
  };

  const getCustomers: FormEventHandler = (e) => {
    e.preventDefault();
    if (searchString.length < 3)
      return setSearchHelpText('Skriv minst 3 tecken för att söka');
    setSearchHelpText('');
    setLoading(true);
    setPreselectedIndex(null);
    axios
      .get(
        `${
          envService.API_URL
        }customers/?q=${searchString}&page=${0}&sortOrder=name`,
        {
          headers: {
            Authorization: 'Bearer ' + state.token,
          },
        }
      )
      .then(({ data }) => {
        const filteredCustomers = (data.customers as CustomerBM[]).filter(
          (c) => c._id !== customerId
        );

        const sortedCustomers = filteredCustomers.sort((a, b) => {
          return a.customerInformation.name.localeCompare(
            b.customerInformation.name,
            'sv'
          );
        });
        setCustomers(sortedCustomers);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        dispatch({
          type: ActionType.ALERT,
          content: 'Kunde inte hämta kunder',
        });
      });
  };

  return (
    <StyledMoveReservoirModal>
      <p className="mbottom05">Sök efter en kund att flytta reservoaren till</p>
      <form onSubmit={getCustomers} className="search-box">
        <input
          type="text"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder="Kundnamn"
        />
        <Button small type="submit" disabled={searchString.length < 3}>
          Sök
        </Button>
      </form>
      <div className="customers-container">
        {searchHelpText && <p>{searchHelpText}</p>}
        {loading ? (
          <p>Laddar kunder...</p>
        ) : (
          customers.map((customer, i) => (
            <div className="move-reservoir-single-customer" key={customer._id}>
              <p>{customer.customerInformation.name}</p>
              <Button
                small
                look={preselectedIndex === i ? 'danger' : undefined}
                onClick={() => {
                  if (preselectedIndex === i) {
                    commitMove(customer);
                  } else {
                    setPreselectedIndex(i);
                  }
                }}
              >
                {preselectedIndex === i ? 'Säker?' : 'Flytta hit'}
              </Button>
            </div>
          ))
        )}
      </div>
    </StyledMoveReservoirModal>
  );
};

export default MoveReservoirModal;
