import styled from 'styled-components';
import React, { ChangeEventHandler } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import Breadcrumbs from './Breadcrumbs';
import BtnNoStyle from './BtnNoStyle';
import Button from './Button';
import Card from './Card';
import OverlayBox from './OverlayBox';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { do_url } from '../cnst/server.cnst';
import { colors } from '../cnst/colors.cnst';
import { ActionType } from '../types/dispatch.types';
import { CustomerBM, UserFM } from '@bm-js/h2o-shared';
import { envService } from '../services/services';
import { useQuery } from '../hooks/useQuery';
import { useNavigate } from 'react-router';
import { captureException } from '@sentry/react';
const StyledDocumentPage = styled.div<{ $context: DocumentPageContext<any> }>`
  #fileInput {
    display: none;
  }
  .select-year-sector {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    gap: 1rem;
    select {
      width: revert;
    }
    p {
      margin: 0;
      margin-bottom: 1rem;
    }
  }
  .old-section {
    padding-top: 3rem;
    h4 {
      font-size: 1.2rem;
      font-weight: 600;
    }
    .file-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .single-document-buttons-container-outer {
    position: relative;
    img {
      width: 30px;
    }
    .single-document-buttons-container-inner {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: ${colors.white};
      padding: 1rem;
      border-radius: 10px;
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      transition: 100ms ease-in-out;
      button {
        white-space: nowrap;
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }
    &:hover {
      .single-document-buttons-container-inner {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
  .order-grid {
    display: grid;
    grid-template-columns: ${({ $context }) => $context.cardGridTemplate};
    p {
      margin: 0;
    }
    &.headers {
      font-weight: 700;
      padding: 0 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .bold {
    font-weight: 700;
  }
  .send-contact-person-selected,
  .send-contact-person {
    display: flex;
    align-items: center;
    margin-top: 0.3rem;
    cursor: pointer;
  }
  .selector-unchecked,
  .selector-checked {
    margin-right: 0.5rem;
    border: 1px solid ${colors.mediumGrey};
    border-radius: 3px;
    height: 1rem;
    width: 1rem;
    position: relative;
  }
  .selector-checked:after {
    position: absolute;
    left: 0.1rem;
    top: 0.1rem;
    background: ${colors.lightBlue};
    border-radius: 50%;
    width: 0.7rem;
    height: 0.7rem;
    content: '';
  }
  @media (max-width: 1000px) {
    .order-grid {
      grid-template-columns: 1fr 1fr 20px;
      p {
        display: none;
        &.mobile {
          display: initial;
        }
      }
    }
  }
`;

export type DocumentPageCardProps<T> = {
  entry: T;
  extraActions?: DocumentPageExtraAction<T>[];
  deleteEntryInit: (v: T) => void;
  editEntryInit: (v: T) => void;
  sendEntryInit: (v: T) => void;
  downloadPdf: (v: T) => void;
  previewPdf: (v: T) => void;
  duplicateEntry: (v: T) => void;
};

export type DocumentPageContext<T> = {
  documentName: string;
  documentNamePlural: string;
  documentNameSpecific: string;
  documentKey: string;
  documentKeyPlural: string;
  internalPersonKey: keyof T;
  contactPersonKey?: keyof T;
  cardComponent: React.FC<DocumentPageCardProps<T>>;
  internalPersonKeyRequired?: boolean;
  cardGridTemplate: string;
  cardHeaders: { text: string; mobile?: boolean }[];
  pdfNeedsFullCustomer?: boolean;
  enablePdfUpload?: boolean;
  documentIdentifier?: keyof T;
  displayFileUploads?: boolean;
};

export type DocumentPageExtraAction<T> = {
  text: string;
  action: (v: T) => void;
};

type Props<T> = {
  context: DocumentPageContext<T>;
  extraActions?: DocumentPageExtraAction<T>[];
};

type Year = number | 'unspecified';

const DocumentPage = <T extends { _id: string; customer: CustomerBM }>({
  context: _context,
  extraActions,
}: Props<T>) => {
  const defaultContext: Partial<DocumentPageContext<T>> = {
    displayFileUploads: true,
  };
  const context = { ...defaultContext, ..._context };
  const query = useQuery<{ name: string; id: string; idToOpen?: string }>();
  const navigate = useNavigate();

  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [documentYears, setDocumentYears] = useState<Year[]>([]);
  const [selectedDocumentYear, setSelectedDocumentYear] = useState<
    string | number
  >('');
  const [deleteDocumentPending, setDeleteDocumentPending] = useState({
    active: false,
    document: '',
  });
  const [filesList, setFilesList] = useState([]);
  const [moveFileModal, setMoveFileModal] = useState({
    active: false,
    filename: '',
  });
  const [documents, setDocuments] = useState<T[]>([]);
  const [deletePending, setDeletePending] = useState<T | null>();

  const [sendSettings, setSendSettings] = useState<{
    active: boolean;
    doc: T | null;
    contactPersons: any[];
    selectedRecipients: any[];
  }>({
    active: false,
    doc: null,
    contactPersons: [],
    selectedRecipients: [],
  });

  const getCustomer = (id: string) => {
    const url = `${envService.API_URL}customers/single/${id}`;
    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + state.token,
      },
    };
    return axios.get(url, axiosConfig);
  };

  const downloadSingleFile = async (filename: string) => {
    dispatch({ type: ActionType.LOADING, value: true });
    try {
      const url = `${do_url}files/${state.token}/${query.name}/pdf/${context.documentKey}/${filename}/${selectedDocumentYear}`;
      const { data } = await axios.get(url);
      const arrayBuf = new Uint8Array(data.data);
      const blob = new Blob([arrayBuf]);
      const objectUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.download = filename;
      a.click();
    } catch {
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel.' });
    } finally {
      dispatch({ type: ActionType.LOADING, value: false });
    }
  };

  const moveFileToYear = async (filename: string, year: Year) => {
    if (!filename) return;
    dispatch({ type: ActionType.LOADING, value: true });
    try {
      const documentYear = selectedDocumentYear || 'unspecified';
      const url = `${do_url}files/${state.token}/rearrange-files-list/${query.name}/pdf/${context.documentKey}/${context.documentName}/${year}/${documentYear}/${filename}`;
      await axios.post(url);
      getFilesFilst();
      setMoveFileModal({
        filename: '',
        active: false,
      });
      const yearReadable = year === 'unspecified' ? '"Ej specificerat"' : year;
      dispatch({
        type: ActionType.ALERT,
        content: `Filen flyttades till ${yearReadable}`,
      });
    } catch {
      dispatch({ type: ActionType.LOADING, value: false });
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel.' });
    }
  };

  const deleteFile = async (filename: string) => {
    dispatch({ type: ActionType.LOADING, value: true });
    try {
      const url = `${do_url}files/${state.token}/${query.name}/pdf/${context.documentKey}/${filename}/${selectedDocumentYear}`;
      await axios.delete(url);
      dispatch({ type: ActionType.ALERT, content: 'Fil raderad' });
      setDeleteDocumentPending({ ...deleteDocumentPending, active: false });
      getFilesFilst();
    } catch {
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
    } finally {
      dispatch({ type: ActionType.LOADING, value: false });
    }
  };

  const previewSingleFile = async (filename: string) => {
    dispatch({ type: ActionType.LOADING, value: true });
    try {
      const url = `${do_url}files/${state.token}/${query.name}/pdf/${context.documentKey}/${filename}/${selectedDocumentYear}`;
      const { data } = await axios.get(url);
      const arrayBuf = new Uint8Array(data.data);
      const blob = new Blob([arrayBuf], { type: 'application/pdf' });
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl);
    } catch (e) {
      console.log(e);
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel.' });
    } finally {
      dispatch({ type: ActionType.LOADING, value: false });
    }
  };

  const getFilesFilst = async () => {
    try {
      const url = `${do_url}files/${state.token}/files-list/${query.name}/pdf/${context.documentKey}/${context.documentName}/${selectedDocumentYear}`;
      const { data } = await axios.get(url);
      setFilesList(data.filesArray);
      if (!selectedDocumentYear) {
        const thisYear = new Date().getFullYear();
        setSelectedDocumentYear(thisYear);
      }
      dispatch({ type: ActionType.LOADING, value: false });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: ActionType.LOADING, value: false });
    }
  };

  const populateYears = () => {
    const years = [];
    const START_YEAR = 2016;
    const DAY_TO_MS = 1000 * 60 * 60 * 24;
    const DIFF_LAST_YEAR = (30 + 31) * DAY_TO_MS;
    const currentYear = new Date(
      new Date().getTime() + DIFF_LAST_YEAR
    ).getFullYear();
    for (let year = START_YEAR; year <= currentYear; year++) {
      years.push(year);
    }
    setDocumentYears(years);
  };

  const filterUsers = (all: UserFM[]) => {
    return all.filter((u) => {
      let val = false;
      if (u.firstName === 'Anders') val = true;
      if (u.firstName === 'Jonas') val = true;
      if (u.firstName === 'Patrik') val = true;
      if (u.firstName === 'Stefan') val = true;
      if (u.firstName === 'Pontus') val = true;
      if (u.firstName === 'Per-Erik') val = true;
      if (u.firstName === 'Tobias') val = true;
      if (u.firstName === 'Tommy') val = true;
      if (u.firstName === 'Björn-Olof') val = true;
      if (u.lastName === 'Hellberg') val = false;
      return val;
    });
  };

  const createDocumentInit = async () => {
    dispatch({ type: ActionType.LOADING, value: true });
    try {
      const customersUrl = `${envService.API_URL}customers/single/${query.id}`;
      const axiosConfig = {
        headers: {
          Authorization: 'Bearer ' + state.token,
        },
      };
      const { data: customer } = await axios.get(customersUrl, axiosConfig);
      const usersUrl = `${envService.API_URL}users/auth/all-users/minimal`;
      const { data: users } = await axios.get(usersUrl, axiosConfig);
      const linkedReservoirsUrl = `${envService.API_URL}customers/linked-reservoirs`;
      const { data: linkedReservoirs } = await axios.post(
        linkedReservoirsUrl,
        { ids: customer.linkedReservoirIds },
        axiosConfig
      );
      const filteredUsers = filterUsers(users);
      const data = {
        users: filteredUsers,
        hidden: false,
        active: true,
        header: `${context.documentName} ${customer.customerInformation.name}`,
        customer: {
          ...customer,
          reservoirs: customer.reservoirs.concat(linkedReservoirs),
        },
        formData: null,
        formType: context.documentKey,
        handleSubmit: createDocument,
      };
      dispatch({ type: ActionType.SET_FORM_OVERLAY, data });
    } catch {
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
    } finally {
      dispatch({ type: ActionType.LOADING, value: false });
    }
  };

  const showMessage = (m: string) =>
    dispatch({ type: ActionType.ALERT, content: m });

  const createDocument = async (data: T, customer: CustomerBM | null) => {
    if (!customer) {
      captureException(new Error('Customer is null'));
      return;
    }
    const internalPersonArr = data[context.internalPersonKey] as
      | any[]
      | undefined;

    if (!internalPersonArr?.length && context.internalPersonKeyRequired)
      return showMessage('Välj minst en servicetekniker');
    if (
      context.contactPersonKey &&
      !(data[context.contactPersonKey] as any[] | undefined)?.length
    )
      return showMessage('Välj minst en kontaktperson');
    dispatch({ type: ActionType.LOADING, value: true });
    try {
      const body = {
        ...data,
        customer: customer._id,
        [context.internalPersonKey]: internalPersonArr?.map((t) => t._id),
      };
      const url = `${envService.API_URL}${context.documentKeyPlural}`;
      const axiosConfig = {
        headers: {
          Authorization: 'Bearer ' + state.token,
        },
      };
      const { data: mongoDocument } = await axios.post(url, body, axiosConfig);
      await saveDocumentToDatabase({
        ...body,
        _id: mongoDocument._id,
        [context.internalPersonKey]: (
          body[context.internalPersonKey] as any[] | undefined
        )?.map((t, i) => {
          return {
            _id: t,
            name: `${internalPersonArr?.[i].firstName} ${internalPersonArr?.[i].lastName}`,
            phone: internalPersonArr?.[i].phone,
            email: internalPersonArr?.[i].email,
          };
        }),
      });
      getDocuments();
      dispatch({ type: ActionType.CLOSE_FORM_OVERLAY });
      showMessage(`${context.documentNamePlural} sparades`);
    } catch (e) {
      console.log(e);
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
      dispatch({ type: ActionType.LOADING, value: false });
    }
  };

  const getDocuments = async (idToOpenEnabled?: boolean) => {
    dispatch({ type: ActionType.LOADING, value: true });
    try {
      const url = `${envService.API_URL}${context.documentKeyPlural}/${query.id}`;
      const axiosConfig = {
        headers: {
          Authorization: 'Bearer ' + state.token,
        },
      };
      const { data } = await axios.get<T[]>(url, axiosConfig);
      setDocuments(data);
      await getFilesFilst();
      const docToOpen =
        idToOpenEnabled &&
        query.idToOpen &&
        data.find((d) => d._id === query.idToOpen);
      if (docToOpen) {
        const queryParams = new URLSearchParams({
          id: query.id,
          name: query.name,
        }).toString();
        navigate(`${window.location.pathname}?${queryParams}`);
        await editDocumentInit(docToOpen);
      }
    } catch {
      dispatch({ type: ActionType.LOADING, value: false });
      showMessage(
        `Kunde inte hämta ${context.documentNamePlural?.toLowerCase()}`
      );
    }
  };

  const deleteDocumentInit = (document: T) => {
    setDeletePending(document);
  };

  const deleteDocument = async () => {
    dispatch({ type: ActionType.LOADING, value: true });
    try {
      const mongoUrl = `${envService.API_URL}${context.documentKeyPlural}/${deletePending?._id}`;
      const doUrl = `${do_url}${context.documentKeyPlural}/${state.token}/delete-document/${query.name}/${deletePending?._id}`;
      const axiosConfig = {
        headers: {
          Authorization: 'Bearer ' + state.token,
        },
      };
      await axios.delete(mongoUrl, axiosConfig);
      await axios.delete(doUrl);
      getDocuments();
      showMessage(`${context.documentNameSpecific} raderades`);
      setDeletePending(null);
    } catch (e) {
      console.log(e);
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
      dispatch({ type: ActionType.LOADING, value: false });
    }
  };

  const editDocumentInit = async (doc: T) => {
    dispatch({ type: ActionType.LOADING, value: true });
    try {
      const customersUrl = `${envService.API_URL}customers/single/${query.id}`;
      const axiosConfig = {
        headers: {
          Authorization: 'Bearer ' + state.token,
        },
      };
      const { data: customer } = await axios.get(customersUrl, axiosConfig);
      const usersUrl = `${envService.API_URL}users/auth/all-users/minimal`;
      const { data: users } = await axios.get(usersUrl, axiosConfig);
      const linkedReservoirsUrl = `${envService.API_URL}customers/linked-reservoirs`;
      const { data: linkedReservoirs } = await axios.post(
        linkedReservoirsUrl,
        { ids: customer.linkedReservoirIds },
        axiosConfig
      );
      const filteredUsers = filterUsers(users);
      const data = {
        users: filteredUsers,
        hidden: false,
        active: true,
        header: `${context.documentName} ${customer.customerInformation.name}`,
        customer: {
          ...customer,
          reservoirs: customer.reservoirs.concat(linkedReservoirs),
        },
        formData: doc,
        formType: context.documentKey,
        handleSubmit: updateDocument,
      };
      dispatch({ type: ActionType.SET_FORM_OVERLAY, data });
    } catch {
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
    } finally {
      dispatch({ type: ActionType.LOADING, value: false });
    }
  };

  const updateDocument = async (data: T) => {
    const body = {
      ...data,
      [context.internalPersonKey]: (
        data[context.internalPersonKey] as any[] | undefined
      )?.map((t) => t._id),
      customer: data.customer._id,
    };
    if (
      !(data[context.internalPersonKey] as any[] | undefined)?.length &&
      context.internalPersonKeyRequired
    )
      return showMessage('Välj minst en servicetekniker');
    if (
      context.contactPersonKey &&
      !(data[context.contactPersonKey] as any[] | undefined)?.length
    )
      return showMessage('Välj minst en kontaktperson');
    dispatch({ type: ActionType.LOADING, value: true });
    try {
      const url = `${envService.API_URL}${context.documentKeyPlural}/${data._id}`;
      const axiosConfig = {
        headers: {
          Authorization: 'Bearer ' + state.token,
        },
      };
      await axios.post(url, body, axiosConfig);
      await saveDocumentToDatabase({
        ...body,
        [context.internalPersonKey]: (
          body[context.internalPersonKey] as any[] | undefined
        )?.map((t, i) => {
          return {
            _id: t,
            name: `${(data[context.internalPersonKey] as any[])[i].firstName} ${
              (data[context.internalPersonKey] as any[])[i].lastName
            }`,
            phone: (data[context.internalPersonKey] as any[])[i].phone,
            email: (data[context.internalPersonKey] as any[])[i].email,
          };
        }),
      });
      getDocuments();
      showMessage(`${context.documentNameSpecific} uppdaterades`);
      dispatch({ type: ActionType.CLOSE_FORM_OVERLAY });
    } catch {
      dispatch({ type: ActionType.LOADING, value: false });
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
    }
  };

  const saveDocumentToDatabase = async (document: T) => {
    let customer = {};
    if (context.pdfNeedsFullCustomer) {
      const { data } = await getCustomer(query.id);
      customer = data;
    }
    const data = {
      ...document,
      customer: {
        ...customer,
        name: query.name,
        id: query.id,
        reservoirs: [],
        communications: [],
      },
    };
    const customerName = query.name.split(' ').join('_');
    const url = `${do_url}${context.documentKeyPlural}/${state.token}/create-document/${customerName}`;
    return axios.post(url, data);
  };

  const sendDocumentInit = async (doc: T) => {
    dispatch({ type: ActionType.LOADING, value: true });
    try {
      const url = `${envService.API_URL}customers/single/${query.id}`;
      const axiosConfig = {
        headers: {
          Authorization: 'Bearer ' + state.token,
        },
      };
      const { data } = await axios.get(url, axiosConfig);
      setSendSettings({
        active: true,
        doc,
        contactPersons: data.contactPersons,
        selectedRecipients: [],
      });
    } catch {
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
    } finally {
      dispatch({ type: ActionType.LOADING, value: false });
    }
  };

  const sendDocument = async () => {
    if (!sendSettings.selectedRecipients.length)
      return dispatch({
        type: ActionType.ALERT,
        content: 'Välj minst en mottagare',
      });
    if (!sendSettings.doc)
      return dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
    dispatch({ type: ActionType.LOADING, value: true });
    try {
      let doUrl = `${do_url}${context.documentKeyPlural}/${state.token}/send-document/${query.name}/${sendSettings.doc._id}`;
      const mongoUrl = `${envService.API_URL}${context.documentKeyPlural}/update-send-status/${sendSettings.doc._id}`;
      if (context.documentIdentifier)
        doUrl += `/${sendSettings.doc[context.documentIdentifier]}`;
      const axiosConfig = {
        headers: {
          Authorization: 'Bearer ' + state.token,
        },
      };
      await axios.post(doUrl, {
        selectedRecipients: sendSettings.selectedRecipients,
      });
      await axios.post(
        mongoUrl,
        {
          ...sendSettings.doc,
          sentTo: sendSettings.selectedRecipients,
          status: 'Skickat',
        },
        axiosConfig
      );
      dispatch({ type: ActionType.ALERT, content: 'E-post skickat' });
      setSendSettings({
        ...sendSettings,
        active: false,
      });
      getDocuments();
    } catch (e) {
      console.log(e);
      dispatch({ type: ActionType.LOADING, value: false });
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel.' });
    }
  };

  const previewPdf = (doc: T) => {
    let url = `${do_url}${context.documentKeyPlural}/${state.token}/get-document/${query.name}/${doc._id}`;
    if (context.documentIdentifier)
      url += `/${doc[context.documentIdentifier]}`;
    window.open(url);
  };
  const downloadPdf = (doc: T) => {
    let url = `${do_url}${context.documentKeyPlural}/${state.token}/get-document/${query.name}/${doc._id}`;
    if (context.documentIdentifier)
      url += `/${doc[context.documentIdentifier]}`;
    url += '/?download=true';
    window.open(url);
  };

  const duplicateDocument = async (doc: T) => {
    try {
      const config = { headers: { Authorization: 'Bearer ' + state.token } };
      dispatch({ type: ActionType.LOADING, value: true });
      const { data: savedDocument } = await axios.post(
        `${envService.API_URL}${context.documentKeyPlural}/duplicate/${doc._id}`,
        {},
        config
      );
      const customername = query.name.split(' ').join('_');
      const doUrl = `${do_url}${context.documentKeyPlural}/${state.token}/duplicate/${customername}/${savedDocument._id}/${doc._id}`;
      await axios.post(doUrl);
      showMessage(`${context.documentNameSpecific} duplicerades`);
      getDocuments();
    } catch {
      dispatch({ type: ActionType.LOADING, value: false });
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
    }
  };

  const readFiles: ChangeEventHandler<HTMLInputElement> = (e) => {
    const fileInput = document.querySelector<HTMLInputElement>('#fileInput');
    if (!e.target.files || e.target.files.length === 0) return;
    dispatch({ type: ActionType.LOADING_PROGRESS, progress: 1, value: true });
    uploadFiles(e.target.files);
    if (!fileInput) return;
    fileInput.value = '';
  };

  const uploadFiles = async (currentFiles: FileList) => {
    try {
      const formData = new FormData();
      for (let index = 0; index < currentFiles.length; index++) {
        formData.append(
          encodeURI(currentFiles[index].name),
          currentFiles[index]
        );
      }
      const config: AxiosRequestConfig = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 100)
          );
          dispatch({
            type: ActionType.LOADING_PROGRESS,
            progress: progress,
            value: true,
          });
        },
      };
      const customername = query.name.split(' ').join('_');
      const url = `${do_url}files/${state.token}/upload/${customername}/pdf/${context.documentKey}/${selectedDocumentYear}`;
      await axios.post(url, formData, config);
      dispatch({ type: ActionType.ALERT, content: 'Filerna har laddats upp' });
      getFilesFilst();
    } catch (err) {
      console.error((err as any).response);
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
      dispatch({
        type: ActionType.LOADING_PROGRESS,
        progress: null,
        value: false,
      });
    }
  };

  //GET FILES WHEN YEAR-SELECTOR CHANGES
  useEffect(() => {
    if (state.token) {
      getDocuments(true);
    }
    //eslint-disable-next-line
  }, [selectedDocumentYear, state.token]);

  useEffect(() => {
    if (!query.name || !state.token) return;
    populateYears();
    //eslint-disable-next-line
  }, [state.token]);

  return (
    <StyledDocumentPage $context={context}>
      <h1 className="page-header">{context.documentNamePlural}</h1>
      <Breadcrumbs
        crumbs={[
          { p: 'Hem', to: '/home' },
          { p: 'Kunder', to: '/customers' },
          {
            p: query.name.split('_').join(' '),
            to: `/customers/single/?id=${query.id}`,
          },
          { p: context.documentNamePlural },
        ]}
        rightContent={
          <div className="document-buttons-container">
            <Button small onClick={createDocumentInit}>
              Skapa {context.documentName?.toLowerCase()}
            </Button>
            {context.enablePdfUpload && (
              <>
                <input
                  type="file"
                  onChange={readFiles}
                  multiple
                  id="fileInput"
                  accept=".pdf,.pages"
                />
                <Button
                  small
                  white
                  className="documents-page-upload-button"
                  onClick={() => {
                    document.getElementById('fileInput')?.click();
                  }}
                >
                  Ladda upp filer
                </Button>
              </>
            )}
          </div>
        }
      />

      <div>
        <div className="order-grid headers">
          {context.cardHeaders.map((header, i) => (
            <p
              key={`${header}__header${i}`}
              className={header.mobile ? 'mobile' : ''}
            >
              {header.text}
            </p>
          ))}
        </div>
        {documents.length ? (
          documents.map((document) => (
            <context.cardComponent
              key={document._id}
              entry={document}
              deleteEntryInit={deleteDocumentInit}
              editEntryInit={editDocumentInit}
              sendEntryInit={sendDocumentInit}
              previewPdf={previewPdf}
              downloadPdf={downloadPdf}
              duplicateEntry={duplicateDocument}
              extraActions={extraActions}
            />
          ))
        ) : (
          <p>
            Inga {context.documentNamePlural?.toLowerCase()} har genererats.
          </p>
        )}
      </div>

      {context.displayFileUploads && (
        <div className="old-section">
          <h4>
            Tidigare uppladdade {context.documentNamePlural?.toLowerCase()}
          </h4>
          <hr />
          <div className="select-year-sector">
            <p>År</p>
            {/* eslint-disable-next-line */}
            <select
              value={selectedDocumentYear}
              onChange={(e) => setSelectedDocumentYear(e.target.value)}
            >
              <option value="" disabled>
                Välj år
              </option>
              {documentYears.map((year) => (
                <option value={year} key={`documentYear_${year}`}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          {filesList.length > 0 ? (
            filesList.map((single) => (
              <React.Fragment key={single}>
                <Card className="file-card">
                  <h5>{single}</h5>
                  <div className="single-document-buttons-container-outer">
                    <BtnNoStyle>
                      <img src="/icons/3-dots.png" alt="" />
                    </BtnNoStyle>
                    <div className="single-document-buttons-container-inner">
                      <Button small onClick={() => downloadSingleFile(single)}>
                        Ladda ned
                      </Button>
                      <Button small onClick={() => previewSingleFile(single)}>
                        Förhandsvisa
                      </Button>
                      <Button
                        small
                        onClick={() =>
                          setMoveFileModal({
                            active: true,
                            filename: single,
                          })
                        }
                      >
                        Flytta fil
                      </Button>
                      {(state.user as UserFM).role === 'Admin' ? (
                        <Button
                          small
                          look="danger"
                          onClick={() =>
                            setDeleteDocumentPending({
                              active: true,
                              document: single,
                            })
                          }
                        >
                          Radera
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </Card>
              </React.Fragment>
            ))
          ) : (
            <p>Det finns inga filer här.</p>
          )}
        </div>
      )}
      <OverlayBox
        header={`Radera filen ${deleteDocumentPending.document}?`}
        active={deleteDocumentPending.active}
        close={() =>
          setDeleteDocumentPending({
            ...deleteDocumentPending,
            active: false,
          })
        }
      >
        <p>
          Vill du <strong>permanent radera </strong> filen{' '}
          {deleteDocumentPending.document}?
        </p>
        <p>
          Det går <strong>inte</strong> att återskapa en raderad fil.
        </p>
        <Button
          look="danger"
          med
          onClick={() => deleteFile(deleteDocumentPending.document)}
        >
          Radera
        </Button>
        <Button
          med
          onClick={() =>
            setDeleteDocumentPending({
              ...deleteDocumentPending,
              active: false,
            })
          }
        >
          Tillbaka
        </Button>
      </OverlayBox>
      <OverlayBox
        active={moveFileModal.active}
        close={() =>
          setMoveFileModal({
            filename: '',
            active: false,
          })
        }
        header="Flytta fil"
      >
        <p>Vart fill du flytta filen?</p>
        {/* eslint-disable-next-line */}
        <select
          value={selectedDocumentYear}
          onChange={(e) => {
            moveFileToYear(moveFileModal.filename, e.target.value as Year);
          }}
        >
          <option disabled>Välj år</option>
          {documentYears.map((year) => (
            <option value={year} key={`moveFileModal_${year}`}>
              {year}
            </option>
          ))}
        </select>
      </OverlayBox>
      <OverlayBox
        close={() => setDeletePending(null)}
        active={!!deletePending}
        header={`Radera ${context.documentName?.toLowerCase()}`}
      >
        <p>
          Är du säker på att du vill permanent radera{' '}
          {context.documentNameSpecific?.toLowerCase()}?
          <br />
          Åtgärden går inte att ångra.
        </p>
        <Button small look="danger" onClick={deleteDocument}>
          Radera
        </Button>
        <Button small onClick={() => setDeletePending(null)}>
          Avbryt
        </Button>
      </OverlayBox>
      <OverlayBox
        active={sendSettings.active}
        header={`Skicka ${context.documentName?.toLowerCase()}`}
        close={() =>
          setSendSettings({
            ...sendSettings,
            active: false,
          })
        }
      >
        <p>
          Välj vilka kontaktpersoner du vill skicka{' '}
          {context.documentNameSpecific?.toLowerCase()} till.
        </p>
        <br />
        {sendSettings.contactPersons.map((person, i) => (
          // eslint-disable-next-line
          <div
            key={person._id}
            className="send-contact-person"
            onClick={() => {
              const updatedContactPersons = [...sendSettings.contactPersons];
              const updatedSelectedRecipients = [
                ...sendSettings.selectedRecipients,
              ];
              const [reordered] = updatedContactPersons.splice(i, 1);
              updatedSelectedRecipients.splice(0, 0, reordered);
              setSendSettings({
                ...sendSettings,
                contactPersons: updatedContactPersons,
                selectedRecipients: updatedSelectedRecipients,
              });
            }}
          >
            <div className="selector-unchecked" />
            {person.name}
          </div>
        ))}
        <br />
        <p className="bold">Skickas till</p>

        {sendSettings.selectedRecipients.length ? (
          sendSettings.selectedRecipients.map((person, i) => (
            // eslint-disable-next-line
            <div
              key={person._id}
              className="send-contact-person-selected"
              onClick={() => {
                const updatedContactPersons = [...sendSettings.contactPersons];
                const updatedSelectedRecipients = [
                  ...sendSettings.selectedRecipients,
                ];
                const [reordered] = updatedSelectedRecipients.splice(i, 1);
                updatedContactPersons.splice(0, 0, reordered);
                setSendSettings({
                  ...sendSettings,
                  contactPersons: updatedContactPersons,
                  selectedRecipients: updatedSelectedRecipients,
                });
              }}
            >
              <div className="selector-checked" />
              {person.email}
            </div>
          ))
        ) : (
          <p>Inga mottagare valda</p>
        )}
        <br />
        <Button small onClick={sendDocument}>
          Skicka
        </Button>
        <Button
          small
          onClick={() =>
            setSendSettings({
              ...sendSettings,
              active: false,
            })
          }
        >
          Avbryt
        </Button>
      </OverlayBox>
    </StyledDocumentPage>
  );
};

export default DocumentPage;
