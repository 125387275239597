import { useEffect, useMemo } from 'react';
import { CheckCircle, XCircle } from 'lucide-react';
import { PasswordRequirementKey } from '../types/user.types';
import styled from 'styled-components';
import { colors } from '../cnst/colors.cnst';

type RequirementEntry = {
  desc: string;
  key: PasswordRequirementKey;
  check: (p: string, p2?: string) => boolean;
};

const _passwordRequirements: RequirementEntry[] = [
  {
    desc: 'Minst 10 tecken',
    key: PasswordRequirementKey.TenChars,
    check: (p) => p.length >= 10,
  },
  {
    desc: 'Minst en stor bokstav',
    key: PasswordRequirementKey.OneCapital,
    check: (p) => /.*[A-Z].*/.test(p),
  },
  {
    desc: 'Minst en liten bokstav',
    key: PasswordRequirementKey.OneLower,
    check: (p) => /.*[a-z].*/.test(p),
  },
  {
    desc: 'Minst en siffra',
    key: PasswordRequirementKey.OneNumber,
    check: (p) => /.*[0-9].*/.test(p),
  },
  {
    desc: 'Lösenorden matchar',
    key: PasswordRequirementKey.SamePass,
    check: (p, p2) => !!p && !!p2 && p === p2,
  },
];

const StyledPasswordRequirements = styled.div`
  h4 {
    margin: 0 !important;
    margin-bottom: 1rem !important;
  }
  li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }

  .stroke-green-500 {
    color: ${colors.lightBlue};
  }

  .stroke-red-500 {
    color: ${colors.red};
  }
`;

type Props = {
  newPassword: string;
  confirmPassword?: string;
  setPasswordValid: (valid: boolean) => void;
  omitted?: PasswordRequirementKey[];
};

const PasswordRequirements = ({
  newPassword,
  confirmPassword,
  setPasswordValid,
  omitted,
}: Props) => {
  const passwordRequirements = useMemo(() => {
    if (!omitted) return _passwordRequirements;
    return _passwordRequirements.filter((r) => !omitted.includes(r.key));
  }, [omitted]);

  const requirements = useMemo(() => {
    if (typeof confirmPassword === 'string') return passwordRequirements;
    return passwordRequirements.filter(
      (r) => r.key !== PasswordRequirementKey.SamePass
    );
  }, [confirmPassword, passwordRequirements]);

  const passwordFullfillment = useMemo(() => {
    return requirements.reduce(
      (acc, curr) => {
        acc[curr.key] = curr.check(newPassword, confirmPassword);
        return acc;
      },
      {} as Record<string, boolean>
    );
  }, [newPassword, confirmPassword, requirements]);

  const passwordValid = useMemo(() => {
    if (
      Object.keys(passwordFullfillment).some((k) => !passwordFullfillment[k])
    ) {
      return true;
    }
    return false;
  }, [passwordFullfillment]);

  useEffect(() => setPasswordValid(!passwordValid), [passwordValid]);

  return (
    <StyledPasswordRequirements>
      <h4>Lösenordskrav</h4>
      <ul>
        {requirements.map((req) => (
          <li key={req.key}>
            {passwordFullfillment[req.key] ? (
              <CheckCircle width={24} className="stroke-green-500" />
            ) : (
              <XCircle width={24} className="stroke-red-500" />
            )}
            <span>{req.desc}</span>
          </li>
        ))}
      </ul>
    </StyledPasswordRequirements>
  );
};

export default PasswordRequirements;
