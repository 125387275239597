import { useContext, useEffect } from 'react';
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from '../context/GlobalContextProvider';
import { request } from '../utils/request';
import { ActionType } from '../types/dispatch.types';
import { useLocation } from 'react-router';

export const usePollNotifications = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const location = useLocation();

  const getNotifications = async () => {
    if (!state.token) return;
    const { err, data } = await request<{
      numberOfUnreadNotifications: number;
    }>({
      method: 'GET',
      state,
      path: 'notifications/get-number-of-unread-notifications',
      skipLoader: true,
    });

    if (err || !data) return;
    dispatch({
      type: ActionType.SET_NUMBER_OF_UNREAD_NOTIFICATIONS,
      value: data.numberOfUnreadNotifications,
    });
  };

  useEffect(() => {
    const interval = setInterval(getNotifications, 300_000); // 5 minutes in milliseconds
    return () => clearInterval(interval);
  }, [state.token]);

  useEffect(() => {
    getNotifications();
  }, [location.pathname]);
};
