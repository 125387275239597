import { useMemo } from 'react';
import styled from 'styled-components';
import Button from './Button';
import Card from './Card';
import { normalizeDate } from '../utils/parseDate.util';
import { CustomerBM } from '@bm-js/h2o-shared';

const StyledCommunicationLogCard = styled.div`
  display: flex;
  flex-direction: column;
  p.communication-comment {
    margin-top: 0.2rem;
  }
  .coms-logs-action-buttons {
    display: flex;
    margin-left: auto;
  }
`;

type Props = {
  communication: CustomerBM['communications'][0];
  displayActions?: boolean;
  editLog?: (communication: CustomerBM['communications'][0]) => void;
  deleteLog?: (id: string) => void;
};

const CommunicationLogCard = ({
  communication,
  displayActions,
  editLog,
  deleteLog,
}: Props) => {
  const { _id, person, contactPerson, comment, date } = communication;
  const personText = useMemo(() => {
    if (!contactPerson) return person;
    return `${person} -> ${contactPerson}`;
  }, [person, contactPerson]);
  return (
    <StyledCommunicationLogCard>
      <Card inner>
        <p className="light">
          {normalizeDate(date)}
          <br />
          {personText}
        </p>
        <p className="communication-comment">
          {comment.split('\n').map((s, i) => (
            <span key={s + i}>
              {s}
              <br />
            </span>
          ))}
        </p>
      </Card>
      {displayActions && (
        <div className="coms-logs-action-buttons">
          <Button small onClick={() => editLog?.(communication)}>
            Redigera
          </Button>
          <Button small look="danger" onClick={() => deleteLog?.(_id)}>
            Radera
          </Button>
        </div>
      )}
    </StyledCommunicationLogCard>
  );
};

export default CommunicationLogCard;
