import { useMemo } from 'react';
import styled from 'styled-components';
import ImageViewer from './ImageViewer';
import FileUploadButton from './FileUploadButton';
import { colors } from '../cnst/colors.cnst';
import Button from './Button';
import { allProtocolResultKeys } from '../cnst/protocolConfig.cnst';
import { MediaContextSelection } from './MediaLibrary';
import { MediaItemFM } from '@bm-js/h2o-shared';

const StyledProtocolImage = styled.div`
  border: 1px solid ${colors.darkGrey};
  border-radius: 6px;
  margin-bottom: 0.5rem;
  background: ${colors.lightGrey};
  .actions {
    border-top: 1px solid ${colors.darkGrey};
    padding: 0.5rem;
    display: flex;
    place-content: flex-end;
    gap: 1rem;
  }
  .image {
    max-height: 200px;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
  .no-selection {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    text-align: center;
  }
`;

type Props = {
  src: string;
  onChange: (value: string) => void;
  handleDelete: () => void;
  context: MediaContextSelection;
};

const ProtocolImage = ({ src, onChange, handleDelete, context }: Props) => {
  const onSelect = (file: MediaItemFM | MediaItemFM[]) => {
    if (Array.isArray(file)) return;
    if (!file.uuid) return;
    onChange(file.uuid);
  };

  const normalizedSrc = useMemo(() => {
    if (allProtocolResultKeys.includes(src)) return '';
    return src;
  }, [src]);

  return (
    <StyledProtocolImage>
      {normalizedSrc ? (
        <ImageViewer assetId={normalizedSrc} className="image" overlayOnClick />
      ) : (
        <div className="no-selection">
          <p>Ingen bild vald</p>
        </div>
      )}
      <div className="actions">
        <FileUploadButton
          buttonText={normalizedSrc ? 'Byt bild' : 'Välj bild'}
          type={'image'}
          context={context}
          onSelect={onSelect}
        />
        <Button noBg look="danger" onClick={handleDelete} type={'button'}>
          Ta bort
        </Button>
      </div>
    </StyledProtocolImage>
  );
};

export default ProtocolImage;
