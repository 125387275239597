import { useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import DotsDropdown from './DotsDropdown';
import { parseDate } from '../utils/parseDate.util';
import DeleteConfirmation from './DeleteConfirmation';
import { parseSize } from '../utils/parseSize.util';
import { request } from '../utils/request';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { getFileFromBuffer } from '../utils/getFileFromBuffer.util';
import OverlayBox from './OverlayBox';
import { protocolMap } from '../cnst/data.cnst';
import { do_url } from '../cnst/server.cnst';
import { colors } from '../cnst/colors.cnst';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import ImageViewer from './ImageViewer';
import Checkbox from './Checkbox';
import FileThumbnailFallback from './FileThumbnailFallback';
import { MediaItemFM } from '@bm-js/h2o-shared';

const StyledFileCard = styled.div<{ $onSelect: boolean }>`
  display: grid;
  grid-template-columns: ${({ $onSelect }) =>
      $onSelect ? '' : '15px'} 100px 3fr 1fr 1fr 20px;
  gap: 0.5rem;
  align-items: center;
  background: white;
  padding: 0.8rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  p {
    white-space: nowrap;
    text-overflow: hidden;
  }
  .filecard-name-and-context {
    overflow: hidden;
    text-overflow: ellipsis;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .filename {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .file-context {
    font-size: 0.8rem;
    padding-top: 0.3rem;
    color: ${colors.mediumGrey};
  }
  .file-card-buttons-container {
    justify-self: flex-end;
  }
  ul {
    margin-top: 1rem;
  }
  .filecard-image {
    background: ${colors.lightGrey};
    border-radius: 4px;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  @media (max-width: 1000px) {
    grid-template-columns: ${({ $onSelect }) =>
        $onSelect ? '' : '15px'} 100px 1fr ${({ $onSelect }) =>
        $onSelect ? '5rem' : '20px'};
  }
`;

export type MediaContext = {
  _id: string;
  name: string;
  reservoirs: {
    _id: string;
    name: string;
    linkedFrom?: string;
    protocols: {
      _id: string;
      dateForInspection: string;
      type: 'insp' | 'kont' | 'RR' | 'MR';
    }[];
  }[];
};

type Props = {
  file: MediaItemFM;
  deleteFile: (uuid: string) => Promise<void>;
  onSelect?: (v: MediaItemFM) => void;
  changeFilename: (uuid: string, newFilename: string, cb: () => void) => void;
  contexts: MediaContext[];
  changeFileContextInit: (v: MediaItemFM) => void;
  batchSelection: string[];
  setBatchSelection: (v: string[]) => void;
  multiple?: boolean;
};

const FileCard = ({
  file,
  deleteFile,
  onSelect,
  changeFilename,
  contexts,
  changeFileContextInit,
  batchSelection,
  setBatchSelection,
  multiple = false,
}: Props) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const {
    name,
    uploadedAt,
    uuid,
    size,
    type,
    customer,
    reservoir,
    protocol,
    isS3,
  } = file;
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [usedInModal, setUsedInModal] = useState<string[]>([]);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [newFilename, setNewFilename] = useState('');
  const ref = useRef(null);
  const entry = useIntersectionObserver(ref, { freezeOnceVisible: true });

  const getGeneralFile = async (download: boolean) => {
    const { err, data } = await request<any>({
      state,
      dispatch,
      path: `media/{token}/${uuid}`,
      isDoServer: true,
    });
    if (err) return;
    getFileFromBuffer({
      buffer: data.data.data,
      filename: name,
      download,
      type,
    });
  };

  const getVideoFile = async (download: boolean) => {
    let path = `media/${state.token}/${uuid}`;
    if (download) path += '?download=true';
    if (isS3) {
      const { data: url } = await request<string>({
        state,
        dispatch,
        path,
        isDoServer: true,
      });
      if (!url) return;
      window.open(url);
      return;
    }
    const url = `${do_url}${path}`;
    window.open(url);
  };

  const getFile = async (download: boolean) => {
    if (type.includes('video')) getVideoFile(download);
    else getGeneralFile(download);
  };

  const checkIfFileIsUsed = async () => {
    const { err, data } = await request<string[]>({
      state,
      dispatch,
      path: `media/is-used/${uuid}`,
    });
    if (err) return;
    if (data?.length) return setUsedInModal(data);
    setConfirmationOpen(true);
  };

  const changeNameInit = () => {
    setNewFilename(name);
    setRenameModalOpen(true);
  };

  const _changeFilename = () => {
    changeFilename(uuid, newFilename, () => {
      setRenameModalOpen(false);
      setNewFilename('');
    });
  };

  const handleCheckbox = () => {
    const updated = [...batchSelection];
    const index = batchSelection.indexOf(uuid);
    if (index === -1) {
      updated.push(uuid);
    } else {
      updated.splice(index, 1);
    }
    setBatchSelection(updated);
  };

  const fileContext = useMemo(() => {
    let str = '';

    if (!customer) return 'Utan koppling';
    const customerObj = contexts.find((c) => c._id === customer);
    if (!customerObj) return 'Utan koppling';
    str += customerObj.name;

    if (!reservoir) return str;
    const reservoirObj = customerObj.reservoirs.find(
      (r) => r._id === reservoir
    );
    if (!reservoirObj) return str;
    str += ` -> ${reservoirObj.name}`;

    if (!protocol) return str;
    const protocolObj = reservoirObj.protocols.find((p) => p._id === protocol);
    if (!protocolObj) return str;
    const date = new Date(protocolObj.dateForInspection);
    const month = date.getMonth() + 1;
    const paddedMonth = month > 9 ? month : `0${month}`;
    str += ` -> ${date.getFullYear()} ${paddedMonth} ${
      protocolMap[protocolObj.type]
    }`;

    return str;
  }, [contexts, customer, reservoir, protocol]);

  const isSelected = useMemo(() => {
    return batchSelection.includes(uuid);
  }, [uuid, batchSelection]);

  const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png'];

  return (
    <StyledFileCard
      id={`fileCard_${uuid}`}
      ref={ref}
      $onSelect={!!onSelect && !(!!onSelect && multiple)}
    >
      {(!onSelect || (!!onSelect && multiple)) && (
        <Checkbox
          id={`batch_${uuid}`}
          checked={isSelected}
          onChange={handleCheckbox}
        />
      )}
      <div className="filecard-image-container">
        {entry?.isIntersecting && ALLOWED_IMAGE_TYPES.includes(type) ? (
          <ImageViewer
            type={type}
            assetId={uuid}
            overlayOnClick
            className="filecard-image"
          />
        ) : (
          <FileThumbnailFallback />
        )}
      </div>
      <div className="filecard-name-and-context">
        <p
          className="filename"
          title={name}
          onClick={() => {
            if (type.includes('video')) return;
            getFile(false);
          }}
        >
          {name}
        </p>
        <p className="file-context">{fileContext}</p>
      </div>
      <p className="hide-mobile">{parseDate(uploadedAt)}</p>
      <p className="hide-mobile">{parseSize(size)}</p>
      {!multiple && (
        <div className="file-card-buttons-container">
          {onSelect ? (
            <Button small onClick={() => onSelect(file)}>
              Välj
            </Button>
          ) : (
            <DotsDropdown
              content={
                <>
                  <Button small onClick={() => getFile(true)}>
                    Ladda ned
                  </Button>
                  <Button
                    small
                    onClick={() => getFile(false)}
                    disabled={type.includes('video') && !isS3}
                  >
                    Öppna
                  </Button>
                  <Button small onClick={changeNameInit}>
                    Byt namn
                  </Button>
                  <Button small onClick={() => changeFileContextInit(file)}>
                    Ändra koppling
                  </Button>
                  <Button small look="danger" onClick={checkIfFileIsUsed}>
                    Radera
                  </Button>
                </>
              }
            />
          )}
        </div>
      )}
      {!!usedInModal.length && (
        <OverlayBox
          active
          header="Filen används"
          close={() => setUsedInModal([])}
        >
          <p>
            Filen används på följande plats/platser. Ta bort bilden från dessa
            platser innan du raderar filen.
          </p>
          <ul>
            {usedInModal.map((item, i) => (
              <li key={`usedIn_${item}_${i}`}>- {item}</li>
            ))}
          </ul>
        </OverlayBox>
      )}
      {confirmationOpen && (
        <DeleteConfirmation
          close={() => setConfirmationOpen(false)}
          commit={() => deleteFile(uuid)}
          item={file}
          itemIdentifier={name}
          itemName="fil"
        />
      )}
      {renameModalOpen && (
        <OverlayBox
          active
          header="Byt namn på fil"
          close={() => setRenameModalOpen(false)}
        >
          <input
            type="text"
            value={newFilename}
            onChange={(e) => setNewFilename(e.target.value)}
            placeholder="Nytt filnamn"
          />
          <Button small onClick={_changeFilename}>
            Spara
          </Button>
          <Button small look="danger" onClick={() => setRenameModalOpen(false)}>
            Avbryt
          </Button>
        </OverlayBox>
      )}
    </StyledFileCard>
  );
};

export default FileCard;
