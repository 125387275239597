import { ProtocolType } from '../types/protocol.types';

const insp = {
  resultsInner: [
    {
      key: 'watersurface',
      placeholder: 'Vattenyta - ytfilm',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'watersurfaceFloating',
      placeholder: 'Vattenyta - Flytande föremål',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'bottomsurface',
      placeholder: 'Bottenyta - Sediment',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'bottomsurfaceLoose',
      placeholder: 'Bottenyta - Lösa föremål',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'bottomsurfaceOrganic',
      placeholder: 'Bottenyta - Organisk aktivitet',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'bottomsurfaceCracks',
      placeholder: 'Bottenyta - Sprickbildning',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'bottomsurfaceFeeding',
      placeholder: 'Bottenyta - Infodring',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'bottomsurfacePipe',
      placeholder: 'Bottenyta - Tvärgående bottenrör',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'roofsurface',
      placeholder: 'Takyta - Inläckage',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'roofsurfaceCracks',
      placeholder: 'Takyta - Sprickbildning',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'roofsurfaceRustbleed',
      placeholder: 'Takyta - Rostblödning',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'wallsurface',
      placeholder: 'Väggyta - Inläckage',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'wallsurfaceSediment',
      placeholder: 'Väggyta - Sediment',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'wallsurfaceCracks',
      placeholder: 'Väggyta - Sprickbildning',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'wallsurfaceRustbleed',
      placeholder: 'Väggyta - Rostblödning',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'wallsurfaceFeeding',
      placeholder: 'Väggyta - Infodring',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'ladder',
      placeholder: 'Stege',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'levelIndicator',
      placeholder: 'Nivågivare',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'pipegallery',
      placeholder: 'Rörgalleri',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'widesewage',
      placeholder: 'Bräddavlopp',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'waterlock',
      placeholder: 'Bräddavlopp - Vattenlås/luftlås',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'rootintrusion',
      placeholder: 'Rotintrång',
      report: '',
      recommendation: '',
      image: '',
    },
  ],
  resultsOuter: [
    {
      key: 'manhatchneck',
      placeholder: 'Manluckehals - Inläckage',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'manhatchneckOrganic',
      placeholder: 'Manluckehals - Organiskt material',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'manhatchneckOther',
      placeholder: 'Manluckehals - Övrigt',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'manhatch',
      placeholder: 'Manluckan',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'manhatchNear',
      placeholder: 'Manluckan - Närområdet',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'throughputs',
      placeholder: 'Genomföringar',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'aerationouter',
      placeholder: 'Ventilation/luftning',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'pollinationfilter',
      placeholder: 'Ventilation - Filteranordning/trycksäkring',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'roofSealing',
      placeholder: 'Tak/tätskikt',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'roofwells',
      placeholder: 'Takbrunnar/spygatter',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'masts',
      placeholder: 'Master/stag',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'ladderOuter',
      placeholder: 'Trappor/stegar/skyddsräcken',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'outsideFasad',
      placeholder: 'Utsida reservoar/fasad',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'valvechamber',
      placeholder: 'Ventilkammare - Rör/ventiler',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'compressor',
      placeholder: 'Kompressor/elverk/batteri',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'chemicals',
      placeholder: 'Kemikalier',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'workenvironment',
      placeholder: 'Arbetsmiljö - El, belysning, säkerhetsutrustning',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'testpointDrinkingwater',
      placeholder: 'Provtagningspunkt dricksvatten',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'windowbars',
      placeholder: 'Fönstergaller',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'shellprotection',
      placeholder: 'Skalskydd - Larm, staket, bom',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'vegetation',
      placeholder: 'Vegetation',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'localcare',
      placeholder: 'Lokalvård',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'operatingInstruction',
      placeholder: 'Driftinstruktion/dokumentation',
      report: '',
      recommendation: '',
      image: '',
    },
  ],
};

const kont = {
  resultsInner: [
    {
      key: 'watersurface',
      placeholder: 'Vattenyta - ytfilm',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'bottomsurface',
      placeholder: 'Bottenyta - Sediment',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'bottomsurfaceOrganic',
      placeholder: 'Bottenyta - Organisk aktivitet',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'roofsurface',
      placeholder: 'Takyta - Inläckage',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'wallsurface',
      placeholder: 'Väggyta - Inläckage',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'wallsurfaceSediment',
      placeholder: 'Väggyta - Sediment',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'other',
      placeholder: 'Övrigt',
      report: '',
      recommendation: '',
      image: '',
    },
  ],
  resultsOuter: [
    {
      key: 'manhatchLeaks',
      placeholder: 'Manluckehals - Inläckage',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'manhatch',
      placeholder: 'Manluckan',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'manhatchNear',
      placeholder: 'Manluckan - Närområdet',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'throughputs',
      placeholder: 'Genomföringar',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'aerationouter',
      placeholder: 'Ventilation/luftning',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'pollinationfilter',
      placeholder: 'Ventilation - Filteranordning/trycksäkring',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'rooffloorouter',
      placeholder: 'Takbrunnar/spygatter',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'otherouter',
      placeholder: 'Övrigt',
      report: '',
      recommendation: '',
      image: '',
    },
  ],
};

const MR = {
  resultsInner: [
    {
      key: 'result',
      placeholder: 'Resultat',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'other',
      placeholder: 'Övrigt',
      report: '',
      recommendation: '',
      image: '',
    },
  ],
};

const RR = {
  resultsInner: [
    {
      key: 'sedimentlayercoverage',
      placeholder: 'Sedimentlager - Täckning',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'sedimentlayerdepth',
      placeholder: 'Sedimentlager - Djup',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'allbottomareacleaned',
      placeholder: 'Hela bottenytan kunde rengöras',
      report: '',
      recommendation: '',
      image: '',
    },
    {
      key: 'note',
      placeholder: 'Notering',
      report: '',
      recommendation: '',
      image: '',
    },
  ],
};

export const protocolConfig: Record<ProtocolType, any> = {
  [ProtocolType.INSP]: insp,
  [ProtocolType.KONT]: kont,
  [ProtocolType.MR]: MR,
  [ProtocolType.RR]: RR,
};

export const protocolHasDualImage: Record<ProtocolType, boolean> = {
  [ProtocolType.INSP]: false,
  [ProtocolType.KONT]: false,
  [ProtocolType.MR]: true,
  [ProtocolType.RR]: true,
};

export const allProtocolResultKeys = [
  'mainImage',
  'secondaryImage',
  'watersurface',
  'watersurfaceFloating',
  'bottomsurface',
  'bottomsurfaceLoose',
  'bottomsurfaceOrganic',
  'bottomsurfaceCracks',
  'bottomsurfaceFeeding',
  'bottomsurfacePipe',
  'roofsurface',
  'roofsurfaceCracks',
  'roofsurfaceRustbleed',
  'wallsurface',
  'wallsurfaceSediment',
  'wallsurfaceCracks',
  'wallsurfaceRustbleed',
  'wallsurfaceFeeding',
  'ladder',
  'pipegallery',
  'widesewage',
  'waterlock',
  'rootintrusion',
  'aeration',
  'levelindicator',
  'manhatchneck',
  'manhatchneckOrganic',
  'manhatchneckOther',
  'manhatch',
  'manhatchNear',
  'throughputs',
  'aerationouter',
  'pollinationfilter',
  'roofSealing',
  'roofwells',
  'masts',
  'ladderOuter',
  'outsideFasad',
  'valvechamber',
  'compressor',
  'chemicals',
  'workenvironment',
  'testpointDrinkingwater',
  'windowbars',
  'shellprotection',
  'vegetation',
  'localcare',
  'operatingInstruction',
  'watersurface',
  'bottomsurface',
  'bottomsurfaceOrganic',
  'roofsurface',
  'wallsurface',
  'wallsurfaceSediment',
  'other',
  'manhatchLeaks',
  'manhatch',
  'manhatchNear',
  'throughputs',
  'aerationouter',
  'pollinationfilter',
  'rooffloorouter',
  'otherouter',
  'result',
  'other',
  'sedimentlayercoverage',
  'sedimentlayerdepth',
  'allbottomareacleaned',
  'note',
];
