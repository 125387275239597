import { useContext } from 'react';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import styled from 'styled-components';
import { colors } from '../cnst/colors.cnst';

const StyledNotificationBadge = styled.div`
  position: absolute;
  z-index: 1;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${colors.red};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  font-size: 0.8rem;
`;

const NotificationBadge = () => {
  const { numberOfUnreadNotifications } = useContext(GlobalStateContext);
  if (!numberOfUnreadNotifications) return null;
  return (
    <StyledNotificationBadge>
      {numberOfUnreadNotifications}
    </StyledNotificationBadge>
  );
};

export default NotificationBadge;
