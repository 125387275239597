import { useMemo } from 'react';
import styled from 'styled-components';
import Button from './Button';
import DataList from './DataList';
import { MediaContext } from './FileCard';
import { MediaContextSelection } from './MediaLibrary';
import { BaseEvent } from '@bm-js/h2o-shared';
import { protocolMap } from '../cnst/data.cnst';
import { Link } from 'react-router';

const StyledFileContextSelector = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  select,
  input {
    margin-bottom: 0.3rem;
  }
  input {
    padding: 0.55rem;
  }
  .single-context-container {
    margin-bottom: 0.5rem;
    .context-link-placeholder {
      height: 0.5rem;
    }
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

type Props = {
  disableContextSwitching?: boolean;
  contexts: MediaContext[];
  contextSelection: MediaContextSelection;
  setContextSelection: (v: MediaContextSelection) => void;
  isChangeContext?: boolean;
  isModal?: boolean;
};

const FileContextSelector = ({
  disableContextSwitching,
  contexts,
  contextSelection,
  setContextSelection,
  isChangeContext,
  isModal,
}: Props) => {
  const handleChangeSelection = (
    e: BaseEvent,
    context: keyof MediaContextSelection
  ) => {
    const updated = { ...contextSelection, [context]: e.target.value };
    if (context === 'customer') {
      updated.protocol = '';
      updated.reservoir = '';
    }
    if (context === 'reservoir') {
      updated.protocol = '';

      const reservoir = currentReservoirs.find(
        (res) => res._id === e.target.value
      );
      if (reservoir?.linkedFrom) {
        updated.customer = reservoir.linkedFrom;
      }
    }
    setContextSelection(updated);
  };

  const currentReservoirs = useMemo(() => {
    return (
      contexts.find((c) => c._id === contextSelection.customer)?.reservoirs ||
      []
    );
  }, [contextSelection.customer, contexts]);

  const currentProtocols = useMemo(() => {
    const protocols =
      currentReservoirs.find((r) => r._id === contextSelection.reservoir)
        ?.protocols || [];
    return protocols.map((p) => {
      const date = new Date(p.dateForInspection);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const paddedMonth = month > 9 ? month : `0${month}`;
      return {
        ...p,
        name: `${year} ${paddedMonth} ${protocolMap[p.type]}`,
      };
    });
  }, [contextSelection.reservoir, currentReservoirs]);

  const contextLinks = useMemo(() => {
    if (
      isChangeContext ||
      isModal ||
      contextSelection.customer === 'noContext'
    ) {
      return {};
    }
    const protocol = currentProtocols.find(
      (p) => p._id === contextSelection.protocol
    );
    const reservoir = currentReservoirs.find(
      (r) => r._id === contextSelection.reservoir
    );
    const customer = contexts.find((c) => c._id === contextSelection.customer);

    return {
      customer: contextSelection.customer
        ? `/customers/single?id=${contextSelection.customer}`
        : null,
      reservoir: contextSelection.reservoir
        ? `/reservoirs/single?customerId=${contextSelection.customer}&reservoirId=${contextSelection.reservoir}`
        : null,
      protocol: protocol
        ? `/create-protocol/${protocol.type}?customerId=${
            customer?._id
          }&customerName=${customer?.name}&reservoirId=${
            reservoir?._id
          }&reservoirName=${reservoir?.name}&type=${
            protocolMap[protocol.type] + ';' + protocol.type
          }&protocolId=${protocol._id}`
        : null,
    };
  }, [
    isChangeContext,
    contextSelection,
    isModal,
    contexts,
    currentProtocols,
    currentReservoirs,
  ]);

  return (
    <StyledFileContextSelector>
      <div className="single-context-container">
        <DataList
          disabled={disableContextSwitching}
          value={contextSelection.customer}
          items={contexts}
          identifier="mediaLibCustomerSelect"
          itemKey={'_id'}
          itemNameKey={'name'}
          onChange={(e) => handleChangeSelection(e, 'customer')}
          placeholder="Ingen kund vald"
        />
        {contextLinks.customer ? (
          <Link to={contextLinks.customer}>
            <Button noBg>Gå till kundkort</Button>
          </Link>
        ) : (
          <div className="context-link-placeholder" />
        )}
      </div>
      <div className="single-context-container">
        <select
          disabled={disableContextSwitching}
          value={contextSelection.reservoir}
          onChange={(e) => handleChangeSelection(e, 'reservoir')}
        >
          <option value="">Alla reservoarer</option>
          {currentReservoirs.map((reservoir) => (
            <option key={reservoir._id} value={reservoir._id}>
              {reservoir.name}
              {!!reservoir.linkedFrom && ' (Länkad)'}
            </option>
          ))}
        </select>
        {contextLinks.reservoir ? (
          <Link to={contextLinks.reservoir}>
            <Button noBg>Gå till reservoarskort</Button>
          </Link>
        ) : (
          <div className="context-link-placeholder" />
        )}
      </div>

      <div className="single-context-container">
        <select
          disabled={disableContextSwitching}
          value={contextSelection.protocol}
          onChange={(e) => handleChangeSelection(e, 'protocol')}
        >
          <option value="">Alla protokoll</option>
          {currentProtocols.map((protocol) => (
            <option key={protocol._id} value={protocol._id}>
              {protocol.name}
            </option>
          ))}
        </select>
        {contextLinks.protocol ? (
          <Link to={contextLinks.protocol}>
            <Button noBg>Gå till protokoll</Button>
          </Link>
        ) : (
          <div className="context-link-placeholder" />
        )}
      </div>
    </StyledFileContextSelector>
  );
};

export default FileContextSelector;
