import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useQuery = <T extends Record<string, string>>() => {
  const { search } = useLocation();
  const query = useMemo(() => {
    const params = new URLSearchParams(search);
    const parsed: Record<string, string> = {};
    params.forEach((v, k) => {
      parsed[k] = v;
    });
    return parsed as T;
  }, [search]);
  return query;
};
