import styled from 'styled-components';
import Button from './Button';
import { useContext } from 'react';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import WorkorderForm from './WorkorderForm';
import { useState } from 'react';
import OfferForm from './OfferForm';
import WorkPlanForm from './WorkPlanForm';
import { ActionType } from '../types/dispatch.types';
import ServicelogForm from './ServicelogForm';
import OrderConfirmationForm from './OrderConfirmationForm';

const StyledFormOverlay = styled.div`
  position: fixed;
  top: 0;
  z-index: 2;
  right: 0;
  bottom: 0;
  width: 80vw;
  max-width: 1000px;
  background: white;
  padding: 1rem;
  box-shadow: -12px 0 12px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: 200ms ease-in-out;
  .form-body {
    overflow: scroll;
    flex-grow: 1;
    padding-bottom: 4rem;
  }
  .form-controls {
    display: flex;
    justify-content: space-between;
  }
  .form-minimized {
    position: absolute;
    right: 100%;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    transition: 200ms ease-in-out;
    transition-delay: 200ms;
    background: white;
    padding: 0.5rem;
    border-radius: 5px 0 0 0;
    box-shadow: -6px -6px 10px rgba(0, 0, 0, 0.08);
  }
  &.hidden {
    width: 600px;
    right: -600px;
    .form-minimized {
      opacity: 1;
      pointer-events: initial;
      transition-delay: 0;
    }
  }
  @media (max-width: 1000px) {
    width: 100vw;
    top: 50px;
    height: calc(100vh - 50px);
  }
`;

const FormOverlay = () => {
  const { formOverlay } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [closePending, setClosePending] = useState(false);

  const hide = () => {
    dispatch({ type: ActionType.HIDE_FORM_OVERLAY });
  };
  const show = () => {
    dispatch({ type: ActionType.SHOW_FORM_OVERLAY });
  };

  const closeInit = () => {
    setClosePending(true);
  };

  const closeForm = () => {
    dispatch({ type: ActionType.CLOSE_FORM_OVERLAY });
  };

  return (
    <StyledFormOverlay className={formOverlay.hidden ? 'hidden' : ''}>
      <div className="form-headers">
        <h3>{formOverlay.header}</h3>
      </div>
      <div className="form-body">
        {formOverlay.formType === 'workorder' && (
          <WorkorderForm
            users={formOverlay.users}
            data={formOverlay.formData}
            customer={formOverlay.customer}
            handleSubmit={formOverlay.handleSubmit}
          />
        )}
        {formOverlay.formType === 'offer' && (
          <OfferForm
            users={formOverlay.users}
            data={formOverlay.formData}
            customer={formOverlay.customer}
            handleSubmit={formOverlay.handleSubmit}
          />
        )}
        {formOverlay.formType === 'workplan' && (
          <WorkPlanForm
            users={formOverlay.users}
            data={formOverlay.formData}
            customer={formOverlay.customer}
            handleSubmit={formOverlay.handleSubmit}
          />
        )}
        {formOverlay.formType === 'servicelog' && (
          <ServicelogForm
            users={formOverlay.users}
            data={formOverlay.formData}
            customer={formOverlay.customer}
            handleSubmit={formOverlay.handleSubmit}
          />
        )}
        {formOverlay.formType === 'orderConfirmation' && (
          <OrderConfirmationForm
            users={formOverlay.users}
            data={formOverlay.formData}
            customer={formOverlay.customer}
            handleSubmit={formOverlay.handleSubmit}
          />
        )}
      </div>
      <div className="form-controls">
        <Button small onClick={hide} noBg>
          Minimera
        </Button>
        <Button
          small
          look="danger"
          onClick={closePending ? closeForm : closeInit}
        >
          {closePending ? 'Säker?' : 'Stäng'}
        </Button>
      </div>
      <div className="form-minimized">
        <Button small onClick={show}>
          Maximera
        </Button>
      </div>
    </StyledFormOverlay>
  );
};

export default FormOverlay;
