export enum ROUTE {
  LOGIN = '/',
  USER_RESET_PASSWORD = '/user-reset-password',
  HOME = '/home',

  INVOICING = '/invoicing',
  MEDIA = '/media',
  NOTIFICATIONS = '/notifications',

  DOCUMENTS = '/documents',
  MY_ACCOUNT = '/my-account',

  OFFERS = '/offers',
  ORDER_CONFIRMATIONS = '/order-confirmations',
  SERVICELOGS = '/servicelogs',
  WORKORDERS = '/workorders',
  WORKPLANS = '/workplans',

  ADMIN = '/admin',
  EDIT_CUSTOMER = '/admin/edit-customer',

  CREATE_PROTOCOL_INSP = '/create-protocol/insp',
  CREATE_PROTOCOL_KONT = '/create-protocol/kont',
  CREATE_PROTOCOL_MR = '/create-protocol/MR',
  CREATE_PROTOCOL_RR = '/create-protocol/RR',

  CUSTOMERS = '/customers',
  CUSTOMER = '/customers/single',
  CUSTOMER_PORTAL = '/customers/customer-portal',
  ADD_TO_CUSTOMER_PORTAL = '/customers/add-to-customer-portal',

  RESERVOIRS = '/reservoirs',
  RESERVOIR = '/reservoirs/single',
}

export const routesWithoutLayout: ROUTE[] = [
  ROUTE.LOGIN,
  ROUTE.USER_RESET_PASSWORD,
];
