import { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../cnst/colors.cnst';
import Button from './Button';

const StyledInformationGrid = styled.div<{ inner?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  .information-pair {
    margin: 0.3rem 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 0.6rem;
    &.fw {
      grid-column: 1 / 3;
    }

    p {
      text-align: right;
      &.light {
        text-align: left;
        color: ${colors.mediumGrey};
      }
    }
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      content: '';
      background: ${(props) =>
        props.inner ? colors.mediumGrey : colors.lightGrey};
    }
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

type Pair = {
  h: string | ReactNode;
  p: string | null | ReactNode[] | number;
  href?: string;
  fw?: boolean;
};

type Props = {
  pairs: (Pair | null)[];
  inner?: boolean;
};

const InformationGrid = ({ inner, pairs }: Props) => {
  return (
    <StyledInformationGrid inner={inner}>
      {pairs.map((pair, i) => {
        if (pair) {
          return (
            <div
              className={`information-pair ${pair.fw ? 'fw' : ''}`}
              key={(typeof pair.h === 'string' ? pair.h : '') + i}
            >
              <p className="light">{pair.h}</p>
              {pair.href ? (
                <a href={pair.href} target="_blank" rel="noreferrer">
                  <Button noBg>{pair.p}</Button>
                </a>
              ) : (
                <p>{pair.p}</p>
              )}
            </div>
          );
        } else return null;
      })}
    </StyledInformationGrid>
  );
};

export default InformationGrid;
