import OverlayBox from './OverlayBox';
import Button from './Button';
import { Notification } from '@bm-js/h2o-shared';

type Props = {
  deleteNotificationPending: { active: boolean; notification?: Notification };
  setDeleteNotificationPending: (v: {
    notification?: Notification;
    active: boolean;
  }) => void;
  deleteNotification: (v?: Notification) => void;
};

const DeleteNotificationModal = ({
  deleteNotificationPending,
  setDeleteNotificationPending,
  deleteNotification,
}: Props) => {
  return (
    <OverlayBox
      header="Radera notis?"
      active={deleteNotificationPending.active}
      close={() =>
        setDeleteNotificationPending({
          ...deleteNotificationPending,
          active: false,
        })
      }
    >
      <p>
        Vill du <strong>permanent radera </strong>notisen?
      </p>
      <p>
        Det går <strong>inte </strong>att återskapa en raderad notis.
      </p>
      <Button
        med
        look="danger"
        onClick={() =>
          deleteNotification(deleteNotificationPending.notification)
        }
      >
        Radera
      </Button>
      <Button
        med
        onClick={() =>
          setDeleteNotificationPending({
            ...deleteNotificationPending,
            active: false,
          })
        }
      >
        Avbryt
      </Button>
    </OverlayBox>
  );
};

export default DeleteNotificationModal;
