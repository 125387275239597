import { useMemo } from 'react';
import styled from 'styled-components';

const StyledMediaLibraryHeader = styled.div<{ $onSelect: boolean }>`
  display: grid;
  grid-template-columns: ${({ $onSelect }) =>
      $onSelect ? '' : '15px'} 50px 3fr 1fr 1fr 20px;
  padding: 0 1rem;
  font-weight: 600;
  gap: 0.5rem;
  margin-bottom: 0.8rem;
  margin-top: 1rem;
  .single-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .sort-container {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      button {
        font-size: 0 !important;
      }
      img {
        width: 10px;
        height: 10px;
        object-fit: contain;
        filter: invert(1);
        &.rotate {
          transform: rotate(180deg);
        }
        &.hidden {
          display: none;
        }
      }
    }
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const headers = [
  {
    key: 'name',
    text: 'Filnamn',
  },
  {
    key: 'uploadedAt',
    text: 'Uppladdad',
    hideMobile: true,
  },
  {
    key: 'size',
    text: 'Storlek',
    hideMobile: true,
  },
];

type Props = {
  sort: string;
  setSort: (v: string) => void;
  onSelect: boolean;
};

const MediaLibraryHeader = ({ sort, setSort, onSelect }: Props) => {
  const { key, dir } = useMemo(() => {
    const [key, dir] = sort.split(':');
    return { key, dir };
  }, [sort]);

  const handleSort = (newKey: string) => {
    const opposite = (input: string) => {
      if (input === 'asc') return 'desc';
      if (input === 'desc') return 'asc';
    };
    if (newKey === key) {
      setSort(`${key}:${opposite(dir)}`);
    } else {
      setSort(`${newKey}:asc`);
    }
  };

  return (
    <StyledMediaLibraryHeader className="files-header" $onSelect={onSelect}>
      {!onSelect && <div />}
      <div />
      {headers.map((header) => (
        <div
          onClick={() => handleSort(header.key)}
          key={`media_lib_${header.key}`}
          className={`single-header ${header.hideMobile ? 'hide-mobile' : ''}`}
        >
          <p>{header.text}</p>
          <span className="sort-container">
            {['desc', 'asc'].map((direction, i) => (
              <img
                key={`sort_${header.key}_${direction}`}
                src="/icons/min-arrow-down.png"
                className={`${i === 0 ? 'rotate' : ''} ${
                  key === header.key && dir !== direction ? 'hidden' : ''
                }`}
                alt=""
              />
            ))}
          </span>
        </div>
      ))}
    </StyledMediaLibraryHeader>
  );
};

export default MediaLibraryHeader;
