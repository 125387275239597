import { Link } from 'react-router';
import styled from 'styled-components';
import Button from '../components/Button';
import { ROUTE } from '../cnst/router.cnst';

const StyledNotFoundRoute = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotFoundRoute = () => {
  return (
    <StyledNotFoundRoute>
      <div>
        <h1>Sidan hittades inte</h1>
        <Link to={ROUTE.HOME}>
          <Button>Gå till startsida</Button>
        </Link>
      </div>
    </StyledNotFoundRoute>
  );
};

export default NotFoundRoute;
