import { Link } from 'react-router';
import {
  FormEventHandler,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import Card from '../components/Card';
import CreateProtocolBasics from '../components/CreateProtocolBasics';
import CreateProtocolResult from '../components/CreateProtocolResult';
import OverlayBox from '../components/OverlayBox';
import Switch from '../components/Switch';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { protocolConfig } from '../cnst/protocolConfig.cnst';
import {
  createPdf,
  getData,
  saveProtocol,
} from '../utils/protocolHelpers.util';
import ProtocolMainImage from '../components/ProtocolMainImage';
import ProtocolHeader from '../components/ProtocolHeader';
import { useInterval } from '../hooks/useInterval';
import { useQuery } from '../hooks/useQuery';
import {
  CustomerBM,
  ProtocolFM,
  ProtocolOptionBM,
  ReservoirFM,
  UserFM,
} from '@bm-js/h2o-shared';
import { ActionType } from '../types/dispatch.types';

const StyledCreateProtocolKontRoute = styled.div`
  form {
    .dual {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      align-items: flex-start;
      p {
        margin-bottom: 0.5rem;
      }
    }
    h4 {
      margin-top: 2rem !important;
      margin-bottom: 1rem !important;
      font-size: 1.5rem !important;
      font-weight: 600 !important;
    }
  }
`;

const CreateProtocolKontRoute = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [users, setUsers] = useState<UserFM[]>([]);
  const [reservoir, setReservoir] = useState<ReservoirFM | {}>({});
  const [customer, setCustomer] = useState<CustomerBM | {}>({});
  const [protocolOptions, setProtocolOptions] = useState<
    ProtocolOptionBM['keys'][0][]
  >([]);
  const [readyForAutoSave, setReadyForAutoSave] = useState(false);
  const [missingInformationWarning, setMissingInformationWarning] = useState({
    active: false,
    skipValidation: false,
  });

  const [protocolData, setProtocolData] = useState<Partial<ProtocolFM>>({
    status: 'Ej påbörjat',
    nextRecommendedInspection: '',
    nextRecommendedCleaning: '',
    nextRecommendedFilterChange: '',
    nextRecommendedControl: '',
    dateForInspection: '',
    mainImage: '',
    responsibleTechnician: '',
    orderPerson: '',
    contactPerson: '',
    isOcular: false,
    resultsInner: protocolConfig.kont.resultsInner,
    resultsOuter: protocolConfig.kont.resultsOuter,
  });
  const [previousProtocol, setPreviousProtocol] = useState<ProtocolFM>();

  const query = useQuery<{
    type: string;
    protocolId: string;
    reservoirId: string;
    customerId: string;
    customerName: string;
    reservoirName: string;
  }>();

  const autoSave = async () => {
    const _reservoir = reservoir as ReservoirFM;
    const _customer = customer as CustomerBM;
    await saveProtocol({
      dispatch,
      query,
      reservoir: _reservoir,
      state,
      protocolData,
      customer: _customer,
      isAutoSave: true,
    });
  };

  const { runningAction } = useInterval({
    action: autoSave,
    active: readyForAutoSave,
  });

  useEffect(() => {
    dispatch({ type: ActionType.LOADING, value: true });
    if (state.token) {
      getData({
        dispatch,
        query,
        setCustomer,
        setUsers,
        setReservoir,
        setReadyForAutoSave,
        setProtocolOptions,
        setPreviousProtocol,
        setProtocolData,
        state,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  const _createPdf = async (
    skipValidation?: boolean,
    skipDownload?: boolean
  ) => {
    const _reservoir = reservoir as ReservoirFM;
    const _customer = customer as CustomerBM;
    createPdf({
      skipDownload,
      skipValidation,
      dispatch,
      query,
      missingInformationWarning,
      setMissingInformationWarning,
      protocolData,
      state,
      reservoir: _reservoir,
      customer: _customer,
    });
  };

  const _saveProtocol: FormEventHandler = (e) => {
    const _reservoir = reservoir as ReservoirFM;
    const _customer = customer as CustomerBM;
    e.preventDefault();
    saveProtocol({
      dispatch,
      query,
      reservoir: _reservoir,
      state,
      protocolData,
      customer: _customer,
    });
  };

  const mediaContext = useMemo(() => {
    return {
      customer: (customer as CustomerBM)._id,
      reservoir: (reservoir as ReservoirFM)._id,
      protocol: protocolData._id || '',
    };
  }, [customer, reservoir, protocolData]);

  if (!readyForAutoSave) return null;

  return (
    <StyledCreateProtocolKontRoute>
      <ProtocolHeader
        date={protocolData.dateForInspection as unknown as string}
        createPdf={_createPdf}
        query={query}
        runningAction={runningAction}
      />
      <div className="single-customer-inner">
        <div className="single-customer-left-content">
          <Card>
            <form onSubmit={_saveProtocol}>
              <>
                <CreateProtocolBasics
                  protocolData={protocolData}
                  setProtocolData={setProtocolData}
                  users={users}
                  customer={customer as CustomerBM}
                />
                <ProtocolMainImage
                  context={mediaContext}
                  imageKey={'mainImage'}
                  protocolData={protocolData}
                  setProtocolData={setProtocolData}
                />
                <h4>Inre kontroll</h4>
                <Switch
                  allBlue
                  checked={protocolData.isOcular}
                  name="isOcular"
                  secondPlaceholder="Okulär"
                  placeholder="ROV"
                  onChange={(e) => {
                    setProtocolData({
                      ...protocolData,
                      isOcular: e.target.checked,
                    });
                  }}
                />
                <h4>Resultat - invändig kontroll</h4>
                {protocolData.resultsInner!.map((result, resultIndex) => (
                  <CreateProtocolResult
                    context={mediaContext}
                    key={result.key}
                    mainKey={'resultsInner'}
                    result={result}
                    previousProtocol={
                      previousProtocol?.resultsInner?.[resultIndex]
                    }
                    resultIndex={resultIndex}
                    protocolData={protocolData}
                    setProtocolData={setProtocolData}
                    protocolOptions={protocolOptions}
                  />
                ))}
                <h4>Resultat - utvändig kontroll</h4>
                {protocolData.resultsOuter!.map((result, resultIndex) => (
                  <CreateProtocolResult
                    mainKey={'resultsOuter'}
                    context={mediaContext}
                    key={result.key}
                    result={result}
                    previousProtocol={
                      previousProtocol?.resultsOuter?.[resultIndex]
                    }
                    resultIndex={resultIndex}
                    protocolData={protocolData}
                    setProtocolData={setProtocolData}
                    protocolOptions={protocolOptions}
                  />
                ))}
              </>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button
                type="submit"
                style={{ display: 'none' }}
                id="submitNewProtocolButton"
              ></button>
            </form>
          </Card>
        </div>
        <div className="single-customer-right-content">
          <Card>
            <div className="customer-card-header">
              <h3>Status</h3>
            </div>
            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
            <select
              value={protocolData.status}
              onChange={(e) => {
                setProtocolData({
                  ...protocolData,
                  status: e.target.value,
                });
              }}
            >
              <option>Ej påbörjat</option>
              <option>Hanteras</option>
              <option>Klart</option>
            </select>
          </Card>
        </div>
      </div>
      <OverlayBox
        header="Information saknas"
        active={missingInformationWarning.active}
        close={() => {
          setMissingInformationWarning({
            ...missingInformationWarning,
            active: false,
          });
        }}
      >
        <p>
          Viss reservoarsinformation saknas för att skapa en komplett PDF.
          Följande information krävs på reservoarskortet:
        </p>
        <p>Namn</p>
        <p>Bottenyta</p>
        <p>Area</p>
        <br />
        <p>Hur vill du fortsätta</p>
        <Link
          to={`/reservoirs/single/?customerId=${
            (customer as CustomerBM)._id
          }&reservoirId=${(reservoir as ReservoirFM)._id}`}
          state={{
            openDialogue: 'edit',
          }}
        >
          <Button small>Komplettera information</Button>
        </Link>

        <Button
          small
          onClick={() => {
            _createPdf(true);
          }}
        >
          Fortsätt skapa PDF
        </Button>
        <Button
          small
          look="danger"
          onClick={() => {
            setMissingInformationWarning({
              ...missingInformationWarning,
              active: false,
            });
          }}
        >
          Avbryt
        </Button>
      </OverlayBox>
    </StyledCreateProtocolKontRoute>
  );
};

export default CreateProtocolKontRoute;
