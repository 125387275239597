import { PersonBM } from '@bm-js/h2o-shared';
import { request } from './request';
import { GlobalState } from '../types/state.types';
import { Dispatch } from 'react';
import { Action } from '../types/dispatch.types';

export const getPersons = async ({
  dispatch,
  state,
}: {
  state: GlobalState;
  dispatch: Dispatch<Action>;
}) => {
  const { err, data } = await request<PersonBM[]>({
    method: 'GET',
    path: 'persons',
    state,
    dispatch,
  });

  if (err) {
    return { err };
  }

  return { persons: data };
};
