import styled from 'styled-components';
import Button from './Button';
import Card from './Card';
import { colors } from '../cnst/colors.cnst';
import { pdfTemplateTypes } from '../cnst/data.cnst';
import { downloadPdf } from '../utils/downloadPdf.util';
import { progressRequest, request } from '../utils/request';
import { useGlobal } from '../hooks/useGlobalState';
import { ChangeEvent, useState } from 'react';
import AdminCard from './AdminCard';
import DeleteConfirmation from './DeleteConfirmation';

const StyledAdminDocumentTemplates = styled.div`
  .file-input {
    display: none;
  }
`;

type Props = {
  templateFilesList: Record<string, string[]>;
  revalidate: () => void;
};

const AdminDocumentTemplates = ({ templateFilesList, revalidate }: Props) => {
  const { state, dispatch } = useGlobal();

  const [templateDeletePending, setTemplateDeletePending] = useState<{
    active: boolean;
    typeName: string;
    fileName: string;
  }>({ active: false, typeName: '', fileName: '' });

  const deleteSingleTemplate = async (template: {
    typeName: string;
    fileName: string;
  }) => {
    const { typeName, fileName } = template;
    const filePath = encodeURIComponent(`${typeName}/${fileName}`);
    await request({
      state,
      dispatch,
      isDoServer: true,
      path: `files/{token}/templates/single/${filePath}`,
      method: 'DELETE',
      successText: 'Mall raderad',
    });
    setTemplateDeletePending({ active: false, typeName: '', fileName: '' });
    revalidate();
  };

  const downloadTemplate = async (
    pdfTemplateType: string,
    fileName: string
  ) => {
    const filePath = encodeURIComponent(`${pdfTemplateType}/${fileName}`);
    const { data } = await request<any>({
      state,
      dispatch,
      isDoServer: true,
      path: `files/{token}/templates/single/${filePath}`,
    });

    if (!data) return;
    downloadPdf(data.data, fileName, true);
  };

  const readTemplateFile = async (
    e: ChangeEvent<HTMLInputElement>,
    pdfTemplateType: string
  ) => {
    if (!e.target.files?.length) return;
    const formData = new FormData();
    formData.append('template', e.target.files[0]);
    await progressRequest({
      state,
      dispatch,
      body: formData,
      method: 'POST',
      path: `files/{token}/templates/${pdfTemplateType}`,
      successText: 'Filen har laddats upp',
    });
    const input = document.getElementById(
      `fileInput${pdfTemplateType}`
    ) as HTMLInputElement;
    if (input) input.value = '';
    revalidate();
  };

  return (
    <StyledAdminDocumentTemplates>
      <Card>
        <div className="customer-card-header">
          <h3>Dokumentmallar</h3>
        </div>
        {pdfTemplateTypes.map((templateType) => (
          <div key={templateType.name}>
            <p
              style={{
                color: colors.mediumGrey,
                marginBottom: '-0.5rem',
              }}
            >
              <strong>{templateType.name}</strong>
            </p>
            <input
              accept=".pdf,.doc,.docx,.pages"
              className="file-input"
              id={`fileInput${templateType.name}`}
              type="file"
              onChange={(e) => {
                readTemplateFile(e, templateType.name);
              }}
            />
            {templateFilesList[templateType.key]?.map((fileName, index) => (
              <AdminCard
                key={fileName + templateType.name + index}
                cardContent={<p>{fileName}</p>}
                menuContent={
                  <>
                    <Button
                      small
                      onClick={() =>
                        downloadTemplate(templateType.name, fileName)
                      }
                    >
                      Ladda ned
                    </Button>
                    <Button
                      small
                      look="danger"
                      onClick={() => {
                        setTemplateDeletePending({
                          active: true,
                          typeName: templateType.name,
                          fileName: fileName,
                        });
                      }}
                    >
                      Radera
                    </Button>
                  </>
                }
              />
            ))}
            <Button
              style={{
                marginTop: '1rem',
                marginBottom: '2rem',
              }}
              small
              onClick={() => {
                document
                  .getElementById(`fileInput${templateType.name}`)
                  ?.click();
              }}
            >
              Ladda upp
            </Button>
          </div>
        ))}
      </Card>

      {templateDeletePending.active && (
        <DeleteConfirmation
          close={() =>
            setTemplateDeletePending({
              active: false,
              typeName: '',
              fileName: '',
            })
          }
          commit={deleteSingleTemplate}
          itemIdentifier={templateDeletePending.fileName}
          item={templateDeletePending}
          itemName={'fil'}
        />
      )}
    </StyledAdminDocumentTemplates>
  );
};

export default AdminDocumentTemplates;
