import { FormEventHandler, useContext, useMemo } from 'react';
import styled from 'styled-components';
import OverlayBox from './OverlayBox';
import { useState } from 'react';
import { parseDate } from '../utils/parseDate.util';
import { protocolMap } from '../cnst/data.cnst';
import Button from './Button';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { createProtocol } from '../utils/protocolHelpers.util';
import { protocolConfig } from '../cnst/protocolConfig.cnst';
import { getProtocolString } from '../utils/getProtocolFilename.util';
import DateInput from './DateInput';
import { CustomerBM, ProtocolFM, ReservoirBM, UserFM } from '@bm-js/h2o-shared';
import { ProtocolType } from '../types/protocol.types';
import { _objectKeys } from '../utils/misc.util';
import { useNavigate } from 'react-router';

const StyledCreateProtocolModal = styled.div`
  .create-protocol-button-container {
    display: flex;
    justify-content: flex-end;
  }
`;

type Props = {
  close: () => void;
  reservoir: ReservoirBM;
  customer: Partial<CustomerBM>;
};

const CreateProtocolModal = ({ close, reservoir, customer }: Props) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const navigate = useNavigate();

  const [protocolType, setProtocolType] = useState<ProtocolType>();
  const [protocolDate, setProtocolDate] = useState(parseDate(new Date()));
  const [protocolIdToCopyFrom, setProtocolIdToCopyFrom] = useState('');

  const copyResultsProtocolTypes = [ProtocolType.INSP, ProtocolType.KONT];

  const handleSubmit: FormEventHandler = (e) => {
    if (!protocolType) return;
    e.preventDefault();
    const { firstName, lastName } = state.user as UserFM;
    const payload = {
      state,
      dispatch,
      reservoir,
      navigate,
      customer,
      protocolData: {
        dateForInspection: protocolDate,
        type: protocolType,
        typeFull: protocolMap[protocolType],
        responsibleTechnician: `${firstName} ${lastName}`,
        status: 'Ej påbörjat',
        resultsInner: protocolConfig[protocolType].resultsInner,
        resultsOuter: protocolConfig[protocolType].resultsOuter,
      } as ProtocolFM,
    };

    if (
      copyResultsProtocolTypes.includes(protocolType) &&
      protocolIdToCopyFrom
    ) {
      const protocolToCopyFrom = reservoir.protocols.find(
        (p) => p._id === protocolIdToCopyFrom
      );
      if (protocolToCopyFrom) {
        payload.protocolData.resultsInner = protocolToCopyFrom.resultsInner;
        payload.protocolData.resultsOuter = protocolToCopyFrom.resultsOuter;
      }
    }

    const recommendedActionKeys = {
      manualCleaning: 'nextRecommendedCleaning',
      robotCleaning: 'nextRecommendedCleaning',
      filterChange: 'nextRecommendedFilterChange',
      inspection: 'nextRecommendedInspection',
      control: 'nextRecommendedControl',
    };
    _objectKeys(recommendedActionKeys).forEach((key) => {
      if (reservoir.nextRecommendedAction?.[key]) {
        // @ts-ignore
        payload.protocolData[recommendedActionKeys[key]] =
          reservoir.nextRecommendedAction[key];
      }
    });

    createProtocol(payload);
  };

  const availableProtocolsToCopyFrom = useMemo(() => {
    const relevantProtocols = reservoir.protocols.filter(
      (protocol) => protocol.type === protocolType
    );
    return relevantProtocols;
  }, [protocolType, reservoir.protocols]);

  return (
    <StyledCreateProtocolModal>
      <OverlayBox close={close} header="Skapa protokoll" active>
        <form onSubmit={handleSubmit}>
          <select
            required
            value={protocolType}
            onChange={(e) => setProtocolType(e.target.value as ProtocolType)}
          >
            <option value="">Välj protokolltyp</option>
            {_objectKeys(protocolMap).map((key) => (
              <option key={`create_proto_${key}`} value={key}>
                {protocolMap[key]}
              </option>
            ))}
          </select>
          <DateInput
            required
            value={protocolDate}
            onChange={(e) => setProtocolDate(e.target.value)}
          />
          {!!protocolType &&
            copyResultsProtocolTypes.includes(protocolType) && (
              <select
                value={protocolIdToCopyFrom || ''}
                onChange={(e) => setProtocolIdToCopyFrom(e.target.value)}
              >
                <option disabled value="">
                  Välj protokoll att kopiera resultat från
                </option>
                {availableProtocolsToCopyFrom.map((protocol) => (
                  <option value={protocol._id} key={protocol._id}>
                    {getProtocolString(
                      protocol.dateForInspection,
                      protocol.typeFull
                    )}
                  </option>
                ))}
              </select>
            )}
          <div className="create-protocol-button-container">
            <Button small type="submit">
              Skapa
            </Button>
          </div>
        </form>
      </OverlayBox>
    </StyledCreateProtocolModal>
  );
};

export default CreateProtocolModal;
