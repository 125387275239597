import axios from 'axios';
import { do_url } from '../cnst/server.cnst';
import { parseCustomerName } from './parseCustomerName.util';
import { getProtocolFilename } from './getProtocolFilename.util';
import { downloadPdf } from './downloadPdf.util';
import { request } from './request';
import {
  CustomerBM,
  ProtocolBM,
  ProtocolFM,
  ProtocolOptionBM,
  ReservoirBM,
  ReservoirFM,
  UserFM,
} from '@bm-js/h2o-shared';
import { Dispatch } from 'react';
import { Action, ActionType } from '../types/dispatch.types';
import { GlobalState } from '../types/state.types';
import { envService } from '../services/services';
import { NavigateFunction } from 'react-router';

const updateReservoirWithProtocolData = async (
  reservoir: ReservoirFM,
  protocolData: Partial<ProtocolFM>,
  customer: CustomerBM,
  token?: string
) => {
  const endpoint = `reservoirs/${customer._id}/${reservoir._id}/updateFromProtocol`;
  const body = { protocol: protocolData };

  await request({
    state: { token },
    method: 'PUT',
    path: endpoint,
    body,
  });
};

export const getData = async ({
  dispatch,
  query,
  setCustomer,
  setUsers,
  setReservoir,
  setProtocolOptions,
  setReadyForAutoSave,
  setPreviousProtocol,
  setProtocolData,
  state,
}: {
  dispatch: Dispatch<Action>;
  query: {
    type: string;
    customerId: string;
    reservoirId: string;
    protocolId: string;
  };
  setCustomer: (v: CustomerBM) => void;
  setUsers: (v: UserFM[]) => void;
  setReservoir: (v: ReservoirBM) => void;
  setProtocolOptions: (v: ProtocolOptionBM['keys'][0][]) => void;
  setReadyForAutoSave: (v: boolean) => void;
  setPreviousProtocol?: (v: ProtocolBM) => void;
  setProtocolData: (v: ProtocolBM) => void;
  state: GlobalState;
}) => {
  const protocolTypeLc = query.type.split(';')[1];
  //GET MONGODATA
  const mongoUrl = `${envService.API_URL}customers/create-protocol-data/${query.customerId}/${query.reservoirId}/${protocolTypeLc}`;
  const mongoOptions = {
    headers: { Authorization: 'Bearer ' + state.token },
  };
  try {
    const mongoRes = await axios.get<{
      customer: CustomerBM;
      users: UserFM[];
      reservoir: ReservoirBM;
      protocolOptions: ProtocolOptionBM['keys'][0][];
    }>(mongoUrl, mongoOptions);
    setCustomer(mongoRes.data.customer);
    setUsers(mongoRes.data.users);
    setReservoir(mongoRes.data.reservoir);
    let tempProtocolData: ProtocolBM | {} = {};

    setProtocolOptions(mongoRes.data.protocolOptions);
    const previousProtocols: ProtocolBM[] = [];
    mongoRes.data.reservoir.protocols.forEach((protocol) => {
      if (protocol.type === protocolTypeLc) {
        previousProtocols.push(protocol);
      }
    });
    const sortedPreviousProtocols = previousProtocols.sort(
      (a, b) =>
        new Date(b.dateForInspection).getTime() -
        new Date(a.dateForInspection).getTime()
    );
    if (!!setPreviousProtocol && sortedPreviousProtocols.length)
      setPreviousProtocol(sortedPreviousProtocols[0]);

    mongoRes.data.reservoir.protocols.forEach((protocol) => {
      if (protocol._id.toString() === query.protocolId) {
        tempProtocolData = protocol;
      }
    });

    setProtocolData(tempProtocolData as ProtocolBM);
    dispatch({ type: ActionType.LOADING, value: false });
    setReadyForAutoSave(true);
  } catch (e) {
    console.error('Get protocol error: ', e);
    dispatch({ type: ActionType.LOADING, value: false });
  }
};

const checkIfReservoirDataExists = ({
  setMissingInformationWarning,
  missingInformationWarning,
  reservoir,
}: {
  setMissingInformationWarning: (v: any) => void;
  missingInformationWarning: any;
  reservoir: ReservoirBM | ReservoirFM;
}) => {
  let valid = true;
  if (!reservoir.basicInformation.name) valid = false;
  if (!reservoir.basicInformation.volume) valid = false;
  if (!reservoir.basicInformation.area) valid = false;
  if (missingInformationWarning.skipValidation) {
    valid = true;
    setMissingInformationWarning({
      ...missingInformationWarning,
      skipValidation: false,
    });
  }
  if (!valid) {
    setMissingInformationWarning({
      ...missingInformationWarning,
      active: true,
    });
    return;
  }
};

export const createPdf = async ({
  skipValidation,
  skipDownload,
  dispatch,
  query,
  setMissingInformationWarning,
  missingInformationWarning,
  protocolData,
  state,
  reservoir,
  customer,
}: {
  skipValidation?: boolean;
  skipDownload?: boolean;
  dispatch: Dispatch<Action>;
  query: { type: string };
  setMissingInformationWarning: (v: any) => void;
  missingInformationWarning: any;
  protocolData: Partial<ProtocolFM>;
  state: GlobalState;
  reservoir: ReservoirFM;
  customer: CustomerBM;
}) => {
  try {
    if (!skipValidation) {
      checkIfReservoirDataExists({
        setMissingInformationWarning,
        missingInformationWarning,
        reservoir,
      });
    }

    dispatch({ type: ActionType.LOADING, value: true });

    const protocolTypeLc = query.type.split(';')[1];
    const protocolTypeName = query.type.split(';')[0];

    const body = {
      customerName: parseCustomerName(customer),
      reservoirName: parseCustomerName(reservoir.basicInformation.name),
      date: protocolData.dateForInspection,
      type: protocolTypeLc,
      status: protocolData.status,
      customerId: customer._id,
      reservoirId: reservoir._id,
      token: state.token,
      reservoir: {
        basicInformation: reservoir.basicInformation,
      },
      protocolData,
      protocolId: protocolData._id,
    };

    const doUrl = `${do_url}protocols/${state.token}/create-protocol-pdf/`;
    const doRes = await axios.post(doUrl, body);

    const filename = getProtocolFilename(
      reservoir.basicInformation.name,
      customer.customerInformation.name,
      protocolTypeName,
      protocolData.dateForInspection!
    );

    if (!skipDownload) {
      downloadPdf(doRes.data.data, filename, true);
    }

    dispatch({ type: ActionType.ALERT, content: 'PDF skapad' });
  } catch {
    dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
  } finally {
    dispatch({ type: ActionType.LOADING_PROGRESS, progress: 0, value: false });
  }
};

export const createProtocol = async ({
  dispatch,
  state,
  reservoir,
  customer,
  protocolData,
  navigate,
}: {
  dispatch: Dispatch<Action>;
  state: GlobalState;
  reservoir: ReservoirBM;
  customer: Partial<CustomerBM>;
  protocolData: ProtocolFM;
  navigate: NavigateFunction;
}) => {
  if (!customer.customerInformation) return;
  const {
    customerInformation: { name: customerName },
  } = customer;
  const {
    basicInformation: { name: reservoirName },
  } = reservoir;
  const protocolTypeLc = protocolData.type;
  const protocolTypeName = protocolData.typeFull;
  const mongoBody = JSON.parse(JSON.stringify(protocolData));
  const doBody = {
    ...mongoBody,
    customerName,
    reservoirName,
  };
  const mongoPath = `customers/protocol-new/${customer._id}/${reservoir._id}`;
  const doPath = 'protocols/{token}/save-protocol';
  const { data: mongoData } = await request<ProtocolBM>({
    state,
    dispatch,
    method: 'POST',
    body: mongoBody,
    path: mongoPath,
  });
  await request({
    state,
    dispatch,
    path: doPath,
    isDoServer: true,
    method: 'POST',
    body: doBody,
    successText: 'Protokoll sparat',
  });

  const protocolUrl = `/create-protocol/${protocolTypeLc}?customerId=${customer._id}&reservoirId=${reservoir._id}&customerName=${customerName}&reservoirName=${reservoirName}&protocolId=${mongoData?._id}&type=${protocolTypeName};${protocolTypeLc}`;
  navigate(protocolUrl);
};

export const saveProtocol = async ({
  dispatch,
  query,
  reservoir,
  state,
  protocolData,
  customer,
  isAutoSave = false,
}: {
  dispatch: Dispatch<Action>;
  query: { type: string; protocolId: string };
  reservoir: ReservoirFM;
  state: GlobalState;
  protocolData: Partial<ProtocolFM>;
  customer: CustomerBM;
  isAutoSave?: boolean;
}) => {
  try {
    const protocolTypeLc = query.type.split(';')[1];
    const protocolTypeName = query.type.split(';')[0];

    if (!isAutoSave) {
      dispatch({ type: ActionType.LOADING, value: true });
    }

    let mongoFormData = JSON.parse(JSON.stringify(protocolData));

    mongoFormData.type = protocolTypeLc;
    mongoFormData.typeFull = protocolTypeName;
    const mongoUrl = `${envService.API_URL}customers/protocol-update/${customer._id}/${reservoir._id}/${query.protocolId}`;
    const mongoOptions = {
      headers: { Authorization: 'Bearer ' + state.token },
    };
    await axios.post(mongoUrl, mongoFormData, mongoOptions);
    const doBody = {
      ...mongoFormData,
      customerName: customer.customerInformation.name,
      reservoirName: reservoir.basicInformation.name,
    };
    const doUrl = `${do_url}protocols/${state.token}/save-protocol`;
    await axios.post(doUrl, doBody);

    await updateReservoirWithProtocolData(
      reservoir,
      protocolData,
      customer,
      state.token
    );

    if (!isAutoSave) {
      dispatch({ type: ActionType.ALERT, content: 'Protokoll sparat' });
      dispatch({ type: ActionType.LOADING, value: false });
    }
  } catch (e) {
    console.error('Save protocol error: ', e);
    dispatch({ type: ActionType.LOADING_PROGRESS, progress: 0, value: false });
    dispatch({
      type: ActionType.ALERT,
      content: isAutoSave ? 'Kunde inte autospara' : 'Något gick fel',
    });
  }
};
