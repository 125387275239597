import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GlobalDispatchContext } from '../context/GlobalContextProvider';
import Button from './Button';
import Switch from './Switch';
import { useInterval } from '../hooks/useInterval';
import DateInput from './DateInput';
import { ActionType } from '../types/dispatch.types';
import { ReservoirBM } from '@bm-js/h2o-shared';
import { reservoirFormDefault } from '../cnst/default.cnst';

const StyledReservoirForm = styled.form`
  .customer-form-inner {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .flex {
    display: flex;
  }
  .manhatch {
    align-items: center;
    p {
      margin: 0 1rem 1rem 1rem !important;
    }
  }
  .dual {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;
    p {
      margin-bottom: 1rem !important;
      font-size: 1rem !important;
      opacity: 0.7;
    }
  }
  .customer-form-part {
    flex-direction: column;
    display: flex;
    margin-bottom: 1.5rem;
    p {
      font-size: 1.2rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
  .customer-form-array {
    flex-direction: column;
    display: flex;
    margin-bottom: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .volume-area-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 2rem;
    &-item {
      &-inner {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        p {
          font-size: 0.8rem;
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .customer-form-inner {
      grid-template-columns: 1fr;
    }
  }
`;

type Props = {
  handleSubmit: (formData: Partial<ReservoirBM>, auto?: boolean) => void;
  formData?: Partial<ReservoirBM>;
  id?: string;
};

const ReservoirForm = (props: Props) => {
  const dispatch = useContext(GlobalDispatchContext);

  const [formData, setFormData] =
    useState<Partial<ReservoirBM>>(reservoirFormDefault);

  const { runningAction } = useInterval({
    action: () => props.handleSubmit(formData, true),
    active: true,
  });

  const getCurrentLocation = () => {
    dispatch({ type: ActionType.LOADING, value: true });
    const handleSuccess: PositionCallback = (position) => {
      setFormData({
        ...formData,
        basicInformation: {
          ...formData.basicInformation!,
          gps: {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          },
        },
      });
      const accuracy = position.coords.accuracy;
      if (accuracy !== null) {
        dispatch({
          type: ActionType.ALERT,
          content: `Position hämtad med ${Math.floor(accuracy)}m säkerhet`,
        });
      }
      dispatch({ type: ActionType.LOADING, value: false });
    };
    const handleError: PositionErrorCallback = (error) => {
      if (error) {
        dispatch({ type: ActionType.ALERT, content: 'Kunde inte hämta plats' });
        dispatch({ type: ActionType.LOADING, value: false });
      }
    };
    const options = {
      maximumAge: 0,
      enableHighAccuracy: true,
    };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        handleSuccess,
        handleError,
        options
      );
    } else {
      dispatch({ type: ActionType.ALERT, content: 'Kunde inte hämta plats' });
    }
  };

  useEffect(() => {
    if (props.formData) {
      const { diagonalPipe, hasDiagonalPipe } = props.formData.cleaning || {};
      let newDiagonalPipeValue = 'Ej valt ännu';
      if (hasDiagonalPipe) {
        newDiagonalPipeValue = 'Ja';
      }
      if (diagonalPipe) {
        newDiagonalPipeValue = diagonalPipe;
      }
      const parsedFormData = {
        ...props.formData,
        cleaning: {
          ...props.formData.cleaning,
          diagonalPipe: newDiagonalPipeValue,
        },
      };
      // @ts-ignore
      setFormData(parsedFormData);
    }
    // eslint-disable-next-line
  }, [props.formData]);

  //Handles backwards compability for summer/winter status
  useEffect(() => {
    let newSummerStatus = null;
    if (!formData.basicInformation?.summerStatusNew) {
      if (formData.basicInformation?.isWinterWork) {
        newSummerStatus = 'all-year';
      } else {
        newSummerStatus = 'summer';
      }
      setFormData({
        ...formData,
        // @ts-ignore
        basicInformation: {
          ...formData.basicInformation,
          summerStatusNew: newSummerStatus,
        },
      });
    }
  }, [formData]);

  const handleExeptions = () => {
    if (
      formData.basicInformation?.electricity.hasElectricity &&
      !formData.basicInformation?.electricity.type
    )
      return 'El är ej valt';
    return false;
  };

  return (
    <StyledReservoirForm
      onSubmit={(e) => {
        e.preventDefault();
        const exeption = handleExeptions();
        if (exeption)
          return dispatch({ type: ActionType.ALERT, content: exeption });
        props.handleSubmit(formData);
      }}
    >
      <div style={{ height: 16 }} />
      <div className="customer-form-inner">
        <div className="customer-form-part">
          <p>
            <strong>Grunduppgifter</strong>
          </p>
          <input
            type="text"
            value={formData.basicInformation?.name}
            name="name"
            required
            placeholder="Namn"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                basicInformation: {
                  ...formData.basicInformation,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />
          <Switch
            checked={formData.basicInformation?.protected}
            name="protected"
            placeholder="Skyddsobjekt"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                basicInformation: {
                  ...formData.basicInformation,
                  [e.target.name]: e.target.checked,
                },
              });
            }}
          />
          <p>GPS-koordinater</p>
          <div className="dual">
            <div>
              <p>Latitud</p>
              <input
                type="text"
                value={formData.basicInformation?.gps.lat || ''}
                name="lat"
                placeholder="Latitud"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    // @ts-ignore
                    basicInformation: {
                      ...formData.basicInformation,
                      gps: {
                        // @ts-ignore
                        ...formData.basicInformation.gps,
                        [e.target.name]: e.target.value,
                      },
                    },
                  });
                }}
              />
            </div>
            <div>
              <p>Longitud</p>
              <input
                type="text"
                value={formData.basicInformation?.gps.lon || ''}
                name="lon"
                placeholder="Longitud"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    // @ts-ignore
                    basicInformation: {
                      ...formData.basicInformation,
                      gps: {
                        // @ts-ignore
                        ...formData.basicInformation.gps,
                        [e.target.name]: e.target.value,
                      },
                    },
                  });
                }}
              />
            </div>
          </div>
          <Button
            type="button"
            med
            onClick={getCurrentLocation}
            style={{ margin: 0, marginBottom: '1rem' }}
          >
            Använd min plats
          </Button>
          <textarea
            value={formData.basicInformation?.adress.join('\n')}
            name="adress"
            placeholder="Adress"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                basicInformation: {
                  ...formData.basicInformation,
                  [e.target.name]: e.target.value.split('\n'),
                },
              });
            }}
          />
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            value={formData.basicInformation?.type}
            name="type"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                basicInformation: {
                  ...formData.basicInformation,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          >
            <option value="" disabled>
              Typ
            </option>
            <option>VT</option>
            <option>HR</option>
            <option>LR</option>
            <option>Tryckstegring</option>
            <option>Spolbassäng</option>
          </select>
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            value={formData.basicInformation?.implementation}
            name="implementation"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                basicInformation: {
                  ...formData.basicInformation,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          >
            <option value="" disabled>
              Utförande
            </option>
            <option>Markreservoar täckt</option>
            <option>Fristående på mark</option>
          </select>
          <p>Höjd över marknivå</p>
          <div className="dual">
            <input
              type="number"
              value={formData.basicInformation?.heightOverGround || ''}
              name="heightOverGround"
              step="0.1"
              placeholder="Höjd över marknivå"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  basicInformation: {
                    ...formData.basicInformation,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
            <p>Meter</p>
          </div>
          <p>Invändig höjd</p>
          <div className="dual">
            <input
              type="number"
              value={formData.basicInformation?.innerHeight || ''}
              name="innerHeight"
              step="0.1"
              placeholder="Invändig höjd"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  basicInformation: {
                    ...formData.basicInformation,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
            <p>Meter</p>
          </div>
          <div className="volume-area-container">
            <div className="volume-area-container-item">
              <p>Volym 1</p>
              <div className="volume-area-container-item-inner">
                <input
                  type="number"
                  value={formData.basicInformation?.volume}
                  name="volume"
                  step="0.1"
                  placeholder="Volym"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      basicInformation: {
                        ...formData.basicInformation,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                />
                <p>m³</p>
              </div>
            </div>
            <div className="volume-area-container-item">
              <p>Volym 2</p>
              <div className="volume-area-container-item-inner">
                <input
                  type="number"
                  value={formData.basicInformation?.volume2}
                  name="volume2"
                  step="0.1"
                  placeholder="Volym 2"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      basicInformation: {
                        ...formData.basicInformation,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                />
                <p>m³</p>
              </div>
            </div>
            <div className="volume-area-container-item">
              <p>Volym 3</p>
              <div className="volume-area-container-item-inner">
                <input
                  type="number"
                  value={formData.basicInformation?.volume3}
                  name="volume3"
                  step="0.1"
                  placeholder="Volym 3"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      basicInformation: {
                        ...formData.basicInformation,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                />
                <p>m³</p>
              </div>
            </div>
            <div className="volume-area-container-item">
              <p>Volym 4</p>
              <div className="volume-area-container-item-inner">
                <input
                  type="number"
                  value={formData.basicInformation?.volume4}
                  name="volume4"
                  step="0.1"
                  placeholder="Volym 4"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      basicInformation: {
                        ...formData.basicInformation,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                />
                <p>m³</p>
              </div>
            </div>
          </div>
          <div className="volume-area-container">
            <div className="volume-area-container-item">
              <p>Bottenyta 1</p>
              <div className="volume-area-container-item-inner">
                <input
                  type="number"
                  value={formData.basicInformation?.area}
                  name="area"
                  step="0.1"
                  placeholder="Bottenyta"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      basicInformation: {
                        ...formData.basicInformation,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                />
                <p>m²</p>
              </div>
            </div>
            <div className="volume-area-container-item">
              <p>Bottenyta 2</p>
              <div className="volume-area-container-item-inner">
                <input
                  type="number"
                  value={formData.basicInformation?.area2}
                  name="area2"
                  step="0.1"
                  placeholder="Bottenyta"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      basicInformation: {
                        ...formData.basicInformation,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                />
                <p>m²</p>
              </div>
            </div>
            <div className="volume-area-container-item">
              <p>Bottenyta 3</p>
              <div className="volume-area-container-item-inner">
                <input
                  type="number"
                  value={formData.basicInformation?.area3}
                  name="area3"
                  step="0.1"
                  placeholder="Bottenyta"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      basicInformation: {
                        ...formData.basicInformation,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                />
                <p>m²</p>
              </div>
            </div>
            <div className="volume-area-container-item">
              <p>Bottenyta 4</p>
              <div className="volume-area-container-item-inner">
                <input
                  type="number"
                  value={formData.basicInformation?.area4}
                  name="area4"
                  step="0.1"
                  placeholder="Bottenyta"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      basicInformation: {
                        ...formData.basicInformation,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                />
                <p>m²</p>
              </div>
            </div>
          </div>
        </div>
        <div className="customer-form-part">
          <p>Fack (antal)</p>
          <div className="dual">
            <input
              type="number"
              value={formData.basicInformation?.compartments.quantity || ''}
              name="quantity"
              step="0.1"
              placeholder="Fack (antal)"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  basicInformation: {
                    ...formData.basicInformation,
                    compartments: {
                      // @ts-ignore
                      ...formData.basicInformation.compartments,
                      [e.target.name]: e.target.value,
                    },
                  },
                });
              }}
            />
          </div>
          {/* @ts-ignore */}
          {formData.basicInformation.compartments.quantity === '1' ? null : (
            <input
              type="text"
              value={formData.basicInformation?.compartments.name}
              name="name"
              placeholder="Fack (namn)"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  basicInformation: {
                    ...formData.basicInformation,
                    compartments: {
                      // @ts-ignore
                      ...formData.basicInformation.compartments,
                      [e.target.name]: e.target.value,
                    },
                  },
                });
              }}
            />
          )}
          <p>Invändigt material</p>
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            value={formData.basicInformation?.innerMaterial}
            name="innerMaterial"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                basicInformation: {
                  ...formData.basicInformation,
                  innerMaterialOther: '',
                  [e.target.name]: e.target.value,
                },
              });
            }}
          >
            <option value="">Ej valt ännu</option>
            <option>Betong</option>
            <option>Rostfritt</option>
            <option>Glasfiber</option>
            <option>Annat</option>
          </select>
          {formData.basicInformation?.innerMaterial === 'Annat' ? (
            <input
              type="text"
              value={formData.basicInformation.innerMaterialOther}
              name="innerMaterialOther"
              placeholder="Invändigt material (fritext)"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  basicInformation: {
                    ...formData.basicInformation,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
          ) : null}
          <p>Infodring</p>
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            value={formData.basicInformation?.feeding}
            name="feeding"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                basicInformation: {
                  ...formData.basicInformation,
                  feedingOther: '',
                  [e.target.name]: e.target.value,
                },
              });
            }}
          >
            <option value="">Ej valt ännu</option>
            <option>Saknas</option>
            <option>Epoxy</option>
            <option>Polyurea</option>
            <option>Hydro-klick</option>
            <option>Annat</option>
          </select>
          {formData.basicInformation?.feeding === 'Annat' ? (
            <input
              type="text"
              value={formData.basicInformation.feedingOther}
              name="feedingOther"
              // required
              placeholder="Infodring (fritext)"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  basicInformation: {
                    ...formData.basicInformation,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
          ) : null}
          <p>Vatten</p>
          <Switch
            checked={formData.basicInformation?.water.hasWater}
            name="hasWater"
            placeholder="Vattentillgång"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                basicInformation: {
                  ...formData.basicInformation,
                  water: {
                    // @ts-ignore
                    ...formData.basicInformation.water,
                    waterOut: false,
                    waterPost: false,
                    [e.target.name]: e.target.checked,
                  },
                },
              });
            }}
          />
          {formData.basicInformation?.water.hasWater ? (
            <>
              <Switch
                checked={formData.basicInformation.water.waterOut}
                name="waterOut"
                placeholder="Vattenutkast"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    // @ts-ignore
                    basicInformation: {
                      ...formData.basicInformation,
                      water: {
                        // @ts-ignore
                        ...formData.basicInformation.water,
                        [e.target.name]: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <Switch
                checked={formData.basicInformation.water.waterPost}
                name="waterPost"
                placeholder="Vattenpost"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    // @ts-ignore
                    basicInformation: {
                      ...formData.basicInformation,
                      water: {
                        // @ts-ignore
                        ...formData.basicInformation.water,
                        [e.target.name]: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <Switch
                checked={formData.basicInformation.water.waterTank}
                name="waterTank"
                placeholder="Fristående vattentank"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    // @ts-ignore
                    basicInformation: {
                      ...formData.basicInformation,
                      water: {
                        // @ts-ignore
                        ...formData.basicInformation.water,
                        [e.target.name]: e.target.checked,
                      },
                    },
                  });
                }}
              />
            </>
          ) : null}
          <p>Sommarjobb/vinterjobb</p>
          <div className="dual">
            {/* eslint-disable-next-line */}
            <select
              value={formData.basicInformation?.summerStatusNew}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  basicInformation: {
                    ...formData.basicInformation,
                    summerStatusNew: e.target.value,
                  },
                });
              }}
            >
              <option value="-">Ej valt ännu</option>
              <option value="summer">Sommarjobb</option>
              <option value="all-year">Året runt jobb</option>
            </select>
          </div>
          <p>
            <strong>Etablering</strong>
          </p>
          <Switch
            checked={formData.establishment?.crane}
            name="crane"
            placeholder="Kranbil"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                establishment: {
                  ...formData.establishment,
                  [e.target.name]: e.target.checked,
                },
              });
            }}
          />
          <Switch
            checked={formData.establishment?.elevator}
            name="elevator"
            placeholder="Hiss"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                establishment: {
                  ...formData.establishment,
                  [e.target.name]: e.target.checked,
                },
              });
            }}
          />
          <Switch
            checked={formData.establishment?.telferCrane}
            name="telferCrane"
            placeholder="Fastmonterad kranarm på tak"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                establishment: {
                  ...formData.establishment,
                  [e.target.name]: e.target.checked,
                },
              });
            }}
          />
          <Switch
            checked={formData.establishment?.innerTelfer}
            name="innerTelfer"
            placeholder="Invändig telfer finns på plats"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                establishment: {
                  ...formData.establishment,
                  [e.target.name]: e.target.checked,
                },
              });
            }}
          />
          <Switch
            checked={formData.establishment?.broughtTelfer}
            name="broughtTelfer"
            placeholder="Telfer med kranarm som tas med av H2O Teknik"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                establishment: {
                  ...formData.establishment,
                  [e.target.name]: e.target.checked,
                },
              });
            }}
          />
          <p>Avstånd parkering - reservoar</p>
          <div className="dual">
            <input
              type="number"
              value={formData.establishment?.parkingReservoirDistance || ''}
              name="parkingReservoirDistance"
              step="0.1"
              placeholder="Avstånd"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  establishment: {
                    ...formData.establishment,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
            <p>m</p>
          </div>
          <p>Etableringstid robot</p>
          <div className="dual">
            <input
              type="number"
              value={formData.establishment?.establishmentTime || ''}
              name="establishmentTime"
              step="0.1"
              placeholder="Etableringstid robot"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  establishment: {
                    ...formData.establishment,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
            <p>timmar</p>
          </div>
          <p>Etableringstid manuell rengöring</p>
          <div className="dual">
            <input
              type="number"
              value={formData.establishment?.establishmentTimeManual || ''}
              name="establishmentTimeManual"
              step="0.1"
              placeholder="Etableringstid manuell rengöring"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  establishment: {
                    ...formData.establishment,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
            <p>timmar</p>
          </div>
          <p>Krävs ometablering</p>
          <Switch
            checked={formData.establishment?.reEstablishmentRequired}
            name="reEstablishmentRequired"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                establishment: {
                  ...formData.establishment,
                  [e.target.name]: e.target.checked,
                },
              });
            }}
            allBlue
            placeholder="Nej"
            secondPlaceholder="Ja"
          />
          <p>Pumpgrop under stege</p>
          {/* eslint-disable-next-line */}
          <select
            value={formData.establishment?.pumpPitUnderLadder}
            name="pumpPitUnderLadder"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                establishment: {
                  ...formData.establishment,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          >
            <option>Ej valt ännu</option>
            <option>Ja</option>
            <option>Nej</option>
          </select>
          <p>
            <strong>Lucka</strong>
          </p>
          <Switch
            checked={formData.hatch?.manlucka.hasManlucka}
            name="hasManlucka"
            secondPlaceholder="Manlucka"
            placeholder="Öppen reservoar"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                hatch: {
                  ...formData.hatch,
                  manlucka: {
                    // @ts-ignore
                    ...formData.hatch.manlucka,
                    brand: '',
                    [e.target.name]: e.target.checked,
                  },
                },
              });
            }}
          />
          {formData.hatch?.manlucka.hasManlucka ? (
            <>
              {/* eslint-disable-next-line jsx-a11y/no-onchange */}
              <select
                value={formData.hatch.manlucka.brand}
                // required
                name="brand"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    // @ts-ignore
                    hatch: {
                      ...formData.hatch,
                      manlucka: {
                        // @ts-ignore
                        ...formData.hatch.manlucka,
                        brandOther: '',
                        [e.target.name]: e.target.value,
                      },
                    },
                  });
                }}
              >
                <option value="" disabled>
                  Märke
                </option>
                <option>ABAT</option>
                <option>Huber</option>
                <option>Amphi-safe</option>
                <option>H2O Säkerhetslucka</option>
                <option>Annat</option>
              </select>
              {formData.hatch.manlucka.brand === 'Annat' ? (
                <input
                  type="text"
                  value={formData.hatch.manlucka.brandOther}
                  name="brandOther"
                  // required
                  placeholder="Märke (fritext)"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      hatch: {
                        ...formData.hatch,
                        manlucka: {
                          // @ts-ignore
                          ...formData.hatch.manlucka,

                          [e.target.name]: e.target.value,
                        },
                      },
                    });
                  }}
                />
              ) : null}
              <Switch
                checked={formData.hatch.safetyHatch.hasSafetyHatch}
                name="hasSafetyHatch"
                placeholder="Säkerhetslucka"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    hatch: {
                      ...formData.hatch,
                      safetyHatch: {
                        ...formData.hatch?.safetyHatch,
                        // @ts-ignore
                        dateInstalled: '',
                        [e.target.name]: e.target.checked,
                      },
                    },
                  });
                }}
              />
              {formData.hatch.safetyHatch.hasSafetyHatch ? (
                <>
                  <p>Monterad datum</p>
                  <DateInput
                    value={formData.hatch.safetyHatch.dateInstalled}
                    name="dateInstalled"
                    placeholder="Monterad datum"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        // @ts-ignore
                        hatch: {
                          ...formData.hatch,
                          // @ts-ignore
                          safetyHatch: {
                            ...formData.hatch?.safetyHatch,

                            [e.target.name]: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </>
              ) : null}
              <p>Mått manlucka</p>
              <div className="flex manhatch">
                <input
                  type="number"
                  value={formData.hatch.measurments.w}
                  name="w"
                  placeholder="mm"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      hatch: {
                        ...formData.hatch,
                        // @ts-ignore
                        measurments: {
                          ...formData.hatch?.measurments,
                          [e.target.name]: e.target.value,
                        },
                      },
                    });
                  }}
                />
                <p>x</p>
                <input
                  type="number"
                  value={formData.hatch.measurments.h}
                  name="h"
                  placeholder="mm"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      hatch: {
                        ...formData.hatch,
                        measurments: {
                          // @ts-ignore
                          ...formData.hatch.measurments,
                          [e.target.name]: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </div>
            </>
          ) : null}
          <p>
            <strong>Ventilation</strong>
          </p>
          <Switch
            checked={formData.ventilation?.hasVentilation}
            name="hasVentilation"
            placeholder="Ventilation"
            onChange={(e) => {
              setFormData({
                ...formData,
                ventilation: {
                  ...formData.ventilation,
                  type: '',
                  // @ts-ignore
                  h2oVentDate: '',
                  [e.target.name]: e.target.checked,
                },
              });
            }}
          />
          {formData.ventilation?.hasVentilation ? (
            // eslint-disable-next-line jsx-a11y/no-onchange
            <select
              value={formData.ventilation.type}
              // required
              name="type"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  ventilation: {
                    ...formData.ventilation,
                    // @ts-ignore
                    h2oVentDate: '',
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            >
              <option value="" disabled>
                Modell
              </option>
              <option>Amphi-vent 125</option>
              <option>Amphi-vent 200</option>
              <option>Svanhals utan filter</option>
              <option>Scandfilter</option>
              <option>H2O-vent</option>
              <option>Miljömontage i Småland AB</option>
              <option>Övrigt</option>
            </select>
          ) : null}
          {formData.ventilation?.type === 'H2O-vent' ? (
            <>
              <p>H2O-vent monterad datum</p>
              <DateInput
                value={formData.ventilation.h2oVentDate}
                name="h2oVentDate"
                placeholder="H2O-vent monterad datum"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    // @ts-ignore
                    ventilation: {
                      ...formData.ventilation,

                      [e.target.name]: e.target.value,
                    },
                  });
                }}
              />
            </>
          ) : null}
          {formData.ventilation?.type === 'Övrigt' && (
            <>
              <p>Ventilationstyp (övrigt)</p>
              <div className="dual">
                <input
                  type="text"
                  value={formData.ventilation.typeOther || ''}
                  name="typeOther"
                  placeholder="Ventilationstyp (övrigt)"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      ventilation: {
                        ...formData.ventilation,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </>
          )}
          <p>Ventilation (antal)</p>
          <div className="dual">
            <input
              type="number"
              value={formData.ventilation?.quantity || ''}
              name="quantity"
              placeholder="Ventilation (antal)"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  ventilation: {
                    ...formData.ventilation,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
          </div>
          <p>
            <strong>Rengöring</strong>
          </p>
          <Switch
            checked={formData.cleaning?.robotPossible}
            name="robotPossible"
            placeholder="Robotrengöring möjlig"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                cleaning: {
                  ...formData.cleaning,
                  robotType: '',
                  noRobotReason: '',
                  [e.target.name]: e.target.checked,
                },
              });
            }}
          />

          {!formData.cleaning?.robotPossible ? (
            <input
              type="text"
              value={formData.cleaning?.noRobotReason}
              name="noRobotReason"
              placeholder="Varför ej möjligt? (fritext)"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  cleaning: {
                    ...formData.cleaning,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
          ) : (
            <>
              {/* eslint-disable-next-line jsx-a11y/no-onchange */}
              <select
                value={formData.cleaning.robotType}
                name="robotType"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    // @ts-ignore
                    cleaning: {
                      ...formData.cleaning,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
              >
                <option value="" disabled>
                  Rekommenderad robot
                </option>
                <option>VR 50</option>
                <option>VR 600</option>
              </select>
              {formData.cleaning.robotType === 'VR 50' ? (
                <textarea
                  value={formData.cleaning.vr50reason}
                  name="vr50reason"
                  placeholder="Motivera varför VR 50"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      // @ts-ignore
                      cleaning: {
                        ...formData.cleaning,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                />
              ) : null}
            </>
          )}
          <p>Tid för robotrengöring</p>
          <div className="dual">
            <input
              type="number"
              value={formData.cleaning?.cleaningTime || ''}
              name="cleaningTime"
              step="0.1"
              placeholder="Tid för robotrengöring"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  cleaning: {
                    ...formData.cleaning,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
            <p>timmar</p>
          </div>
          <p>Tid för manuell rengöring</p>
          <div className="dual">
            <input
              type="number"
              value={formData.cleaning?.cleaningTimeManual || ''}
              name="cleaningTimeManual"
              step="0.1"
              placeholder="Tid för manuell rengöring"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  cleaning: {
                    ...formData.cleaning,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
            <p>timmar</p>
          </div>
          <p>Tvärgående bottenrör</p>
          {/* eslint-disable-next-line */}
          <select
            value={formData.cleaning?.diagonalPipe}
            name="diagonalPipe"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                cleaning: {
                  ...formData.cleaning,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          >
            <option>Ej valt ännu</option>
            <option>Ja</option>
            <option>Nej</option>
          </select>

          {formData.cleaning?.diagonalPipe === 'Ja' ? (
            <div>
              <p>Möljighet att knuffa robot över rör</p>
              <Switch
                checked={formData.cleaning.canPushRobotOverPipe}
                name="canPushRobotOverPipe"
                placeholder="Nej"
                secondPlaceholder="Ja"
                allBlue
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    // @ts-ignore
                    cleaning: {
                      ...formData.cleaning,
                      [e.target.name]: e.target.checked,
                    },
                  });
                }}
              />
              <p>Möljighet att köra runt rör</p>
              <Switch
                checked={formData.cleaning.canDriveAroundPipe}
                name="canDriveAroundPipe"
                placeholder="Nej"
                secondPlaceholder="Ja"
                allBlue
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    // @ts-ignore
                    cleaning: {
                      ...formData.cleaning,
                      [e.target.name]: e.target.checked,
                    },
                  });
                }}
              />
              <p>Möljighet att köra under rör</p>
              <Switch
                checked={formData.cleaning.canDriveUnderPipe}
                name="canDriveUnderPipe"
                placeholder="Nej"
                secondPlaceholder="Ja"
                allBlue
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    // @ts-ignore
                    cleaning: {
                      ...formData.cleaning,
                      [e.target.name]: e.target.checked,
                    },
                  });
                }}
              />
            </div>
          ) : null}
          <p>Får tripod plats</p>
          {/* eslint-disable-next-line */}
          <select
            value={formData.cleaning?.tripodSpace}
            name="tripodSpace"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                cleaning: {
                  ...formData.cleaning,
                  tripodSpace: e.target.value,
                },
              });
            }}
          >
            <option value="">Ej valt ännu</option>
            <option value="Ja">Ja</option>
            <option value="Nej">Nej</option>
          </select>
          <p>Flotte</p>
          {/* eslint-disable-next-line */}
          <select
            value={formData.cleaning?.floater}
            name="floater"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                cleaning: {
                  ...formData.cleaning,
                  floater: e.target.value,
                },
              });
            }}
          >
            <option value="">Ej valt ännu</option>
            <option value="Ja">Ja</option>
            <option value="Nej">Nej</option>
          </select>

          <p>EL</p>
          <Switch
            checked={formData.basicInformation?.electricity.hasElectricity}
            name="hasElectricity"
            placeholder="Eltillgång"
            onChange={(e) => {
              setFormData({
                ...formData,
                // @ts-ignore
                basicInformation: {
                  ...formData.basicInformation,
                  electricity: {
                    // @ts-ignore
                    ...formData.basicInformation.electricity,
                    type: '',
                    [e.target.name]: e.target.checked,
                  },
                },
              });
            }}
          />
          {formData.basicInformation?.electricity.hasElectricity ? (
            //eslint-disable-next-line jsx-a11y/no-onchange
            <select
              value={formData.basicInformation.electricity.type}
              name="type"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  // @ts-ignore
                  basicInformation: {
                    ...formData.basicInformation,
                    electricity: {
                      // @ts-ignore
                      ...formData.basicInformation.electricity,
                      [e.target.name]: e.target.value,
                    },
                  },
                });
              }}
            >
              <option value="" disabled>
                El (typ)
              </option>
              <option>El saknas</option>
              <option>1-fas 220V</option>
              <option>3-fas 400V/16A</option>
            </select>
          ) : null}

          <div className="dual">
            <Button
              disabled={runningAction}
              type="submit"
              med
              id={`saveButton_resid-${props.id}`}
            >
              Spara
            </Button>
          </div>
        </div>
      </div>
    </StyledReservoirForm>
  );
};

export default ReservoirForm;
