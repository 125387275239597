import styled from 'styled-components';
import { colors } from '../cnst/colors.cnst';

const StyledFileThumbnailFallback = styled.div`
  background: ${colors.lightGrey};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 50px;
  img {
    width: 20px;
    height: 20px;
  }
`;

const FileThumbnailFallback = () => {
  return (
    <StyledFileThumbnailFallback>
      <img src="/images/document.png" alt="" />
    </StyledFileThumbnailFallback>
  );
};

export default FileThumbnailFallback;
