import { useContext } from 'react';
import styled from 'styled-components';
import Button from './Button';
import { request } from '../utils/request';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { Notification } from '@bm-js/h2o-shared';

const StyledNotificationActions = styled.div`
  margin-bottom: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  p {
    font-weight: 600;
  }
`;

type Props = {
  batchSelection: Notification[];
  setBatchSelection: (value: Notification[]) => void;
  revalidate: () => void;
};

const NotificationActions = ({
  batchSelection,
  setBatchSelection,
  revalidate,
}: Props) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const deselectAll = () => {
    setBatchSelection([]);
  };

  const deleteSelected = async () => {
    await request({
      state,
      method: 'POST',
      dispatch,
      successText: 'Notiserna raderades',
      path: `notifications/batch/delete`,
      body: batchSelection,
    });
    setBatchSelection([]);
    revalidate();
  };

  if (!batchSelection.length) return null;
  return (
    <StyledNotificationActions>
      <p>Åtgärder för markerade notiser:</p>
      <Button small onClick={deselectAll}>
        Avmarkera
      </Button>

      <Button small look="danger" onClick={deleteSelected}>
        Radera
      </Button>
    </StyledNotificationActions>
  );
};

export default NotificationActions;
