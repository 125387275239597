import { ReservoirFM } from '@bm-js/h2o-shared';
import { FormEventHandler, useContext, useMemo, useState } from 'react';
import Card from './Card';
import Button from './Button';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { ActionType } from '../types/dispatch.types';
import { request } from '../utils/request';
import InformationGrid from './InformationGrid';
import { parseDateMonthYear } from '../utils/parseDate.util';
import OverlayBox from './OverlayBox';
import YearMonthPicker from './YearMonthPicker';
import styled from 'styled-components';

const StyledReservoirRecommendedActions = styled.div`
  .form-group {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  @media (max-width: 1000px) {
    .form-group {
      grid-template-columns: 1fr;
    }
  }
`;

type Props = {
  reservoir: ReservoirFM;
  customerId: string;
  reservoirId: string;
  getReservoir: () => Promise<void>;
};

const recommendedActionMap: Record<
  keyof ReservoirFM['nextRecommendedAction'],
  string
> = {
  filterChange: 'Filterbyte',
  inspection: 'Inspektion',
  control: 'Kontroll',
  robotCleaning: 'Robotrengöring',
  manualCleaning: 'Manuell rengöring',
  statusCheck: 'Statusbedömning',
};

const ReservoirRecommendedActions = ({
  reservoir,
  customerId,
  reservoirId,
  getReservoir,
}: Props) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [nextRecommendedActionEdit, setNextRecommendedActionEdit] = useState<
    ReservoirFM['nextRecommendedAction'] & { active: boolean }
  >({
    active: false,
    filterChange: '',
    inspection: '',
    control: '',
    robotCleaning: '',
    manualCleaning: '',
    statusCheck: '',
  });

  const updateRecommendedActions: FormEventHandler = async (e) => {
    e.preventDefault();
    dispatch({ type: ActionType.LOADING, value: true });
    const body = {
      reservoir: { ...reservoir },
      generateRecommendedActionNotification: true,
    };

    body.reservoir.nextRecommendedAction = {
      filterChange: nextRecommendedActionEdit.filterChange,
      inspection: nextRecommendedActionEdit.inspection,
      control: nextRecommendedActionEdit.control,
      robotCleaning: nextRecommendedActionEdit.robotCleaning,
      manualCleaning: nextRecommendedActionEdit.manualCleaning,
      statusCheck: nextRecommendedActionEdit.statusCheck,
    };

    await request({
      state,
      dispatch,
      method: 'POST',
      path: `customers/reservoirs/update/${customerId}/${reservoirId}`,
      body,
      successText: 'Åtgärder uppdaterade',
    });

    getReservoir();
    setNextRecommendedActionEdit({
      ...nextRecommendedActionEdit,
      active: false,
    });
  };

  const editInit = () => {
    setNextRecommendedActionEdit({
      active: true,
      filterChange: reservoir.nextRecommendedAction?.filterChange,
      inspection: reservoir.nextRecommendedAction?.inspection,
      control: reservoir.nextRecommendedAction?.control,
      robotCleaning: reservoir.nextRecommendedAction?.robotCleaning,
      manualCleaning: reservoir.nextRecommendedAction?.manualCleaning,
      statusCheck: reservoir.nextRecommendedAction?.statusCheck,
    });
  };

  const informationPairs = useMemo(() => {
    return Object.entries(reservoir.nextRecommendedAction || {}).map(
      ([key, value]) => {
        return {
          h: recommendedActionMap[
            key as keyof ReservoirFM['nextRecommendedAction']
          ],
          p: parseDateMonthYear(value, '-'),
        };
      }
    );
  }, [reservoir.nextRecommendedAction]);

  return (
    <StyledReservoirRecommendedActions>
      <Card>
        <div className="customer-card-header">
          <h3>Nästa rekommenderade åtgärd</h3>
          <div className="customer-card-header-right-content">
            <Button noBg onClick={editInit}>
              Ändra
            </Button>
          </div>
        </div>

        <InformationGrid pairs={informationPairs} />
      </Card>

      <OverlayBox
        header="Nästa rekommenderade åtgärd"
        active={nextRecommendedActionEdit.active}
        close={() =>
          setNextRecommendedActionEdit({
            ...nextRecommendedActionEdit,
            active: false,
          })
        }
      >
        <form onSubmit={updateRecommendedActions}>
          <div className="form-group">
            {Object.entries(nextRecommendedActionEdit).map(([key, value]) => {
              if (typeof value === 'boolean') return null;
              return (
                <YearMonthPicker
                  key={`nextRecommendedActionEdit-${key}`}
                  value={value}
                  name={key}
                  label={
                    recommendedActionMap[
                      key as keyof ReservoirFM['nextRecommendedAction']
                    ]
                  }
                  onChange={(e) =>
                    setNextRecommendedActionEdit({
                      ...nextRecommendedActionEdit,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              );
            })}
            {/* <YearMonthPicker
              value={nextRecommendedActionEdit.inspection}
              name={'inspection'}
              label={'Inspektion'}
              onChange={(e) =>
                setNextRecommendedActionEdit({
                  ...nextRecommendedActionEdit,
                  [e.target.name]: e.target.value,
                })
              }
            /> */}
          </div>
          <Button small type="submit">
            Spara
          </Button>
        </form>
      </OverlayBox>
    </StyledReservoirRecommendedActions>
  );
};

export default ReservoirRecommendedActions;
