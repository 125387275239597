import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { months } from '../cnst/data.cnst';

// Define the props for the YearMonthPicker component
type Props = {
  value: Date | string | undefined;
  name: string;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

// Styled components for styling
const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #333;
`;

const InputField = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 0.5rem;
`;

const SelectorContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Select = styled.select`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  flex: 1;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

const Button = styled.button`
  flex: 1;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #6c757d;
  &:hover {
    background-color: #5a6268;
  }

  &:last-child {
    background-color: #007bff;
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const monthOptions = months.map((month, index) => ({
  value: index + 1,
  label: month,
}));

const YearMonthPicker: React.FC<Props> = ({ value, name, label, onChange }) => {
  const parsedValue = useMemo(() => {
    if (typeof value === 'string') {
      // Handle ISO string
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        return { year: date.getFullYear(), month: date.getMonth() + 1 };
      }

      // Handle "YYYY-MONTH" format
      const [year, monthName] = value.split('-');
      const monthIndex =
        monthOptions.findIndex((m) => m.label === monthName) + 1;
      return { year: parseInt(year, 10), month: monthIndex || undefined };
    } else if (value instanceof Date && !isNaN(value.getTime())) {
      return { year: value.getFullYear(), month: value.getMonth() + 1 };
    }

    return { year: undefined, month: undefined };
  }, [value]);

  const [year, setYear] = useState<number | undefined>(parsedValue.year);
  const [month, setMonth] = useState<number | undefined>(parsedValue.month);
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setYear(parsedValue.year);
    setMonth(parsedValue.month);
  }, [parsedValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 21 }, (_, i) => currentYear - 10 + i);

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newYear = parseInt(e.target.value, 10) || undefined;
    setYear(newYear);
    emitChange(newYear, month || 1);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newMonth = parseInt(e.target.value, 10) || undefined;
    setMonth(newMonth);
    emitChange(year || new Date().getFullYear(), newMonth);
  };

  const emitChange = (updatedYear?: number, updatedMonth?: number) => {
    if (updatedYear && updatedMonth) {
      // format the date as "YYYY-MM"
      const monthString = updatedMonth < 10 ? `0${updatedMonth}` : updatedMonth;
      onChange({
        target: {
          name,
          value: `${updatedYear}-${monthString}`,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      onChange({
        target: {
          name,
          value: '',
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleConfirm = () => {
    setIsOpen(false);
  };

  const handleReset = () => {
    setYear(undefined);
    setMonth(undefined);
    emitChange(undefined, undefined);
  };

  return (
    <Container ref={containerRef}>
      <Label>{label}</Label>
      <InputField
        type="text"
        readOnly
        value={
          year && month
            ? `${year}-${monthOptions[month - 1].label.toUpperCase()}`
            : ''
        }
        placeholder="ÅÅÅÅ-MÅNAD"
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <Dropdown>
          <SelectorContainer>
            <Select
              name={`${name}-year`}
              value={year || ''}
              onChange={handleYearChange}
            >
              <option value="">År</option>
              {years.map((y) => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
            </Select>
            <Select
              name={`${name}-month`}
              value={month || ''}
              onChange={handleMonthChange}
            >
              <option value="">Månad</option>
              {monthOptions.map((m) => (
                <option key={m.value} value={m.value}>
                  {m.label}
                </option>
              ))}
            </Select>
          </SelectorContainer>
          <ButtonContainer>
            <Button type="button" onClick={handleReset}>
              Rensa
            </Button>
            <Button type="button" onClick={handleConfirm}>
              Bekräfta
            </Button>
          </ButtonContainer>
        </Dropdown>
      )}
    </Container>
  );
};

export default YearMonthPicker;
