import axios from 'axios';
import { Dispatch, useEffect, useState } from 'react';
import styled from 'styled-components';
import { do_url } from '../cnst/server.cnst';
import { colors } from '../cnst/colors.cnst';
import Button from './Button';
import BtnNoStyle from './BtnNoStyle';
import { GlobalState } from '../types/state.types';
import { Action, ActionType } from '../types/dispatch.types';
import { CustomerBM } from '@bm-js/h2o-shared';
import { envService } from '../services/services';

export type CustomerFilesBrowserFile = {
  protocolName: string;
  protocol: {
    customerName?: string;
    resName: string;
    id: string;
    type: string;
    year: number;
  };
};

type Props = {
  state: GlobalState;
  dispatch: Dispatch<Action>;
  customer?: CustomerBM;
  files: CustomerFilesBrowserFile[];
  setFiles: (v: CustomerFilesBrowserFile[]) => void;
  commitFiles: () => void;
};

const CustomerFilesBrowser = ({
  state,
  dispatch,
  customer,
  files,
  setFiles,
  commitFiles,
}: Props) => {
  const [allProtocols, setAllProtocols] = useState<
    CustomerFilesBrowserFile['protocol'][]
  >([]);
  const [loading, setLoading] = useState(true);

  const getAllPdfs = () => {
    if (!customer) return;
    setLoading(true);
    axios
      .get(
        `${do_url}files/${
          state.token
        }/all-protocol-files-information/${customer.customerInformation.name
          .split(' ')
          .join('_')}`
      )
      .then((res) => {
        axios
          .post(
            `${envService.API_URL}customers/protocol-years-from-protocol-ids/${customer._id}`,
            { protocolsArray: res.data },
            {
              headers: { Authorization: 'Bearer ' + state.token },
            }
          )
          .then((secondRes) => {
            if (customer.linkedReservoirIds?.length) {
              axios
                .post(
                  `${envService.API_URL}customers/linked-reservoirs`,
                  {
                    ids: customer.linkedReservoirIds,
                  },
                  {
                    headers: {
                      Authorization: 'Bearer ' + state.token,
                    },
                  }
                )
                .then(({ data: linkedReservoirs }) => {
                  axios
                    .post(
                      `${do_url}files/${state.token}/get-linked-protocol-files-information`,
                      {
                        reservoirs: linkedReservoirs,
                      }
                    )
                    .then(({ data: linkedProtocols }) => {
                      axios
                        .post(
                          `${envService.API_URL}customers/protocol-years-from-linked-protocols-ids`,
                          {
                            protocolsArray: linkedProtocols,
                          },
                          {
                            headers: {
                              Authorization: 'Bearer ' + state.token,
                            },
                          }
                        )
                        .then(({ data: parsedLinkedProtocols }) => {
                          setAllProtocols(
                            secondRes.data.concat(parsedLinkedProtocols)
                          );
                          setLoading(false);
                        })
                        .catch((err) => {
                          console.log(err.reponse);
                        });
                    })
                    .catch((err) => {
                      console.log(err.reponse);
                    });
                })
                .catch((err) => {
                  console.log(err.reponse);
                });
            } else {
              setAllProtocols(secondRes.data);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err.reponse);
          });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const viewFile = (protocol: CustomerFilesBrowserFile['protocol']) => {
    dispatch({ type: ActionType.LOADING, value: true });
    axios
      .get(
        `${do_url}protocols/${state.token}/protocol-pdf/${
          protocol.customerName ||
          customer?.customerInformation.name.split(' ').join('_')
        }/${protocol.resName.split(' ').join('_')}/${protocol.id}/${
          protocol.type
        }`
      )
      .then((res) => {
        const arrayBufferView = new Uint8Array(res.data.data);
        const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
        const urlCreator = window.URL || window.webkitURL;
        const url = urlCreator.createObjectURL(blob);
        window.open(url);
        dispatch({ type: ActionType.LOADING, value: false });
      })
      .catch(() => {
        dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
        dispatch({ type: ActionType.LOADING, value: false });
      });
  };

  const selectFile = (
    protocolName: string,
    protocol: CustomerFilesBrowserFile['protocol'],
    selected: boolean
  ) => {
    const tempFilesList = [...files];
    if (!selected) {
      //Is not selected yet, add to list
      tempFilesList.push({ protocolName, protocol });
    } else {
      tempFilesList.splice(
        tempFilesList.indexOf({ protocolName, protocol }),
        1
      );
      //Is already selected, remove from list
    }
    setFiles(tempFilesList);
  };

  useEffect(() => {
    if (state.token && customer) {
      getAllPdfs();
    }
    // eslint-disable-next-line
  }, [state.token, customer]);

  if (!customer) return null;

  return (
    <div>
      {loading ? (
        <p>Laddar filer...</p>
      ) : (
        <div className="customer-files-browser-container">
          {allProtocols.map((protocol) => (
            <CustomerBrowserFile
              key={protocol.id}
              customer={customer}
              protocol={protocol}
              viewFile={viewFile}
              selectFile={selectFile}
            />
          ))}
        </div>
      )}
      <Button med disabled={!files.length} onClick={commitFiles}>
        Lägg till valda filer
      </Button>
    </div>
  );
};

export default CustomerFilesBrowser;

const StyledCustomerBrowserFile = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 0.5rem;
  margin-bottom: 1rem;
  button {
    text-align: left;
    margin: 0 !important;
  }
  .select-box {
    border: 1px solid ${colors.darkGrey};
    border-radius: 2px;
    width: 100%;
    height: 100%;
    div {
      display: none;
      background: ${colors.lightBlue};
      width: 100%;
      height: 100%;
      clip-path: circle(50%);
      transform: scale(0.8);
    }
    &.selected {
      div {
        display: block;
      }
    }
  }
`;

type FileProps = {
  protocol: CustomerFilesBrowserFile['protocol'];
  customer: CustomerBM;
  selectFile: (
    protocolName: string,
    protocol: CustomerFilesBrowserFile['protocol'],
    selected: boolean
  ) => void;
  viewFile: (protocol: CustomerFilesBrowserFile['protocol']) => void;
};

const CustomerBrowserFile = ({
  protocol,
  customer,
  selectFile,
  viewFile,
}: FileProps) => {
  const [selected, setSelected] = useState(false);

  const reservoirTypes = {
    RR: 'Robotrengöring',
    MR: 'Manuell rengöring',
    insp: 'Inspektion',
    kont: 'Kontroll',
  };
  const protocolName = `${protocol.resName}_${
    protocol.customerName || customer.customerInformation?.name
  }_${reservoirTypes[protocol.type as keyof typeof reservoirTypes]}_${
    protocol.year
  }`;
  return (
    <StyledCustomerBrowserFile>
      <BtnNoStyle
        className={`select-box ${selected ? 'selected' : ''}`}
        onClick={() => {
          selectFile(protocolName, protocol, selected);
          setSelected(!selected);
        }}
      >
        <div />
      </BtnNoStyle>
      <Button noBg onClick={() => viewFile(protocol)}>
        {protocolName}
      </Button>
    </StyledCustomerBrowserFile>
  );
};
