import styled from 'styled-components';
import Button from '../components/Button';
import Card from '../components/Card';
import { colors } from '../cnst/colors.cnst';
import Plus from '../components/Plus';
import { useState } from 'react';
import { useGlobal } from '../hooks/useGlobalState';
import { request } from '../utils/request';
import {
  AdminProtocolOptionsType,
  ProtocolType,
} from '../types/protocol.types';

const StyledAdminProtocolOptions = styled.div`
  .add-protocol-option-container {
    display: flex;
    button {
      margin-left: 0.5rem;
      align-items: center;
      margin-bottom: 1rem;
    }
  }
`;

type Props = {
  protocolOptions: AdminProtocolOptionsType;
  setProtocolOptions: (protocolOptions: AdminProtocolOptionsType) => void;
  revalidate: () => void;
};

const AdminProtocolOptions = ({
  protocolOptions,
  setProtocolOptions,
  revalidate,
}: Props) => {
  const { state, dispatch } = useGlobal();

  const [protocolOptionsSelectedType, setProtocolOptionsSelectedType] =
    useState<ProtocolType>();

  const updateProtocolOptions = async () => {
    const body = {
      protocolType: protocolOptionsSelectedType,
      keys: protocolOptions[protocolOptionsSelectedType as ProtocolType],
    };
    await request({
      state,
      dispatch,
      method: 'POST',
      successText: 'Protokollalternativ uppdaterades',
      body,
      path: `protocol-options/update/${protocolOptionsSelectedType}`,
    });
    revalidate();
  };

  return (
    <StyledAdminProtocolOptions>
      <Card>
        <div className="customer-card-header">
          <h3>Protokollalternativ</h3>
          <div className="customer-card-header-right-content">
            <Button noBg onClick={updateProtocolOptions}>
              Spara
            </Button>
          </div>
        </div>
        <select
          value={protocolOptionsSelectedType}
          onChange={(e) =>
            setProtocolOptionsSelectedType(e.target.value as ProtocolType)
          }
        >
          <option value="">Välj protokolltyp</option>
          {Object.keys(protocolOptions).map((key) => (
            <option key={key}>{key}</option>
          ))}
        </select>
        <br />
        {!!protocolOptionsSelectedType &&
          protocolOptions[protocolOptionsSelectedType]?.map(
            (option, optionIndex) => (
              <div key={option._id}>
                <h5>{option.placeholder}</h5>
                <div className="dual">
                  <div>
                    <p className="strong">Resultat</p>
                    {option.results.map((result, resultIndex) => (
                      <div
                        className="add-protocol-option-container"
                        key={option._id + 'result' + resultIndex}
                      >
                        <input
                          type="text"
                          placeholder="Nytt alternativ"
                          value={result}
                          onChange={(e) => {
                            const tempOptions = [
                              ...protocolOptions[protocolOptionsSelectedType],
                            ];
                            tempOptions[optionIndex].results[resultIndex] =
                              e.target.value;
                            setProtocolOptions({
                              ...protocolOptions,
                              [protocolOptionsSelectedType]: tempOptions,
                            });
                          }}
                        />
                        <Button
                          look="danger"
                          small
                          onClick={() => {
                            const tempOptions = [
                              ...protocolOptions[protocolOptionsSelectedType],
                            ];
                            tempOptions[optionIndex].results.splice(
                              resultIndex,
                              1
                            );
                            setProtocolOptions({
                              ...protocolOptions,
                              [protocolOptionsSelectedType]: tempOptions,
                            });
                          }}
                        >
                          <Plus cross color={colors.white} />
                        </Button>
                      </div>
                    ))}
                    <Button
                      small
                      onClick={() => {
                        const tempOptions = [
                          ...protocolOptions[protocolOptionsSelectedType],
                        ];
                        tempOptions[optionIndex].results.push('');
                        setProtocolOptions({
                          ...protocolOptions,
                          [protocolOptionsSelectedType]: tempOptions,
                        });
                      }}
                    >
                      Lägg till alternativ
                    </Button>
                  </div>
                  {protocolOptionsSelectedType !== 'RR' &&
                  protocolOptionsSelectedType !== 'MR' ? (
                    <div>
                      <p className="strong">Rekommendationer</p>
                      {option.recommendations.map(
                        (recommendation, recommendationIndex) => (
                          <div
                            className="add-protocol-option-container"
                            key={option._id + 'rec' + recommendationIndex}
                          >
                            <input
                              type="text"
                              placeholder="Nytt alternativ"
                              value={recommendation}
                              onChange={(e) => {
                                const tempOptions = [
                                  ...protocolOptions[
                                    protocolOptionsSelectedType
                                  ],
                                ];
                                tempOptions[optionIndex].recommendations[
                                  recommendationIndex
                                ] = e.target.value;
                                setProtocolOptions({
                                  ...protocolOptions,
                                  [protocolOptionsSelectedType]: tempOptions,
                                });
                              }}
                            />
                            <Button
                              look="danger"
                              small
                              onClick={() => {
                                const tempOptions = [
                                  ...protocolOptions[
                                    protocolOptionsSelectedType
                                  ],
                                ];
                                tempOptions[optionIndex].recommendations.splice(
                                  recommendationIndex,
                                  1
                                );
                                setProtocolOptions({
                                  ...protocolOptions,
                                  [protocolOptionsSelectedType]: tempOptions,
                                });
                              }}
                            >
                              <Plus cross color={colors.white} />
                            </Button>
                          </div>
                        )
                      )}
                      <Button
                        small
                        onClick={() => {
                          const tempOptions = [
                            ...protocolOptions[protocolOptionsSelectedType],
                          ];
                          tempOptions[optionIndex].recommendations.push('');
                          setProtocolOptions({
                            ...protocolOptions,
                            [protocolOptionsSelectedType]: tempOptions,
                          });
                        }}
                      >
                        Lägg till alternativ
                      </Button>
                    </div>
                  ) : null}
                </div>
                <hr />
              </div>
            )
          )}
      </Card>
    </StyledAdminProtocolOptions>
  );
};

export default AdminProtocolOptions;
