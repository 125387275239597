import { parseDate } from '../utils/parseDate.util';
import Button from './Button';
import Card from './Card';
import DotsDropdown from './DotsDropdown';
import { DocumentPageCardProps } from './DocumentPage';
import { WorkorderBM } from '@bm-js/h2o-shared';
import { Fragment } from 'react';

type Props = DocumentPageCardProps<WorkorderBM>;

const WorkorderCard = ({
  entry,
  deleteEntryInit,
  editEntryInit,
  sendEntryInit,
  downloadPdf,
  previewPdf,
  duplicateEntry,
}: Props) => {
  return (
    <div>
      <Card className="order-grid">
        <p className="mobile">
          {entry.startDateTimeNew
            ? parseDate(entry.startDateTimeNew)
            : parseDate(entry.startDateTime)}
        </p>
        <p>
          {entry.orderPersons?.length
            ? entry.orderPersons.map((orderPerson, i) => (
                <Fragment key={`workorder_orderperson_${orderPerson._id}_${i}`}>
                  {orderPerson.name}
                  <br />
                </Fragment>
              ))
            : entry.orderPerson?.name}
        </p>
        <p>
          {entry.technicians.map((technician, i) => (
            <Fragment key={`workorder_technichian_${technician._id}_${i}`}>
              {technician.firstName} {technician.lastName}
              <br />
            </Fragment>
          ))}
        </p>
        <p>{entry.table.length}</p>
        <p className="mobile">{entry.status}</p>
        <DotsDropdown
          content={
            <>
              <Button small onClick={() => previewPdf(entry)}>
                Förhandsvisa
              </Button>
              <Button small onClick={() => downloadPdf(entry)}>
                Ladda ned
              </Button>
              <Button small onClick={() => editEntryInit(entry)}>
                Redigera
              </Button>
              <Button small onClick={() => duplicateEntry(entry)}>
                Duplicera
              </Button>
              <Button small onClick={() => sendEntryInit(entry)}>
                Skicka som e-post
              </Button>
              <Button
                small
                onClick={() => deleteEntryInit(entry)}
                look="danger"
              >
                Radera
              </Button>
            </>
          }
        />
      </Card>
    </div>
  );
};

export default WorkorderCard;
