import axios from 'axios';
import { FormEventHandler, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { ActionType } from '../types/dispatch.types';
import { UserFM } from '@bm-js/h2o-shared';
import { envService } from '../services/services';
import { colors } from '../cnst/colors.cnst';
import { useNavigate } from 'react-router';
import { ROUTE } from '../cnst/router.cnst';

const StyledLoginRoute = styled.div`
  display: flex;
  .login-left-section {
    width: 40vw;
    min-width: 500px;
    background: ${colors.white};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 0px 14px rgba(0, 0, 0, 0.1);

    form {
      margin-bottom: 10rem;
      h3 {
        margin-bottom: 3rem;
        margin-top: 1rem;
      }
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-grow: 1;
      input,
      button {
        max-width: calc(500px - 6rem);
        width: 100%;
      }
    }
    .login-left-section-bottom {
      background: ${colors.lightBlue};
      color: ${colors.white};
      text-align: center;
      padding: 1.5rem;
      a {
        text-decoration: underline;
      }
    }
  }
  img {
    width: 80px;
  }
  .login-right-section {
    background-size: cover;
    background-image: url('/images/water.jpg');
    width: 100%;
    opacity: 0.2;
  }
  @media (max-width: 1000px) {
    display: block;
    .login-left-section {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
      form {
        padding: 1rem;
      }
    }
    .login-right-section {
      display: none;
    }
  }
`;

const LoginRoute = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const handleLogin: FormEventHandler = async (e) => {
    try {
      dispatch({ type: ActionType.LOADING, value: true });
      e.preventDefault();
      const body = {
        user: {
          email: email,
          password: password,
        },
      };

      const res = await axios.post<{
        user: UserFM;
        token: string;
        numberOfUnreadNotifications: number;
      }>(`${envService.API_URL}users/no-auth/login`, body);

      const { user, token, numberOfUnreadNotifications } = res.data;
      dispatch({
        type: ActionType.TOKEN_VERIFIED,
        token: token,
        user: user,
        numberOfUnreadNotifications,
      });
      localStorage.setItem('token', token);
      await navigate(ROUTE.NOTIFICATIONS);
    } catch {
      dispatch({
        type: ActionType.ALERT,
        content: 'Felaktiga inloggningsuppgifter',
      });
      dispatch({ type: ActionType.LOADING, value: false });
    }
  };

  useEffect(() => {
    if (state.tokenVerified) {
      void navigate(ROUTE.NOTIFICATIONS);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.tokenVerified) return null;

  return (
    <StyledLoginRoute>
      <div className="login-left-section">
        <form onSubmit={handleLogin}>
          <img src="/images/logo.png" alt="logo" />
          <h1>Logga in</h1>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Mailadress"
            autoComplete="email"
          />
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Lösenord"
            autoComplete="current-password"
          />
          <Button type="submit">Logga in</Button>
        </form>
        <div className="login-left-section-bottom">
          <a href="https://h2oteknik.se">Gå till H2Oteknik.se</a>
        </div>
      </div>
      <div className="login-right-section"></div>
    </StyledLoginRoute>
  );
};

export default LoginRoute;
