import { useContext } from 'react';
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from '../context/GlobalContextProvider';

const useGlobalState = () => {
  const state = useContext(GlobalStateContext);
  return state;
};

const useDispatch = () => {
  const dispatch = useContext(GlobalDispatchContext);
  return dispatch;
};

export const useGlobal = () => {
  const state = useGlobalState();
  const dispatch = useDispatch();
  return { state, dispatch };
};
