import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Breadcrumbs from '../components/Breadcrumbs';
import { colors } from '../cnst/colors.cnst';
import { request } from '../utils/request';
import { MunipRegBM, ProtocolOptionBM, UserFM } from '@bm-js/h2o-shared';
import AdminMunipRegs from '../components/AdminMunipRegs';
import { useGlobal } from '../hooks/useGlobalState';
import {
  AdminProtocolOptionsType,
  ProtocolType,
} from '../types/protocol.types';
import AdminDocumentTemplates from '../components/AdminDocumentTemplates';
import AdminProtocolOptions from '../components/AdminProtocolOptions';
import AdminUsers from '../components/AdminUsers';
import AdminCustomers from '../components/AdminCustomers';

const StyledAdminRoute = styled.div`
  .dual {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }
  h5 {
    margin-bottom: 0.7rem !important;
  }
  p.strong {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .search-box {
    position: relative;
    button {
      border: 1px solid ${colors.darkGrey};

      position: absolute;
      top: 0.3rem;
      right: 0.3rem;
      margin: 0;
    }
    input {
      min-width: 250px;
    }
  }
  .mbottom05 {
    margin-bottom: 0.5rem !important;
  }

  @media (max-width: 1000px) {
    .dual {
      grid-template-columns: 1fr;
    }
  }
`;

const AdminRoute = () => {
  const { state, dispatch } = useGlobal();

  const [users, setUsers] = useState<UserFM[]>([]);
  const [munipReg, setMunipReg] = useState<MunipRegBM[]>([]);
  const [templateFilesList, setTemplateFilesList] = useState<
    Record<string, string[]>
  >({});

  const [protocolOptions, setProtocolOptions] =
    useState<AdminProtocolOptionsType>({
      [ProtocolType.INSP]: [],
      [ProtocolType.KONT]: [],
      [ProtocolType.MR]: [],
      [ProtocolType.RR]: [],
    });

  const getAdminData = async () => {
    const adminDataPromise = request<{
      users: UserFM[];
      munipreg: MunipRegBM[];
      protocolOptions: ProtocolOptionBM[];
    }>({
      state,
      dispatch,
      path: 'customers/admindata-all-but-customers',
    });
    const fileListPromise = request<Record<string, string[]>>({
      state,
      dispatch,
      isDoServer: true,
      path: 'files/{token}/templates/list',
    });
    const [{ data: adminData }, { data: fileList }] = await Promise.all([
      adminDataPromise,
      fileListPromise,
    ]);

    if (!adminData || !fileList) return;
    setUsers(adminData.users);
    setMunipReg(adminData.munipreg);
    setTemplateFilesList(fileList);
    const protocolOptsMap = adminData.protocolOptions.reduce((acc, curr) => {
      acc[curr.protocolType as ProtocolType] = curr.keys;
      return acc;
    }, {} as AdminProtocolOptionsType);
    setProtocolOptions(protocolOptsMap);
  };

  useEffect(() => {
    if (state.token) getAdminData();
    // eslint-disable-next-line
  }, [state.token]);

  return (
    <StyledAdminRoute>
      <h1 className="page-header">Admin</h1>
      <Breadcrumbs crumbs={[{ to: '/home', p: 'Hem' }, { p: 'Admin' }]} />
      <div className="single-customer-inner">
        <div className="single-customer-left-content">
          <AdminCustomers />
          <AdminUsers users={users} revalidate={getAdminData} />
          <AdminProtocolOptions
            protocolOptions={protocolOptions}
            setProtocolOptions={setProtocolOptions}
            revalidate={getAdminData}
          />
        </div>
        <div className="single-customer-right-content">
          <AdminDocumentTemplates
            templateFilesList={templateFilesList}
            revalidate={getAdminData}
          />
          <AdminMunipRegs munipReg={munipReg} revalidate={getAdminData} />
        </div>
      </div>
    </StyledAdminRoute>
  );
};

export default AdminRoute;
