import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from '../components/Button';
import OverlayBox from '../components/OverlayBox';
import ReservoirForm from '../components/ReservoirForm';
import ReservoirCard from '../components/ReservoirCard';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { request } from '../utils/request';
import { CustomerBM, ReservoirBM, UserFM } from '@bm-js/h2o-shared';
import { ActionType } from '../types/dispatch.types';
import { envService } from '../services/services';
import { useQuery } from '../hooks/useQuery';

const StyledReservoirsRoute = styled.div`
  .single-customer-inner {
    display: flex;
    .single-customer-left-content {
      width: 60%;
      margin-right: 1.5rem;
    }
    .single-customer-right-content {
      width: calc(40% - 1.5rem);
    }
  }
  @media (max-width: 1000px) {
    .single-customer-inner {
      flex-direction: column;
      .single-customer-left-content {
        width: 100%;
      }
      .single-customer-right-content {
        width: 100%;
      }
    }
  }
`;

const ReservoirsRoute = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const query = useQuery<{ customerId: string }>();

  const [customer, setCustomer] = useState<CustomerBM>();
  const [addReservoirs, setAddReservoirs] = useState({
    active: false,
  });
  const [linkedReservoirs, setLinkedReservoirs] = useState<
    null | (ReservoirBM & { customerName: string; customerId: string })[]
  >(null);
  const [linkedReservoirsLoading, setLinkedReservoirsLoading] = useState(false);

  const getCustomer = () => {
    const { customerId } = query;
    if (customerId) {
      axios
        .get(`${envService.API_URL}customers/single/${customerId}`, {
          headers: { Authorization: 'Bearer ' + state.token },
        })
        .then((res) => {
          setCustomer(res.data);
          if (res.data.linkedReservoirIds?.length) {
            getLinkedReservoirs(res.data.linkedReservoirIds);
          }
          dispatch({ type: ActionType.LOADING, value: false });
        })
        .catch(() => {
          dispatch({ type: ActionType.LOADING, value: false });
        });
    }
  };

  const getLinkedReservoirs = (ids: string[]) => {
    setLinkedReservoirsLoading(true);
    axios
      .post(
        `${envService.API_URL}customers/linked-reservoirs`,
        { ids },
        {
          headers: {
            Authorization: 'Bearer ' + state.token,
          },
        }
      )
      .then((res) => {
        setLinkedReservoirs(res.data);
        setLinkedReservoirsLoading(false);
      })
      .catch(() => {
        setLinkedReservoirsLoading(false);
      });
  };

  const handleAddReservoir = async (
    formData: Partial<ReservoirBM>,
    isAutoSave?: boolean
  ) => {
    if (isAutoSave) return;
    const mongoBody = { reservoir: { ...formData } };
    delete mongoBody.reservoir.images;
    delete mongoBody.reservoir.drawings;

    await request({
      state,
      dispatch,
      method: 'POST',
      path: `customers/reservoirs/add/${customer?._id}`,
      body: mongoBody,
      successText: 'Reservoar sparad',
    });

    resetForm();
    getCustomer();
  };

  const resetForm = () => {
    setAddReservoirs({
      ...addReservoirs,
      active: false,
    });
  };

  useEffect(() => {
    dispatch({ type: ActionType.LOADING, value: true });
    if (state.token) {
      getCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  if (!customer) return null;

  const { customerInformation, reservoirs } = customer;
  return (
    <StyledReservoirsRoute>
      <h1 className="page-header">{customerInformation.name} — Reservoarer</h1>

      <Breadcrumbs
        crumbs={[
          { to: '/home', p: 'Hem' },
          { to: '/customers', p: 'Kunder' },
          {
            to: `/customers/single/?id=${customer?._id}`,
            p: customerInformation?.name,
          },
          { p: 'Reservoarer' },
        ]}
        rightContent={
          (state.user as UserFM).role === 'Admin' ? (
            <Button
              onClick={() => {
                setAddReservoirs({ ...addReservoirs, active: true });
              }}
              small
            >
              Lägg till reservoar
            </Button>
          ) : null
        }
      />
      {reservoirs?.length > 0 ? (
        <>
          {reservoirs.map((reservoir) => (
            <ReservoirCard
              reservoir={reservoir}
              key={reservoir._id}
              customerId={customer?._id}
            />
          ))}
          {linkedReservoirsLoading ? (
            <p>Laddar...</p>
          ) : (
            <>
              {linkedReservoirs?.length
                ? linkedReservoirs.map((reservoir) => (
                    <ReservoirCard
                      reservoir={{
                        ...reservoir,
                        basicInformation: {
                          ...reservoir.basicInformation,
                          name: `${reservoir.basicInformation.name} (${reservoir.customerName})`,
                        },
                      }}
                      key={reservoir._id}
                      customerId={reservoir.customerId}
                    />
                  ))
                : null}
            </>
          )}
        </>
      ) : (
        <>
          {linkedReservoirsLoading ? (
            <p>Laddar...</p>
          ) : (
            <>
              {linkedReservoirs?.length ? (
                linkedReservoirs.map((reservoir) => (
                  <ReservoirCard
                    reservoir={{
                      ...reservoir,
                      basicInformation: {
                        ...reservoir.basicInformation,
                        name: `${reservoir.basicInformation.name} (${reservoir.customerName})`,
                      },
                    }}
                    key={reservoir._id}
                    customerId={reservoir.customerId}
                  />
                ))
              ) : (
                <p>Det finns inga reservoarer ännu</p>
              )}
            </>
          )}
        </>
      )}
      {!!addReservoirs.active && (
        <OverlayBox
          header="Lägg till reservoar"
          active
          close={() => setAddReservoirs({ ...addReservoirs, active: false })}
          closeWarning
        >
          <ReservoirForm handleSubmit={handleAddReservoir} />
        </OverlayBox>
      )}
    </StyledReservoirsRoute>
  );
};

export default ReservoirsRoute;
