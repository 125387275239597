import { envService } from '../services/services';

const get_do_url = () => {
  if (envService.USE_LOCAL_SERVER) return 'http://localhost:3002/';
  return envService.IS_DEVELOPMENT
    ? 'https://dev-h2o-api.bucketmedia.se/'
    : 'https://h2o-api-v2.bucketmedia.se/';
};

export const do_url = get_do_url();
