import { months } from '../cnst/data.cnst';

export const getProtocolFilename = (
  reservoirName: string,
  customerName: string,
  protocolName: string,
  dateForInspection: string | Date
) => {
  const dateObj = new Date(dateForInspection);
  return `${reservoirName}_${customerName}_${protocolName}_${dateObj.getFullYear()}_${
    months[dateObj.getMonth()]
  }.pdf`;
};

export const getProtocolString = (
  dateForInspection: string | Date,
  protocolName: string
) => {
  const dateObj = new Date(dateForInspection);
  return `${dateObj.getFullYear()} ${
    months[dateObj.getMonth()]
  } ${protocolName}`;
};
