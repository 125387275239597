import { ProtocolType } from '../types/protocol.types';

export const allCounties = [
  'Blekinge län',
  'Dalarnas län',
  'Gotlands län',
  'Gävleborgs län',
  'Hallands län',
  'Jämtlands län',
  'Jönköpings län',
  'Kalmar län',
  'Kronobergs län',
  'Norrbottens län',
  'Skåne län',
  'Stockholms län',
  'Södermanlands län',
  'Uppsala län',
  'Värmlands län',
  'Västerbottens län',
  'Västernorrlands län',
  'Västmanlands län',
  'Västra Götalands län',
  'Örebro län',
  'Östergötlands län',
];

type ServiceOption = {
  name: string;
  priceExVat: number;
  id?: string;
  sortBottom?: boolean;
  disabled?: boolean;
};

export const serviceOptions: ServiceOption[] = [
  { name: 'Inspektion tömd reservoar', priceExVat: 8900, id: 'insp' },
  { name: 'Inspektion under drift', priceExVat: 8900, id: 'insp' },
  { name: 'Kontroll tömd reservoar', priceExVat: 4900, id: 'kont' },
  { name: 'Kontroll under drift ', priceExVat: 4900, id: 'kont' },
  { name: 'Kranarm H2O/ABAT med golv/vägg-fäste ', priceExVat: 18000 },
  { name: 'Manuell rengöring', priceExVat: 0, id: 'mr' },
  { name: 'Montage avfuktare', priceExVat: 0, id: 'mont' },
  { name: 'Montage kranarm', priceExVat: 4000, id: 'mont' },
  { name: 'Montage säkerhetslucka', priceExVat: 8500, id: 'mont' },
  { name: 'Montage säkerhetslucka dubbel', priceExVat: 12500, id: 'mont' },
  { name: 'Montage H2O-ventiltion', priceExVat: 4000, id: 'mont' },
  { name: 'Pollenfilter AV-125 - F7', priceExVat: 1300 },
  { name: 'Pollenfilter AV-200 - F7', priceExVat: 1500 },
  { name: 'Pollenfilter H2O med membran - F7', priceExVat: 1500 },
  { name: 'Pollenfilter Scandfilter - F7', priceExVat: 1500 },
  { name: 'Robotrengöring VR50/VR600', priceExVat: 18000, id: 'rr' },
  { name: 'Sanering svartmögel', priceExVat: 0, id: 'mont' },
  { name: 'Stege i rostfritt för dricksvattenreservoar', priceExVat: 0 },
  { name: 'Montage stege i rostfritt', priceExVat: 0 },
  { name: 'Vattenglas-behandling', priceExVat: 0 },
  { name: 'Serviceavtal 2 år', priceExVat: 0 },
  { name: 'Serviceavtal 3 år', priceExVat: 0 },
  { name: 'Serviceavtal 4 år', priceExVat: 0 },
  { name: 'Serviceavtal 5 år', priceExVat: 0 },
  { name: 'Särskild byggnadsteknisk besiktning', priceExVat: 29000, id: 'bed' },
  { name: 'Tillståndsbedömning betong', priceExVat: 19000, id: 'bed' },
  { name: 'Trycksäkringsmembran AV-125', priceExVat: 250 },
  { name: 'Trycksäkringsmembran AV-200', priceExVat: 300 },
  { name: 'Tätningslist manlucka', priceExVat: 0 },
  {
    name: 'Ventilationshus UTE med pollenfilter F7, stos anpassad för befintligt rör',
    priceExVat: 17500,
  },
  {
    name: 'Ventilationshus UTE med pollenfilter F7, stos anpassad för befintligt rör, 90 graders böj samt täckplatta vägg',
    priceExVat: 23500,
  },
  { name: 'Ventilationshus INNE med pollenfilter F7', priceExVat: 14900 },
  {
    name: 'Ventilationshus INNE med pollenfilter F7, komplett med rör och stos',
    priceExVat: 0,
  },
  { name: 'Milersättning kr/mil', priceExVat: 65, sortBottom: true },
  { name: 'Reseersättning kr/tim', priceExVat: 400, sortBottom: true },
  { name: 'Logi kr/natt', priceExVat: 1500, sortBottom: true },
  { name: 'Stilleståndstillägg', priceExVat: 795, sortBottom: true },
];

export const workPlanRiskOverviewItems = [
  {
    key: 'enclosedSpaces',
    text: 'Slutna utrymmen',
    value: true,
  },
  {
    key: 'el12v',
    text: 'El 12 v',
    value: true,
  },
  {
    key: 'el220v',
    text: 'El 220 v',
    value: true,
  },
  {
    key: 'fallRisk',
    text: 'Arbete med risk för fall från högre höjd än två meter',
    value: true,
  },
  {
    key: 'slipRisk',
    text: 'Halkrisk',
    value: true,
  },
  {
    key: 'squeezeRisk',
    text: 'Klämrisk',
    value: true,
  },
  {
    key: 'chlorineUse',
    text: 'Kemikalieanvändning (klor)',
    value: true,
  },
  {
    key: 'mercuryComponents',
    text: 'Kvicksilverkomponenter',
    value: false,
  },
  {
    key: 'drowningRisk',
    text: 'Arbete i reservoar med risk för drunkning eller kvävning',
    value: true,
  },
  {
    key: 'contaminationRisk',
    text: 'Arbete i reservoar med risk för kontaminering',
    value: true,
  },
  {
    key: 'chemicalContainers',
    text: 'Uppställning av behållare innehållande bränsle eller kemikalier',
    value: true,
  },
  {
    key: 'hotWork',
    text: 'Heta arbeten',
    value: false,
  },
];

export const workPlanEquipmentItems = [
  {
    key: 'lights12v',
    text: '12v belysning',
    value: true,
  },
  {
    key: 'savingTripod',
    text: 'Räddningstripodd',
    value: true,
  },
  {
    key: 'airFan',
    text: 'Tillluftsfläkt',
    value: true,
  },
  {
    key: 'hotWaterWash',
    text: 'Hetvattentvätt med tillbehör',
    value: true,
  },
  {
    key: 'handTools',
    text: 'Handverktyg',
    value: true,
  },
  {
    key: 'floater',
    text: 'Flotte',
    value: false,
  },
  {
    key: 'scaffolding',
    text: 'Ställning',
    value: false,
  },
  {
    key: 'ladder',
    text: 'Stege',
    value: false,
  },
  {
    key: 'natriumHypoklorit',
    text: 'Natriumhypoklorit (för klorering av verktyg/ utrustning och ytor)',
    value: true,
  },
  {
    key: 'personalSafetyEquipment',
    text: 'Personlig skyddsutrustning',
    value: true,
  },
  {
    key: 'plasmaCutter',
    text: 'Plasmaskärare samt arbete med rondell',
    value: false,
  },
];

export const workPlanWorkMomentDescriptions = [
  { text: 'Manuell rengöring av dricksvattenreservoar', key: 'manualCleaning' },
  {
    text: 'Tillståndsbedömning av betong inne i dricksvattenreservoar',
    key: 'statusCheck',
  },
  { text: 'Inspektion inne i dricksvattenreservoar', key: 'innerInspection' },
  { text: 'Montage av stege i dricksvattenreservoar', key: 'ladderMounting' },
  {
    text: 'Montage av ny manlucka/säkerhetslucka ovanför dricksvattenreservoar',
    key: 'hatchMounting',
  },
  { text: 'Arbete i manluckehalsen', key: 'hatchWork' },
  { text: '', key: 'other' },
];

export const pdfTemplateTypes = [
  {
    name: 'Offert',
    key: 'offer',
  },
  {
    name: 'Serviceavtal',
    key: 'servicedeal',
  },
  {
    name: 'Arbetsorder',
    key: 'workorder',
  },
  {
    name: 'Arbetsmiljöplan',
    key: 'workplan',
  },
  {
    name: 'Servicelogg',
    key: 'servicelog',
  },
  {
    name: 'Fakturaunderlag',
    key: 'invoice',
  },
];

export const customerDocumentButtons = [
  { typeName: 'Offert', key: 'offers' },
  { typeName: 'Serviceavtal', key: 'servicedeal', useBasePage: true },
  { typeName: 'Arbetsorder', key: 'workorders' },
  { typeName: 'Arbetsmiljöplan', key: 'workplans' },
  { typeName: 'Servicelogg', key: 'servicelogs' },
  { typeName: 'Orderkännande', key: 'order-confirmations' },
];

export const protocolMap: Record<ProtocolType, string> = {
  insp: 'Inspektion',
  kont: 'Kontroll',
  RR: 'Robotrengöring',
  MR: 'Manuell rengöring',
};

export const months = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December',
];
