export const colors = {
  white: '#fff',
  black: '#000',
  lightBlue: '#0D6EF4',
  lighterBlue: '#95C1FF',
  lightBlueFaded: 'rgba(13, 110, 244, 0.7)',
  lightGrey: '#EDEDED',
  darkGrey: '#414141',
  mediumGrey: '#A8A8A8',
  red: '#e63232',
  redFaded: 'rgba(230,50,50,0.7)',
  yellowAlert: '#fff875',
  orangeAlert: '#fabe75',
  redAlert: '#ffb5b5',
  pdfBlue: '#0074BD',
  pdfGrey: '#ddd',
};
