import { PropsWithChildren, useMemo } from 'react';
import { useLocation } from 'react-router';
import { ROUTE, routesWithoutLayout } from '../cnst/router.cnst';
import Navbar from './Navbar';
import styled from 'styled-components';
import { usePollNotifications } from '../hooks/usePollNotifications';
import { envService } from '../services/services';
import MaintenanceOverlay from './MaintenanceOverlay';

const StyledLayout = styled.div<{ $clean?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f2f2f2;
  .app-inner {
    &.left-space {
      margin-left: 200px;
    }
    flex-grow: 1;
    padding: ${({ $clean }) => ($clean ? ' 0' : ' 2rem')};
  }

  @media (max-width: 1350px) {
    .single-customer-inner {
      flex-direction: column;
      .single-customer-left-content {
        width: 100%;
      }
      .single-customer-right-content {
        width: 100%;
      }
    }
    .app-inner.left-space {
      margin-left: 120px;
    }
  }
  @media (max-width: 1000px) {
    .app-inner {
      padding: ${({ $clean }) => ($clean ? ' 0' : ' 1rem')};
      &.left-space {
        margin-left: 0;
        margin-top: 70px;
      }
    }
  }
`;

const Layout = ({ children }: PropsWithChildren) => {
  const location = useLocation();

  const shouldRenderLayout = useMemo(() => {
    return !routesWithoutLayout.includes(
      location.pathname.toLowerCase() as ROUTE
    );
  }, [location]);

  if (envService.IS_MAINTENANCE === 'true') {
    return <MaintenanceOverlay />;
  }

  if (!shouldRenderLayout) return children;

  return <_Layout>{children}</_Layout>;
};

export default Layout;

const _Layout = ({ children }: PropsWithChildren) => {
  usePollNotifications();

  return (
    <StyledLayout>
      <Navbar />
      <div className={`app-inner left-space`}>{children}</div>
    </StyledLayout>
  );
};
