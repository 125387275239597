import { useContext, useMemo, useState } from 'react';
import { request } from '../utils/request';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { CustomerFM } from '@bm-js/h2o-shared';
import Card from './Card';
import Button from './Button';
import MultilineText from './MultilineText';

type Props = {
  customer: CustomerFM;
  getCustomer: () => Promise<void>;
};

const editCommentDefault = {
  active: false,
  comment: '',
};

const CustomerComments = ({ customer, getCustomer }: Props) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [commentEdit, setCommentEdit] = useState(editCommentDefault);

  const saveMainComment = async () => {
    const body = {
      mainComment: commentEdit.comment,
    };
    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      body,
      path: `customers/update-main-comment/${customer._id}`,
      successText: 'Kommentar sparad',
    });
    if (err) return;
    setCommentEdit(editCommentDefault);
    getCustomer();
  };

  const editInit = () => {
    setCommentEdit({
      comment: customer.mainComment,
      active: true,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentEdit({
      ...commentEdit,
      comment: e.target.value,
    });
  };

  const editAbort = () => {
    setCommentEdit({
      comment: customer.mainComment,
      active: false,
    });
  };

  const comment = useMemo(() => {
    if (!customer.mainComment) return ['Det finns ingen kommentar ännu'];
    return customer.mainComment.split('\n');
  }, [customer.mainComment]);

  return (
    <Card>
      <div className="customer-card-header">
        <h3>Kommentarer</h3>
        <div className="customer-card-header-right-content">
          {!commentEdit.active && (
            <Button noBg onClick={editInit}>
              Redigera
            </Button>
          )}
        </div>
      </div>

      {commentEdit.active ? (
        <>
          <textarea value={commentEdit.comment} onChange={handleChange} />
          <div className="flex gap05">
            <Button small onClick={() => saveMainComment()}>
              Spara
            </Button>
            <Button small onClick={editAbort}>
              Avbryt
            </Button>
          </div>
        </>
      ) : (
        <MultilineText text={comment} />
      )}
    </Card>
  );
};

export default CustomerComments;
