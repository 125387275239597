export class EnvService {
  public API_URL: string;
  public USE_LOCAL_SERVER: string | undefined;
  public IS_DEVELOPMENT: string | undefined;
  public IS_MAINTENANCE: string | undefined;
  constructor(parent: Record<string, string | undefined>) {
    this.API_URL = parent.VITE_API_URL!;
    this.USE_LOCAL_SERVER = parent.VITE_USE_LOCAL_SERVER;
    this.IS_DEVELOPMENT = parent.VITE_IS_DEVELOPMENT;
    this.IS_MAINTENANCE = parent.VITE_IS_MAINTENANCE;

    const keys = ['API_URL'] as const;

    keys.forEach((key) => {
      if (!this[key]) throw new Error(`${key} is not set`);
    });
  }
}
