import OverlayBox from './OverlayBox';
import { parseDate } from '../utils/parseDate.util';
import { Notification } from '@bm-js/h2o-shared';

type InternalNotificationModal = {
  active: boolean;
  notification?: Notification;
};

type Props = {
  viewInternalNotificationModal: InternalNotificationModal;
  setViewInternalNotificationModal: (v: InternalNotificationModal) => void;
};

const InternalNotificationModal = ({
  viewInternalNotificationModal,
  setViewInternalNotificationModal,
}: Props) => {
  if (!viewInternalNotificationModal.notification) return null;

  return (
    <OverlayBox
      header={viewInternalNotificationModal.notification.subject}
      active={viewInternalNotificationModal.active}
      close={() =>
        setViewInternalNotificationModal({
          ...viewInternalNotificationModal,
          active: false,
        })
      }
    >
      <p>
        <strong>Datum för hantering: </strong>
        {parseDate(viewInternalNotificationModal.notification.dateForHandling)}
      </p>
      <p>
        <strong>Hanteras av: </strong>
        {`${viewInternalNotificationModal.notification.recipientFirstName} ${viewInternalNotificationModal.notification.recipientLastName}`}
      </p>
      <p>
        <strong>Innehåll: </strong>
        {viewInternalNotificationModal.notification.textContent}
      </p>
    </OverlayBox>
  );
};

export default InternalNotificationModal;
