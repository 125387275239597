import { useMemo, useState } from 'react';
import ProtocolActions from './ProtocolActions';
import BreadcrumbsV2 from './BreadcrumbsV2';
import styled from 'styled-components';
import { colors } from '../cnst/colors.cnst';
import BtnNoStyle from './BtnNoStyle';
import { ArrowDown, ArrowUp } from 'lucide-react';

const StyledProtocolHeader = styled.div`
  .hide-button {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    background: ${colors.lightBlue};
    border-radius: 0 0 0 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.3rem 0.3rem 0.3rem 0.5rem;
  }
  .protocol-header-content {
    transition: top 200ms ease-in-out;
    position: fixed;
    width: calc(100% - 200px);
    left: 200px;
    top: 0;
    background: ${colors.white};
    z-index: 2;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;

    &-inner {
      display: flex;
      justify-content: space-between;
    }
    h1 {
      margin: 0 !important;
    }
  }
  .sticky-placeholder {
    transition: 200ms ease-in-out;
    height: 7rem;
  }
  &.hidden {
    .protocol-header-content {
      top: -7rem;
    }
    .sticky-placeholder {
      height: 0;
    }
  }
  @media (max-width: 1350px) {
    &.hidden {
      .protocol-header-content {
        top: -10rem;
      }
    }
    .protocol-header-content {
      width: calc(100% - 120px);
      left: 120px;

      &-inner {
        flex-direction: column;
      }
    }
    .sticky-placeholder {
      height: 9rem;
    }
  }
  @media (max-width: 1000px) {
    .hide-button {
      top: 70px;
    }
    .protocol-header-content {
      top: 70px;
      width: 100vw;
      left: 0;
      h1 {
        font-size: 1.6rem !important;
      }
    }
    .sticky-placeholder {
      height: 8rem;
    }
  }
`;

type Props = {
  date: string;
  query: {
    type: string;
    protocolId: string;
    reservoirId: string;
    customerId: string;
    reservoirName: string;
    customerName: string;
  };
  createPdf: () => void;
  runningAction: boolean;
};

const ProtocolHeader = ({ date, query, createPdf, runningAction }: Props) => {
  const [hidden, setHidden] = useState(false);

  const year = useMemo(() => {
    if (!date) return new Date().getFullYear();
    return new Date(date).getFullYear();
  }, [date]);

  const typeName = useMemo(() => {
    return query.type.split(';')[0] || '';
  }, [query]);

  return (
    <StyledProtocolHeader className={hidden ? 'hidden' : ''}>
      <div className="hide-button">
        <BtnNoStyle onClick={() => setHidden(!hidden)}>
          {hidden ? (
            <ArrowDown stroke={colors.white} width={24} />
          ) : (
            <ArrowUp stroke={colors.white} width={24} />
          )}
        </BtnNoStyle>
      </div>
      <div className="protocol-header-content">
        <h1>{`${typeName} ${year}`}</h1>
        <div className="protocol-header-content-inner">
          <BreadcrumbsV2
            crumbs={[
              {
                to: `/customers/single/?id=${query.customerId}`,
                p: query.customerName,
              },
              {
                to: `/reservoirs/?customerId=${query.customerId}`,
                p: 'Reservoarer',
              },
              {
                to: `/reservoirs/single/?customerId=${query.customerId}&reservoirId=${query.reservoirId}`,
                p: query.reservoirName,
              },
              null,
            ]}
          />
          <ProtocolActions createPdf={createPdf} disabled={runningAction} />
        </div>
      </div>
      <div className="sticky-placeholder" />
    </StyledProtocolHeader>
  );
};

export default ProtocolHeader;
