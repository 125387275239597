import { CustomerBM, DateString, ProtocolFM, UserFM } from '@bm-js/h2o-shared';
import styled from 'styled-components';
import YearMonthPicker from './YearMonthPicker';
import DateInput from './DateInput';

const StyledCreateProtocolBasics = styled.div`
  .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  @media (max-width: 1000px) {
    .form-group {
      grid-template-columns: 1fr;
    }
  }
`;

type Props = {
  protocolData: Partial<ProtocolFM>;
  setProtocolData: (v: Partial<ProtocolFM>) => void;
  users: UserFM[];
  customer: CustomerBM;
  type?: string;
};

const recommendedActionsKeys: { key: keyof ProtocolFM; label: string }[] = [
  {
    key: 'nextRecommendedInspection',
    label: 'Nästa rekommenderade inspektion',
  },
  { key: 'nextRecommendedCleaning', label: 'Nästa rekommenderade rengöring' },
  {
    key: 'nextRecommendedFilterChange',
    label: 'Nästa rekommenderade filterbyte',
  },
  { key: 'nextRecommendedControl', label: 'Nästa rekommenderade kontroll' },
];

const CreateProtocolBasics = (props: Props) => {
  const { protocolData, setProtocolData, users, customer, type } = props;
  return (
    <StyledCreateProtocolBasics>
      <div className="dual">
        <div>
          <p>Datum för {type || 'inspektion'}</p>
          <DateInput
            required
            value={protocolData.dateForInspection}
            name="dateForInspection"
            placeholder="Datum för inspektion"
            onChange={(e) => {
              setProtocolData({
                ...protocolData,
                dateForInspection: e.target.value,
              });
            }}
          />
        </div>
        <div>
          <p>Ansvarig servicetekniker</p>
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            value={protocolData.responsibleTechnician}
            name="responsibleTechnician"
            onChange={(e) => {
              setProtocolData({
                ...protocolData,
                responsibleTechnician: e.target.value,
              });
            }}
          >
            <option value="">Ingen person vald</option>
            {users.map((user) => (
              <option
                key={user._id}
                value={user.firstName + ' ' + user.lastName}
              >
                {user.firstName + ' ' + user.lastName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="dual">
        <div>
          <p>Kontaktperson</p>
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            value={protocolData.contactPerson}
            onChange={(e) =>
              setProtocolData({
                ...protocolData,
                contactPerson: e.target.value,
              })
            }
          >
            <option value="">Ingen person vald</option>
            {customer.contactPersons?.map((person) => (
              <option key={person._id} value={person.name}>
                {person.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group">
        {recommendedActionsKeys.map((action) => (
          <YearMonthPicker
            label={action.label}
            key={`nextAction-${action.key}`}
            name={action.key}
            value={(protocolData[action.key] as DateString) || ''}
            onChange={(e) => {
              setProtocolData({
                ...protocolData,
                [action.key]: e.target.value,
              });
            }}
          />
        ))}
      </div>
    </StyledCreateProtocolBasics>
  );
};

export default CreateProtocolBasics;
