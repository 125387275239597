import styled from 'styled-components';
import Button from './Button';

const StyledProtocolActions = styled.div`
  display: flex;
  gap: 0.5rem;
  button {
    white-space: nowrap;
  }
  @media (max-width: 1000px) {
    gap: 0.3rem;
    button {
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
    }
  }
`;

type Props = {
  createPdf: (save: boolean, download: boolean) => void;
  disabled: boolean;
};

const ProtocolActions = ({ createPdf, disabled }: Props) => {
  const buttons: {
    text: string;
    onClick: () => void;
    type?: 'button' | 'submit';
  }[] = [
    {
      text: 'Skapa PDF',
      onClick: () => {
        createPdf(true, true);
      },
    },
    {
      text: 'Skapa & ladda ned PDF',
      onClick: () => {
        createPdf(true, false);
      },
    },
    {
      text: 'Spara',
      type: 'submit',
      onClick: () => {
        document.getElementById('submitNewProtocolButton')?.click();
      },
    },
  ];

  return (
    <StyledProtocolActions>
      {buttons.map((button, index) => (
        <Button
          key={index}
          small
          disabled={disabled}
          onClick={button.onClick}
          type={button.type || 'button'}
        >
          {button.text}
        </Button>
      ))}
    </StyledProtocolActions>
  );
};

export default ProtocolActions;
