import { ChangeEventHandler, useState } from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import Card from '../components/Card';
import OverlayBox from '../components/OverlayBox';
import { UserFM } from '@bm-js/h2o-shared';
import { ActionType } from '../types/dispatch.types';
import { trimObjectKeys } from '../utils/trimObjectKeys.util';
import { pick } from '../utils/pick.util';
import { request } from '../utils/request';
import { useGlobal } from '../hooks/useGlobalState';
import PasswordRequirements from '../components/PasswordRequirements';
import { colors } from '../cnst/colors.cnst';

const StyledMyAccountRoute = styled.div`
  .single-customer-inner {
    display: flex;
    .single-customer-left-content {
      width: 60%;
      margin-right: 1.5rem;
    }
    .single-customer-right-content {
      width: calc(40% - 1.5rem);
    }
  }
  form {
    p {
      margin-bottom: 0.5rem !important;
    }
  }
  .avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar-image-container {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        &.error {
          object-fit: contain;
        }
      }
    }
  }
  #avatarFileUpload {
    display: none;
  }

  .confirm-overlay-box {
    button {
      margin-right: 1rem;
    }
  }

  .password-requirements {
    margin-top: 1rem;
    border: 1px solid ${colors.lightBlue};
    padding: 1rem;
    border-radius: 0.5rem;
  }
  @media (max-width: 1000px) {
    .single-customer-inner {
      flex-direction: column;
      .single-customer-left-content {
        width: 100%;
      }
      .single-customer-right-content {
        width: 100%;
      }
    }
  }
  .confirm-overlay-box {
    button {
      margin-right: 0.5rem;
    }
    .customer-card-header {
      button {
        margin-right: 0;
      }
    }
  }
`;

const MyAccountRoute = () => {
  const { state, dispatch } = useGlobal();

  const stateUser = state.user as UserFM;

  const [userInfo, setUserInfo] = useState({
    firstName: stateUser.firstName,
    lastName: stateUser.lastName,
    email: stateUser.email,
    phone: stateUser.phone || '',
  });

  const [changePasswordFormData, setChangePasswordFormData] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: '',
  });
  const [passwordValid, setPasswordValid] = useState(false);

  const [confirmChange, setConfirmChange] = useState({
    active: false,
    proceed: () => {},
  });

  const onChangeUserInfo: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const updateUserInfo = async () => {
    const body = {
      user: trimObjectKeys(
        pick(userInfo, ['firstName', 'lastName', 'email', 'phone'])
      ),
    };

    const { err } = await request({
      path: 'users/auth/self-update',
      body,
      state,
      dispatch,
      method: 'POST',
      successText: 'Sparat, loggar ut...',
    });

    if (err) {
      if (err === 'Email exists') {
        dispatch({
          type: ActionType.ALERT,
          content: 'Mailadressen finns redan',
        });
      }
      return;
    }

    localStorage.removeItem('token');
    dispatch({ type: ActionType.LOGOUT });
  };

  const onChangePassword: ChangeEventHandler<HTMLInputElement> = (e) => {
    setChangePasswordFormData({
      ...changePasswordFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePassword = async () => {
    const body = {
      user: {
        oldPassword: changePasswordFormData.oldPassword,
        newPassword: changePasswordFormData.newPassword,
      },
    };

    const { err } = await request({
      path: 'users/auth/change-password',
      body,
      state,
      dispatch,
      method: 'POST',
      successText: 'Lösenord ändrat, loggar ut...',
    });

    if (err) {
      if (err === 'Invalid old password') {
        dispatch({
          type: ActionType.ALERT,
          content: 'Nuvarande lösenord är fel',
        });
      }
      return;
    }

    localStorage.removeItem('token');
    dispatch({ type: ActionType.LOGOUT });
  };

  return (
    <StyledMyAccountRoute>
      <h1 className="page-header">Mitt konto</h1>
      <div className="single-customer-inner">
        <div className="single-customer-left-content">
          <Card>
            <div className="customer-card-header">
              <h3>Ändra uppgifter</h3>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setConfirmChange({
                  active: true,
                  proceed: () => {
                    updateUserInfo();
                  },
                });
              }}
            >
              <input
                required
                type="text"
                name="firstName"
                value={userInfo.firstName}
                onChange={onChangeUserInfo}
                placeholder="Förnamn"
              />
              <input
                required
                type="text"
                name="lastName"
                value={userInfo.lastName}
                onChange={onChangeUserInfo}
                placeholder="Efternamn"
              />
              <input
                required
                type="text"
                name="email"
                value={userInfo.email}
                onChange={onChangeUserInfo}
                placeholder="E-post"
              />
              <input
                type="text"
                name="phone"
                value={userInfo.phone}
                onChange={onChangeUserInfo}
                placeholder="Telefonnummer"
              />
              <p>
                <strong>Roll:</strong> {stateUser.role}
              </p>
              <Button med type="submit">
                Spara
              </Button>
            </form>
          </Card>
          <Card>
            <div className="customer-card-header">
              <h3>Byt lösenord</h3>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setConfirmChange({
                  active: true,
                  proceed: () => {
                    handleChangePassword();
                  },
                });
              }}
            >
              <input
                required
                type="password"
                name="oldPassword"
                value={changePasswordFormData.oldPassword}
                onChange={onChangePassword}
                placeholder="Nuvarande lösenord"
              />
              <input
                required
                type="password"
                name="newPassword"
                value={changePasswordFormData.newPassword}
                onChange={onChangePassword}
                placeholder="Nytt lösenord"
              />
              <input
                required
                type="password"
                name="newPasswordRepeat"
                value={changePasswordFormData.newPasswordRepeat}
                onChange={onChangePassword}
                placeholder="Upprepa nytt lösenord"
              />
              <Button
                med
                type="submit"
                disabled={!passwordValid || !changePasswordFormData.oldPassword}
              >
                Spara
              </Button>
            </form>
            <div className="password-requirements">
              <PasswordRequirements
                confirmPassword={changePasswordFormData.newPasswordRepeat}
                newPassword={changePasswordFormData.newPassword}
                setPasswordValid={setPasswordValid}
              />
            </div>
          </Card>
        </div>
        <div className="single-customer-right-content"></div>
      </div>
      <OverlayBox
        className="confirm-overlay-box"
        active={confirmChange.active}
        header="Är du säker?"
        close={() =>
          setConfirmChange({
            active: false,
            proceed: () => {},
          })
        }
      >
        <p>
          När du sparar en ändring av din profilinformation så loggas du ut.
          <br />
          Vill du fortsätta?
        </p>
        <Button med onClick={confirmChange.proceed}>
          Fortsätt
        </Button>
        <Button
          med
          onClick={() =>
            setConfirmChange({
              active: false,
              proceed: () => {},
            })
          }
        >
          Tillbaka
        </Button>
      </OverlayBox>
    </StyledMyAccountRoute>
  );
};

export default MyAccountRoute;
