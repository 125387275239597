export const downloadPdf = (
  data: number[],
  filename = '',
  download?: boolean
) => {
  const arrayBuf = new Uint8Array(data);
  const blob = new Blob([arrayBuf], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  if (download) {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  } else {
    window.open(url);
  }
};
