import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import LinkPreventWrapper from './LinkPreventWrapper';
import { colors } from '../cnst/colors.cnst';
import { ArrowRight } from 'lucide-react';

type StyledProps = {
  $scrolledToEnd: boolean;
  $scrolledToStart?: boolean;
};

const StyledBreadcrumbsV2 = styled.div<StyledProps>`
  position: relative;
  .crumbs-container {
    padding: 0.5rem 0;
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 1000px) {
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 20px;
        height: 100%;
        pointer-events: none;
        transition: 200ms ease-in-out;
        opacity: 0;
      }
      &:before {
        left: 0;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 1),
          transparent
        );
        ${({ $scrolledToStart }) => (!$scrolledToStart ? 'opacity: 1;' : '')}
      }
      &:after {
        right: 0;
        ${({ $scrolledToEnd }) => (!$scrolledToEnd ? 'opacity: 1;' : '')}
        background: linear-gradient(to left, rgba(255, 255, 255, 1), transparent);
      }
    }
  }
`;

type Crumb = {
  to?: string;
  p?: string;
};

type Props = {
  crumbs: (Crumb | null)[];
};

const BreadcrumbsV2 = ({ crumbs }: Props) => {
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const [scrolledToEnd, setScrolledToEnd] = useState(false);
  const [scrolledToStart, setScrolledToStart] = useState(true);

  useEffect(() => {
    if (!scrollContainer.current) return;
    const container = scrollContainer.current;
    const scrollHandler = () => {
      if (container.scrollLeft === 0) {
        setScrolledToStart(true);
      } else {
        setScrolledToStart(false);
      }

      if (
        container.scrollLeft + container.clientWidth ===
        container.scrollWidth
      ) {
        setScrolledToEnd(true);
      } else {
        setScrolledToEnd(false);
      }
    };
    container.addEventListener('scroll', scrollHandler);

    return () => {
      container.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollContainer]);

  return (
    <StyledBreadcrumbsV2
      $scrolledToEnd={scrolledToEnd}
      $scrolledToStart={scrolledToStart}
    >
      <div className="crumbs-container" ref={scrollContainer}>
        {crumbs.map((crumb, index) => (
          <Crumb
            {...crumb}
            key={crumb?.p ? crumb.p + index : 'crumb' + index}
            crumbs={crumbs}
            index={index}
          />
        ))}
      </div>
    </StyledBreadcrumbsV2>
  );
};

export default BreadcrumbsV2;

const StyledCrumb = styled.div`
  color: ${colors.lightBlue};
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 0.5rem;
  &:hover {
    color: ${colors.lightBlueFaded};
  }
`;

type CrumbProps = {
  crumbs: (Crumb | null)[];
  p?: string;
  to?: string;
  index: number;
};

const Crumb = (props: CrumbProps) => {
  const { p, to, index, crumbs } = props;

  const shouldRenderArrow = useMemo(() => {
    const includesNullVal = crumbs.includes(null);
    const lastIndex = includesNullVal ? crumbs.length - 2 : crumbs.length - 1;
    if (index === lastIndex) return false;
    return true;
  }, [crumbs, index]);

  if (!props?.to && !props?.p) return null;

  if (to)
    return (
      <StyledCrumb>
        <LinkPreventWrapper to={to}>{p}</LinkPreventWrapper>
        {shouldRenderArrow && <Arrow />}
      </StyledCrumb>
    );
  return (
    <StyledCrumb>
      {p}
      {shouldRenderArrow && <Arrow />}
    </StyledCrumb>
  );
};

const Arrow = () => {
  return <ArrowRight width={16} stroke={colors.lightBlue} />;
};
