import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { request } from '../utils/request';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { getFileFromBuffer } from '../utils/getFileFromBuffer.util';

const StyledImageViewer = styled.img<{ $pointer?: boolean }>`
  max-width: 100%;
  display: block;
  ${({ $pointer }) => ($pointer ? 'cursor: pointer;' : '')}
`;

const StyledImageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: 200ms ease-in-out;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

type Props = {
  assetId: string;
  type?: string;
  className?: string;
  overlayOnClick?: boolean;
};

const ImageViewer = ({
  assetId,
  type = 'image/jpeg',
  className,
  overlayOnClick,
}: Props) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [src, setSrc] = useState<string | undefined>('');
  const [overlayOpen, setOverlayOpen] = useState(false);

  const getImage = async () => {
    if (src && !assetId) return setSrc('');
    if (!assetId) return;
    const { err, data } = await request<{ data: number[] }>({
      state,
      dispatch,
      isDoServer: true,
      skipLoader: true,
      path: `media/{token}/${assetId}`,
      errorText: 'En eller flera bilder kunde inte laddas',
    });
    if (err || !data) return;
    const url = getFileFromBuffer({
      // @ts-ignore
      buffer: data.data?.data || data.data,
      type,
      returnUrl: true,
    });
    setSrc(url);
  };

  const handleClick = () => {
    if (!overlayOnClick) return;
    setOverlayOpen(true);
  };

  useEffect(() => {
    if (state.token) getImage();
    // eslint-disable-next-line
  }, [assetId, state.token]);

  if (!src) return null;
  return (
    <>
      <StyledImageViewer
        src={src}
        className={className}
        $pointer={overlayOnClick}
        onClick={handleClick}
      />
      {overlayOpen && (
        <StyledImageOverlay onClick={() => setOverlayOpen(false)}>
          <img src={src} alt="" />
        </StyledImageOverlay>
      )}
    </>
  );
};

export default ImageViewer;
