import { useContext } from 'react';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import { UserFM } from '@bm-js/h2o-shared';
import AdminCard from './AdminCard';
import Button from './Button';

type Props = {
  user: UserFM;
  editUser: (user: UserFM) => void;
  deleteUser: (user: UserFM) => void;
  requestPasswordReset: (user: UserFM) => void;
};

const UserCard = ({
  user,
  editUser,
  deleteUser,
  requestPasswordReset,
}: Props) => {
  const state = useContext(GlobalStateContext);
  const stateUser = state.user as UserFM;

  const { firstName, lastName, role, _id } = user;
  const name = `${firstName} ${lastName}`;
  const isMe = _id === stateUser._id;

  return (
    <div>
      <AdminCard
        key={user._id}
        cardContent={
          <div>
            <p>{name}</p>
            <p className="light">{`${role} ${isMe ? '(Du)' : ''}`}</p>
          </div>
        }
        menuContent={
          <>
            <Button small onClick={() => editUser(user)}>
              Ändra
            </Button>
            {!isMe && (
              <>
                <Button small onClick={() => requestPasswordReset(user)}>
                  Återställ lösenord
                </Button>
                <Button small look="danger" onClick={() => deleteUser(user)}>
                  Radera
                </Button>
              </>
            )}
          </>
        }
      />
    </div>
  );
};

export default UserCard;
