import {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';
import Button from './Button';
import { UserFormInput } from '../types/user.types';
import { adminEditUserDefault } from '../cnst/default.cnst';
type Props = {
  data?: UserFormInput;
  handleSubmit: (user?: UserFormInput) => void;
};

const UserForm = ({ handleSubmit, data }: Props) => {
  const [formData, setFormData] = useState<UserFormInput>(adminEditUserDefault);

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (
    e
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <form onSubmit={onSubmit}>
      <input
        type="text"
        name="firstName"
        value={formData.firstName}
        onChange={onChange}
        required
        placeholder="Förnamn"
      />
      <input
        type="text"
        name="lastName"
        value={formData.lastName}
        onChange={onChange}
        required
        placeholder="Efternamn"
      />
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={onChange}
        required
        placeholder="E-post"
      />
      <input
        type="phone"
        name="phone"
        value={formData.phone}
        onChange={onChange}
        placeholder="Telefonnummer"
      />

      {!formData._id && (
        <>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={onChange}
            required
            placeholder="Lösenord"
          />
          <input
            type="password"
            name="passwordRepeat"
            value={formData.passwordRepeat}
            onChange={onChange}
            required
            placeholder="Upprepa lösenord"
          />
        </>
      )}
      <select name="role" value={formData.role} onChange={onChange} required>
        <option disabled value="">
          Roll
        </option>
        <option value="Tekniker">Tekniker</option>
        <option value="Admin">Admin</option>
      </select>
      <Button type="submit" med>
        Spara
      </Button>
    </form>
  );
};

export default UserForm;
