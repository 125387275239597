import { BaseEventHandler } from '@bm-js/h2o-shared';
import { X } from 'lucide-react';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../cnst/colors.cnst';

const StyledStringArrayInput = styled.div`
  margin-bottom: 1.5rem;
  label {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .value-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    p {
      margin: 0 !important;
      font-size: 1rem !important;
    }
    svg {
      cursor: pointer;
    }
  }
`;

type Props = {
  value?: string[];
  name: string;
  onChange: BaseEventHandler<string[]>;
  label: string;
};

const StringArrayInput = ({ value, name, onChange, label }: Props) => {
  const [pendingValue, setPendingValue] = React.useState('');

  const handleInputChange: BaseEventHandler<string> = (e) => {
    setPendingValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    e.preventDefault();

    const newValue = [...(value || []), pendingValue];
    const event = { target: { value: newValue, name } };
    onChange(event);
    setPendingValue('');
  };

  const removeItem = (index: number) => {
    const newValue = (value || []).filter((_, i) => i !== index);
    const event = { target: { value: newValue, name } };
    onChange(event);
  };

  return (
    <StyledStringArrayInput>
      <label>{label}</label>
      <input
        placeholder="Skriv in ett värde och tryck Enter"
        value={pendingValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      {value?.map((str, index) => (
        <div key={`${name}_${index}_${str}`} className="value-item">
          <p>- {str}</p>
          <X width={20} onClick={() => removeItem(index)} stroke={colors.red} />
        </div>
      ))}
    </StyledStringArrayInput>
  );
};

export default StringArrayInput;
