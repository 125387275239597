import { Route, Routes } from 'react-router';
import { ROUTE } from '../cnst/router.cnst';
import HomeRoute from './Home.route';
import LoginRoute from './Login.route';
import AdminRoute from './Admin.route';
import CustomersRoute from './Customers.route';
import CustomerPortalRoute from './CustomerPortal.route';
import ReservoirRoute from './Reservoir.route';
import AddToCustomerPortalRoute from './AddToCustomerPortal.route';
import ReservoirsRoute from './Reservoirs.route';
import InvoicingRoute from './Invoicing.route';
import NotificationsRoute from './Notifications.route';
import MediaRoute from './Media.route';
import DocumentsRoute from './Documents.route';
import MyAccountRoute from './MyAccount.route';
import OffersRoute from './Offers.route';
import OrderConfirmationsRoute from './OrderConfirmations.route';
import ServicelogsRoute from './ServiceLogs.route';
import WorkordersRoute from './Workorders.route';
import WorkplansRoute from './Workplans.route';
import EditCustomerRoute from './EditCustomer.route';
import CreateProtocolInspRoute from './CreateProtocolInsp.route';
import CreateProtocolKontRoute from './CreateProtocolKont.route';
import CreateProtocolMRRoute from './CreateProtocolMR.route';
import CreateProtocolRRRoute from './CreateProtocolRR.route';
import CustomerRoute from './Customer.route';
import NotFoundRoute from './NotFound.route';
import UserResetPasswordRoute from './UserResetPassword.route';

const Router = () => {
  return (
    <Routes>
      <Route path={ROUTE.LOGIN} Component={LoginRoute} />
      <Route path={ROUTE.USER_RESET_PASSWORD} Component={UserResetPasswordRoute} />
      <Route path={ROUTE.HOME} Component={HomeRoute} />

      <Route path={ROUTE.INVOICING} Component={InvoicingRoute} />
      <Route path={ROUTE.MEDIA} Component={MediaRoute} />
      <Route path={ROUTE.NOTIFICATIONS} Component={NotificationsRoute} />

      <Route path={ROUTE.DOCUMENTS} Component={DocumentsRoute} />
      <Route path={ROUTE.MY_ACCOUNT} Component={MyAccountRoute} />

      <Route path={ROUTE.OFFERS} Component={OffersRoute} />
      <Route path={ROUTE.ORDER_CONFIRMATIONS} Component={OrderConfirmationsRoute} />
      <Route path={ROUTE.SERVICELOGS} Component={ServicelogsRoute} />
      <Route path={ROUTE.WORKORDERS} Component={WorkordersRoute} />
      <Route path={ROUTE.WORKPLANS} Component={WorkplansRoute} />

      <Route path={ROUTE.ADMIN} Component={AdminRoute} />
      <Route path={ROUTE.EDIT_CUSTOMER} Component={EditCustomerRoute} />

      <Route path={ROUTE.CREATE_PROTOCOL_INSP} Component={CreateProtocolInspRoute} />
      <Route path={ROUTE.CREATE_PROTOCOL_KONT} Component={CreateProtocolKontRoute} />
      <Route path={ROUTE.CREATE_PROTOCOL_MR} Component={CreateProtocolMRRoute} />
      <Route path={ROUTE.CREATE_PROTOCOL_RR} Component={CreateProtocolRRRoute} />

      <Route path={ROUTE.CUSTOMERS} Component={CustomersRoute} />
      <Route path={ROUTE.CUSTOMER} Component={CustomerRoute} />
      <Route path={ROUTE.CUSTOMER_PORTAL} Component={CustomerPortalRoute} />
      <Route path={ROUTE.ADD_TO_CUSTOMER_PORTAL} Component={AddToCustomerPortalRoute} />

      <Route path={ROUTE.RESERVOIRS} Component={ReservoirsRoute} />
      <Route path={ROUTE.RESERVOIR} Component={ReservoirRoute} />

      <Route path="*" Component={NotFoundRoute} />
    </Routes>
  );
};

export default Router;
