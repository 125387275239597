import { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from '../components/Button';
import Card from '../components/Card';
import InformationGrid from '../components/InformationGrid';
import NotificationCard from '../components/NotificationCard';
import OverlayBox from '../components/OverlayBox';
import ReservoirsCompressed from '../components/ReservoirsCompressed';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { normalizeDate } from '../utils/parseDate.util';
import { colors } from '../cnst/colors.cnst';
import { parseCustomerName } from '../utils/parseCustomerName.util';
import { request } from '../utils/request';
import { customerDocumentButtons } from '../cnst/data.cnst';
import CustomerTabCard from '../components/CustomerTabCard';
import NotificationForm from '../components/NotificationForm';
import DeleteConfirmation from '../components/DeleteConfirmation';
import CommunicationLogForm from '../components/CommunicationLogForm';
import CommunicationLogCard from '../components/CommunicationLogCard';
import CustomerContactForm from '../components/CustomerContactForm';
import NotificationPopup from '../components/NotificationPopup';
import {
  CommunicationLogInput,
  CustomerFM,
  Notification,
  PortalCustomerFM,
  ReservoirBM,
  UserBM,
  UserFM,
} from '@bm-js/h2o-shared';
import { ActionType } from '../types/dispatch.types';
import CustomerComments from '../components/CustomerComments';
import { Link, Location, useNavigate, useLocation } from 'react-router';
import { useQuery } from '../hooks/useQuery';

const StyledSingleCustomerPage = styled.div`
  p.card-middle-header {
    color: ${colors.darkGrey};
    margin-bottom: 1rem;
    margin-top: 1.3rem;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .new-communication {
    margin-top: 1rem;
    form {
      display: flex;
      flex-direction: column;
      button {
        align-self: flex-end;
      }
    }
  }
  .documents-buttons-container {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    a {
      margin-left: 0.5rem;
      button {
        margin: 0.3rem 0;
      }
    }
  }
  @media (max-width: 1000px) {
    .documents-buttons-container {
      margin-top: 0.5rem;
      justify-content: flex-start;
      a {
        width: calc(50% - 1rem);
        button {
          width: 100%;
          padding: 0.5rem;
        }
      }
    }
  }
`;

const deleteNotificationDefault: {
  active: boolean;
  notification: Notification | {};
} = {
  active: false,
  notification: {},
};
const editNotificationDefault: Partial<Notification> & { active: boolean } = {
  active: false,
  read: false,
  dateForHandling: '',
  recipientId: '',
  textContent: '',
  textContentLong: [],
  _id: '',
};

const portalStatusDefault = {
  checkCompleted: false,
  isInPortal: false,
};

const SingleCustomerPage = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const location: Location<{ linkKey?: string; notification?: Notification }> =
    useLocation();
  const query = useQuery<{ id: string }>();

  const navigate = useNavigate();
  const [comLogsOpen, setComLogsOpen] = useState(false);
  const [customer, setCustomer] = useState<CustomerFM | {}>({});
  const [deleteNotificationPending, setDeleteNotificationPending] = useState(
    deleteNotificationDefault
  );
  const [linkedReservoirs, setLinkedReservoirs] = useState<
    (ReservoirBM & { customerId: string; customerName: string })[] | null
  >(null);
  const [linkedReservoirsLoading, setLinkedReservoirsLoading] = useState(false);
  const [editNotificationPopup, setEditNotificationPopup] = useState<
    Partial<Notification> & { active?: boolean }
  >(editNotificationDefault);
  const [popupNotification, setPopupNotification] = useState<Notification>();
  const [customerNotifications, setCustomerNotifications] = useState<
    Notification[]
  >([]);

  const [portalStatus, setPortalStatus] = useState(portalStatusDefault);
  const [contactInformationEdit, setContactInformationEdit] = useState<{
    lastContact?: string | null;
    serviceDealStart?: string | null;
    serviceDealEnd?: string | null;
  } | null>();
  const [editCommunicationLog, setEditCommunicationLog] = useState<
    CustomerFM['communications'][0] | null
  >();
  const [allUsers, setAllUsers] = useState<UserBM[]>([]);

  const { customerInformation } = customer as CustomerFM;

  const showNotification = (notification: Notification) => {
    setPopupNotification(notification);
  };

  const addCommunicationsLog = async (
    payload: CommunicationLogInput,
    cb: () => void
  ) => {
    const body = {
      ...payload,
      person: `${(state.user as UserFM).firstName} ${
        (state.user as UserFM).lastName
      }`,
    };
    const { err } = await request({
      state,
      dispatch,
      successText: 'Sparad',
      method: 'POST',
      path: `customers/add-coms-log/${(customer as CustomerFM)._id}`,
      body,
    });

    if (err) return;
    cb();
    getCustomer();
  };

  const editComsLogInit = (communication: CustomerFM['communications'][0]) => {
    setEditCommunicationLog(communication);
  };

  const editComsLog = async (
    payload: CustomerFM['communications'][0],
    cb: () => void
  ) => {
    const body = {
      ...payload,
      person: `${(state.user as UserFM).firstName} ${
        (state.user as UserFM).lastName
      }`,
    };

    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      body,
      path: `customers/edit-coms-log/${(customer as CustomerFM)._id}/${
        payload._id
      }`,
      successText: 'Kontaktlogg uppdaterad',
    });
    if (err) return;
    cb();
    getCustomer();
    setEditCommunicationLog(null);
  };

  const deleteCommunication = async (id: string) => {
    const { err } = await request({
      state,
      dispatch,
      method: 'DELETE',
      path: `customers/delete-coms-log/${(customer as CustomerFM)._id}/${id}`,
      successText: 'Raderad',
    });
    if (err) return;
    getCustomer();
  };

  const changeContactInformationInit = () => {
    setContactInformationEdit({
      lastContact: normalizeDate((customer as CustomerFM).lastContact),
      serviceDealStart: normalizeDate(
        (customer as CustomerFM).serviceDeal?.startDate,
        ''
      ),
      serviceDealEnd: normalizeDate(
        (customer as CustomerFM).serviceDeal?.endDate,
        ''
      ),
    });
  };

  const changeContactInformation = async (body: {
    contactInformation: {
      lastContact: string;
      serviceDeal: { end: string; start: string };
    };
  }) => {
    const { err } = await request({
      state,
      dispatch,
      successText: 'Kontaktinformation uppdaterad',
      method: 'POST',
      body,
      path: `customers/update-contact-information/${
        (customer as CustomerFM)._id
      }`,
    });

    if (err) return;
    setContactInformationEdit(null);
    getCustomer();
  };

  const parseNotifications = (users: UserBM[], customerId: string) => {
    const allCustomerNotifications: Notification[] = [];
    users.forEach((user) => {
      user.notifications.forEach((notification) => {
        if (
          notification.subject === 'customerContactNotification' &&
          notification.customerId === customerId &&
          !notification.archived
        ) {
          allCustomerNotifications.push(notification);
        }
      });
    });
    return allCustomerNotifications.sort((a, b) => {
      const dateA = new Date(a.dateForHandling);
      const dateB = new Date(b.dateForHandling);
      return dateA.getTime() - dateB.getTime();
    });
  };

  const getAllUsers = async (customerId: string) => {
    const { data, err } = await request<UserBM[]>({
      state,
      dispatch,
      path: 'users/auth/all-users/minimal/',
    });
    if (err || !data) return;
    setAllUsers(data);
    const notifications = parseNotifications(data, customerId);
    setCustomerNotifications(notifications);
    if (location?.state?.linkKey) {
      setPopupNotification(location.state.notification);
    }
  };

  const checkPortalStatus = async (id: string) => {
    const { err, data } = await request<{ portalCustomer: PortalCustomerFM }>({
      state,
      dispatch,
      path: `portalCustomers/${id}`,
    });
    if (err || !data) return;
    setPortalStatus({
      checkCompleted: true,
      isInPortal: !!data.portalCustomer,
    });
  };

  const sortComms = (comms: CustomerFM['communications'][0][]) =>
    comms.sort((a, b) => {
      const timeA = new Date(a.date).getTime();
      const timeB = new Date(b.date).getTime();
      return timeA - timeB;
    });

  const getCustomer = async () => {
    const { id } = query;
    if (!id) return;

    const { data, err } = await request<CustomerFM>({
      state,
      dispatch,
      path: `customers/single/${id}`,
    });
    if (err || !data) return;

    if (data.linkedReservoirIds) {
      getLinkedReservoirs(data.linkedReservoirIds);
    }
    checkPortalStatus(id);
    getAllUsers(id);
    const communications = sortComms(data.communications);
    setCustomer({ ...data, communications });
  };

  const getLinkedReservoirs = async (ids: string[]) => {
    if (!ids?.length) return;
    setLinkedReservoirsLoading(true);
    const { err, data } = await request<
      (ReservoirBM & { customerName: string; customerId: string })[]
    >({
      state,
      dispatch,
      method: 'POST',
      body: { ids },
      path: 'customers/linked-reservoirs',
    });
    setLinkedReservoirsLoading(false);
    if (err || !data) return;
    setLinkedReservoirs(data);
  };

  const createNotification = async (
    body: { notificationData: Notification },
    cb: () => void
  ) => {
    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      body,
      path: `customers/create-customer-notification/${body.notificationData.customerId}`,
      successText: 'Notis sparad',
    });
    if (err) return;
    cb();
    getCustomer();
  };

  const editNotificationInit = (notif: Notification) => {
    setEditNotificationPopup({
      ...notif,
      active: true,
      dateForHandling: normalizeDate(notif.dateForHandling) as unknown as Date,
    });
  };

  const editNotification = async (body: { notificationData: Notification }) => {
    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      body,
      path: `customers/edit-customer-notification/${body.notificationData.customerId}/${body.notificationData._id}`,
      successText: 'Notis uppdaterad',
    });
    if (err) return;
    // @ts-ignore
    setEditNotificationPopup({ active: false });
    getCustomer();
  };

  const preDeleteNotification = (notification: Notification) => {
    setDeleteNotificationPending({
      active: true,
      notification: notification,
    });
  };

  const deleteNotification = async (notification: Notification) => {
    const { err } = await request({
      state,
      dispatch,
      method: 'DELETE',
      path: `notifications/delete/${notification.recipientId}/${notification._id}`,
      successText: 'Notis raderad',
    });
    if (err) return;
    setDeleteNotificationPending(deleteNotificationDefault);
    getCustomer();
  };

  const archiveNotification = async (notification: Notification) => {
    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      path: `notifications/archive/${notification.recipientId}/${notification._id}`,
      successText: 'Notis arkiverad',
    });
    if (err) return;
    getCustomer();
  };

  useEffect(() => {
    dispatch({ type: ActionType.LOADING, value: true });
    if (state.token) {
      getCustomer();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  const parsedCustomerName = useMemo(
    () => parseCustomerName(customer),
    [customer]
  );

  const documentButtons = useMemo(() => {
    if (!customerInformation) return [];
    return customerDocumentButtons.map((doc) => {
      let to = `/`;
      if (doc.useBasePage) to += 'documents';
      else to += doc.key;
      to += `?id=${(customer as CustomerFM)._id}&name=${parsedCustomerName}`;
      if (doc.useBasePage) to += `&type=${doc.key}&typeName=${doc.typeName}`;

      return {
        text: doc.typeName,
        to,
      };
    });
  }, [customerInformation, customer, parsedCustomerName]);

  const renderedCommunications = useMemo(() => {
    if (!(customer as CustomerFM).communications) return [];
    return [...(customer as CustomerFM).communications].reverse().slice(0, 2);
  }, [(customer as CustomerFM).communications]);

  if (!customerInformation) return null;

  return (
    <StyledSingleCustomerPage>
      <h1 className="page-header has-extra-button">
        {customerInformation.name} — kundkort
        <br />
        {portalStatus.checkCompleted ? (
          <Button
            noBg
            onClick={() => {
              navigate(
                `/customers/customer-portal/?id=${(customer as CustomerFM)._id}`
              );
            }}
          >
            Hantera kundfiler
          </Button>
        ) : null}
      </h1>

      <Breadcrumbs
        crumbs={[
          { to: '/home', p: 'Hem' },
          { to: '/customers', p: 'Kunder' },
          { p: customerInformation.name },
        ]}
        rightContent={
          <div className="documents-buttons-container">
            {documentButtons.map((button) => (
              <Link to={button.to} key={button.to}>
                <Button white small>
                  {button.text}
                </Button>
              </Link>
            ))}
            <Link to={`/media/?customer=${(customer as CustomerFM)._id}`}>
              <Button small>Filer</Button>
            </Link>
            {(state.user as UserFM).role === 'Admin' ? (
              <Link
                to="/admin/edit-customer"
                state={{
                  formData: customer,
                }}
              >
                <Button small>Redigera</Button>
              </Link>
            ) : null}
          </div>
        }
      />
      <div className="single-customer-inner">
        <div className="single-customer-left-content">
          <CustomerTabCard
            customer={customer as CustomerFM}
            refetchCustomer={getCustomer}
          />
          <CustomerComments
            customer={customer as CustomerFM}
            getCustomer={getCustomer}
          />
          <Card>
            <div className="customer-card-header">
              <h3>Reservoarer</h3>
              <div className="customer-card-header-right-content">
                <Link
                  to={`/reservoirs/?customerId=${(customer as CustomerFM)._id}`}
                >
                  <Button noBg>Visa alla</Button>
                </Link>
              </div>
            </div>
            <ReservoirsCompressed
              linkedReservoirs={linkedReservoirs!}
              linkedReservoirsLoading={linkedReservoirsLoading}
              customer={customer as CustomerFM}
              customerName={customerInformation.name}
            />
          </Card>
        </div>
        <div className="single-customer-right-content">
          <Card>
            <div className="customer-card-header">
              <h3>Kontakt</h3>
              <div className="customer-card-header-right-content">
                <Button
                  noBg
                  onClick={changeContactInformationInit}
                >{`Redigera`}</Button>
              </div>
            </div>
            <InformationGrid
              pairs={[
                {
                  h: 'Sista kontakt',
                  p: normalizeDate((customer as CustomerFM).lastContact, '—'),
                  fw: true,
                },
                {
                  h: 'Serviceavtal',
                  p: `${normalizeDate(
                    (customer as CustomerFM).serviceDeal?.startDate,
                    '-'
                  )} - ${normalizeDate(
                    (customer as CustomerFM).serviceDeal?.endDate
                  )}`,
                  fw: true,
                },
              ]}
            />
          </Card>
          <Card>
            <div className="customer-card-header">
              <h3>Notiser</h3>
            </div>
            {customerNotifications.map((notification) => (
              <NotificationCard
                showNotification={showNotification}
                noBtn
                hasEdit
                my={(state.user as UserFM).role === 'Admin'}
                archiveNotification={archiveNotification}
                preDeleteNotification={preDeleteNotification}
                editNotification={() => editNotificationInit(notification)}
                key={notification._id}
                notification={notification}
              />
            ))}
            <hr />
            <p className="card-middle-header">Ny notis</p>
            <NotificationForm
              customer={customer as CustomerFM}
              handleSubmit={createNotification}
              users={allUsers}
            />
          </Card>
          <Card>
            <div className="customer-card-header">
              <h3>Samtalslogg</h3>
              <div className="customer-card-header-right-content">
                <Button
                  noBg
                  onClick={() => setComLogsOpen(true)}
                >{`Visa alla (${
                  (customer as CustomerFM).communications.length
                })`}</Button>
              </div>
            </div>
            {renderedCommunications.map((communication) => (
              <CommunicationLogCard
                key={communication._id}
                communication={communication}
              />
            ))}
            <hr />
            <p className="card-middle-header">Skriv en ny logg</p>
            <CommunicationLogForm
              contactPersons={(customer as CustomerFM).contactPersons}
              handleSubmit={addCommunicationsLog}
            />
          </Card>
        </div>
      </div>
      {comLogsOpen && (
        <OverlayBox
          header="Samtalslogg"
          active={comLogsOpen}
          close={() => {
            setComLogsOpen(false);
          }}
        >
          {[...(customer as CustomerFM).communications]
            .reverse()
            .map((communication) => (
              <CommunicationLogCard
                key={communication._id}
                communication={communication}
                displayActions
                editLog={editComsLogInit}
                deleteLog={deleteCommunication}
              />
            ))}
          <hr />
          <p>
            {editCommunicationLog ? 'Redigera samtalslogg' : 'Skriv en ny logg'}
          </p>
          <CommunicationLogForm
            // @ts-ignore
            handleSubmit={
              editCommunicationLog ? editComsLog : addCommunicationsLog
            }
            contactPersons={(customer as CustomerFM).contactPersons}
            // @ts-ignore
            data={editCommunicationLog}
          />
        </OverlayBox>
      )}
      {contactInformationEdit && (
        <OverlayBox
          header="Kontakt"
          active
          close={() => setContactInformationEdit(null)}
        >
          <CustomerContactForm
            // @ts-ignore
            handleSubmit={changeContactInformation}
            // @ts-ignore
            data={contactInformationEdit}
          />
        </OverlayBox>
      )}
      {popupNotification && (
        <NotificationPopup
          notification={popupNotification}
          // @ts-ignore
          close={() => setPopupNotification(null)}
        />
      )}
      {editNotificationPopup.active && (
        <OverlayBox
          header="Redigera notis"
          active
          close={() => setEditNotificationPopup(editNotificationDefault)}
        >
          <NotificationForm
            handleSubmit={editNotification}
            data={editNotificationPopup}
            users={allUsers}
            customer={customer as CustomerFM}
          />
        </OverlayBox>
      )}
      {deleteNotificationPending.active && (
        <DeleteConfirmation
          item={deleteNotificationPending.notification as Notification}
          close={() => setDeleteNotificationPending(deleteNotificationDefault)}
          commit={deleteNotification}
          itemIdentifier={'notisen'}
          itemName={'notis'}
        />
      )}
    </StyledSingleCustomerPage>
  );
};

export default SingleCustomerPage;
