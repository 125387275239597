import { useContext } from 'react';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import Button from './Button';
import OverlayBox from './OverlayBox';
import { ActionType } from '../types/dispatch.types';
import { useNavigate } from 'react-router';

const LinkPreventPopup = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const navigate = useNavigate();

  return (
    <OverlayBox
      active={state.preventNavigationPopup.active}
      close={() =>
        dispatch({
          type: ActionType.SET_PREVENT_NAVIGATE_POPUP,
          value: { active: false, destination: '/' },
        })
      }
      header="Vill du lämna sidan?"
    >
      <p>Vill du verkligen lämna sidan utan att spara dina ändringar?</p>
      <div className="navigation-alert-buttons">
        <Button
          med
          look="danger"
          onClick={() => {
            navigate(state.preventNavigationPopup.destination);
            dispatch({
              type: ActionType.SET_PREVENT_NAVIGATE_POPUP,
              value: { active: false, destination: '/' },
            });
          }}
        >
          Lämna
        </Button>
        <Button
          med
          onClick={() =>
            dispatch({
              type: ActionType.SET_PREVENT_NAVIGATE_POPUP,
              value: { active: false, destination: '/' },
            })
          }
        >
          Avbryt
        </Button>
      </div>
    </OverlayBox>
  );
};

export default LinkPreventPopup;
