import styled from 'styled-components';
import { ROUTE } from '../cnst/router.cnst';
import { FormEventHandler, useEffect, useState } from 'react';
import { useGlobal } from '../hooks/useGlobalState';
import { useNavigate } from 'react-router';
import Button from '../components/Button';
import { colors } from '../cnst/colors.cnst';
import { useQuery } from '../hooks/useQuery';
import { ActionType } from '../types/dispatch.types';
import { envService } from '../services/services';
import PasswordRequirements from '../components/PasswordRequirements';
import axios from 'axios';
import { UserFM } from '@bm-js/h2o-shared';

const StyledUserResetPasswordRoute = styled.div`
  display: flex;
  .login-left-section {
    width: 40vw;
    min-width: 500px;
    background: ${colors.white};
    min-height: 100vh;
    margin-bottom: 10rem;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 0px 14px rgba(0, 0, 0, 0.1);

    form {
      h3 {
        margin-bottom: 3rem;
        margin-top: 1rem;
      }
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-grow: 1;
      input,
      button {
        max-width: calc(500px - 6rem);
        width: 100%;
      }
      button {
        margin-bottom: 1rem;
      }
    }
  }

  img {
    width: 80px;
  }
  .password-requirements {
    max-width: calc(500px - 6rem);
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    border: 1px solid ${colors.lightBlue};
    border-radius: 0.5rem;
  }
  .login-right-section {
    background-size: cover;
    background-image: url('/images/water.jpg');
    width: 100%;
    opacity: 0.2;
  }
  @media (max-width: 1000px) {
    display: block;
    .login-left-section {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
      form {
        padding: 1rem;
      }
    }
    .login-right-section {
      display: none;
    }
  }
`;

const UserResetPasswordRoute = () => {
  const { state, dispatch } = useGlobal();
  const navigate = useNavigate();
  const { t } = useQuery<{ t: string }>();

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);

  const [tokenStatus, setTokenStatus] = useState<
    'pending' | 'valid' | 'invalid'
  >('pending');

  const handleSubmit: FormEventHandler = async (e) => {
    try {
      e.preventDefault();
      if (!passwordValid || tokenStatus !== 'valid') {
        throw new Error('Not ready');
      }

      dispatch({ type: ActionType.LOADING, value: true });
      const resetRes = await fetch(
        `${envService.API_URL}users/no-auth/reset-password`,
        {
          method: 'POST',
          body: JSON.stringify({ newPassword: password }),
          headers: {
            Authorization: `Bearer ${t}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (!resetRes.ok) throw new Error('Not OK');
      const { email } = (await resetRes.json()) as { email: string };
      dispatch({ type: ActionType.ALERT, content: 'Lösenord uppdaterat' });
      const loginBody = {
        user: {
          email: email,
          password: password,
        },
      };
      const loginRes = await axios.post<{
        user: UserFM;
        token: string;
        numberOfUnreadNotifications: number;
      }>(`${envService.API_URL}users/no-auth/login`, loginBody);

      const { user, token, numberOfUnreadNotifications } = loginRes.data;
      dispatch({
        type: ActionType.TOKEN_VERIFIED,
        token: token,
        user: user,
        numberOfUnreadNotifications,
      });
      localStorage.setItem('token', token);
      await navigate(ROUTE.NOTIFICATIONS);
    } catch (e) {
      dispatch({ type: ActionType.LOADING, value: false });
      dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
    }
  };

  useEffect(() => {
    if (state.tokenVerified) {
      void navigate(ROUTE.NOTIFICATIONS);
      return;
    }

    const checkToken = async (token: string) => {
      try {
        const res = await fetch(
          `${envService.API_URL}users/no-auth/verify-reset-password-token`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (res.ok) setTokenStatus('valid');
        else setTokenStatus('invalid');
      } catch (e) {
        setTokenStatus('invalid');
      }
    };

    if (!t) setTokenStatus('invalid');
    else void checkToken(t);
  }, [state.tokenVerified, t]);

  if (tokenStatus === 'pending') return <div>Verifierar länk...</div>;
  if (tokenStatus === 'invalid') return <div>Ogiltig länk</div>;

  return (
    <StyledUserResetPasswordRoute>
      <div className="login-left-section">
        <form onSubmit={handleSubmit}>
          <img src="/images/logo.png" alt="logo" />
          <h1>Återställ lösenord</h1>
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Nytt lösenord"
            autoComplete="new-password"
          />
          <input
            type="password"
            name="passwordRepeat"
            value={passwordRepeat}
            onChange={(e) => setPasswordRepeat(e.target.value)}
            placeholder="Upprepa nytt lösenord"
            autoComplete="new-password"
          />
          <Button type="submit" disabled={!passwordValid}>
            Uppdatera lösenord
          </Button>
          <div className="password-requirements">
            <PasswordRequirements
              newPassword={password}
              confirmPassword={passwordRepeat}
              setPasswordValid={setPasswordValid}
            />
          </div>
        </form>
      </div>
      <div className="login-right-section"></div>
    </StyledUserResetPasswordRoute>
  );
};

export default UserResetPasswordRoute;
