import { months } from '../cnst/data.cnst';

export const parseDate = (date?: Date | string | null) => {
  if (!date) return null;
  const parsedDate = new Date(date).toLocaleDateString('sv');
  return parsedDate;
};

export const parseDateTime = (date?: Date | string | null) => {
  if (!date) return null;
  const preformatted = new Date(date).toString();
  const formatted = new Date(preformatted).toLocaleString('sv', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  return formatted;
};

export const normalizeDate = (date?: Date | string | null, fallback = '') => {
  return date ? parseDate(date) || fallback : fallback;
};

export const parseDateMonthYear = (
  date?: Date | string | null,
  fallback = ''
) => {
  if (!date) return fallback;
  let dateString: string;
  if (date instanceof Date) {
    dateString = date.toISOString();
  } else {
    dateString = date;
  }
  const [year, month] = dateString.split('-');
  const monthIndex = parseInt(month, 10);
  const monthName = monthIndex ? months[monthIndex - 1] : '';
  if (!monthName) {
    return fallback;
  }

  return `${monthName} ${year}`;
};
