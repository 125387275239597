import axios from 'axios';
import React, { Dispatch, PropsWithChildren, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import FormOverlay from '../components/FormOverlay';
import GlobalStyles from '../components/GlobalStyles';
import { initialFormOverlay, initialGlobalState } from '../cnst/state.cnst';
import { GlobalState } from '../types/state.types';
import { Action, ActionType } from '../types/dispatch.types';
import { UserFM } from '@bm-js/h2o-shared';
import { envService } from '../services/services';
import { ROUTE } from '../cnst/router.cnst';

export const GlobalStateContext = React.createContext(initialGlobalState);
export const GlobalDispatchContext = React.createContext<Dispatch<Action>>(
  () => {}
);

const reducer = (state: GlobalState, action: Action): GlobalState => {
  switch (action.type) {
    case ActionType.TOKEN_VERIFIED:
      return {
        ...state,
        token: action.token,
        user: action.user,
        loading: false,
        tokenVerified: true,
        numberOfUnreadNotifications: action.numberOfUnreadNotifications,
      };
    case ActionType.TOKEN_DISCARDED:
      localStorage.removeItem('token');
      return {
        ...state,
        loading: false,
        tokenVerified: false,
        user: {},
        token: '',
      };
    case ActionType.LOADING:
      return {
        ...state,
        loading: action.value,
        loadingProgress: !action.value ? null : state.loadingProgress,
      };
    case ActionType.LOADING_PROGRESS: {
      return {
        ...state,
        loading: action.value,
        loadingProgress: action.progress,
      };
    }
    case ActionType.ALERT:
      return {
        ...state,
        alert: {
          active: true,
          content: action.content,
        },
      };
    case ActionType.CLOSE_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          active: false,
        },
      };
    case ActionType.SET_PREVENT_NAVIGATE:
      return {
        ...state,
        preventNavigation: action.value,
      };
    case ActionType.SET_PREVENT_NAVIGATE_POPUP:
      return {
        ...state,
        preventNavigationPopup: action.value,
      };
    case ActionType.LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: '',
        tokenVerified: false,
        loading: false,
        user: {},
      };
    case ActionType.SET_FORM_OVERLAY:
      return {
        ...state,
        formOverlay: action.data,
      };
    case ActionType.HIDE_FORM_OVERLAY:
      return {
        ...state,
        formOverlay: {
          ...state.formOverlay,
          hidden: true,
        },
      };
    case ActionType.SHOW_FORM_OVERLAY:
      return {
        ...state,
        formOverlay: {
          ...state.formOverlay,
          hidden: false,
        },
      };
    case ActionType.CLOSE_FORM_OVERLAY:
      return {
        ...state,
        formOverlay: initialFormOverlay,
      };
    case ActionType.SET_NUMBER_OF_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        numberOfUnreadNotifications: action.value,
      };
    default:
      return state;
  }
};

const GlobalContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = React.useReducer(reducer, initialGlobalState);
  const navigate = useNavigate();
  const location = useLocation();

  const checkToken = async () => {
    const localToken = localStorage.getItem('token');
    if (!localToken) {
      if (location.pathname !== ROUTE.USER_RESET_PASSWORD) {
        await navigate(ROUTE.LOGIN);
      }
      dispatch({ type: ActionType.LOADING, value: false });
      return;
    }

    try {
      const res = await axios.post<{
        user: UserFM;
        numberOfUnreadNotifications: number;
      }>(
        envService.API_URL + 'users/auth/verify-token',
        {},
        {
          headers: { Authorization: 'Bearer ' + localToken },
        }
      );

      dispatch({
        type: ActionType.TOKEN_VERIFIED,
        token: localToken,
        user: res.data.user,
        numberOfUnreadNotifications: res.data.numberOfUnreadNotifications,
      });
      if (location.pathname === '/') {
        await navigate(ROUTE.NOTIFICATIONS);
      }
    } catch {
      dispatch({ type: ActionType.TOKEN_DISCARDED });
      await navigate(ROUTE.LOGIN);
    }
  };

  useEffect(() => {
    if (!state.tokenVerified) checkToken();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tokenVerified]);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        <GlobalStyles>
          {children}
          {state.formOverlay.active && <FormOverlay />}
        </GlobalStyles>
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
