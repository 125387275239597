import { UserFM } from '@bm-js/h2o-shared';
import { useGlobal } from '../hooks/useGlobalState';
import { ActionType } from '../types/dispatch.types';
import { UserFormInput } from '../types/user.types';
import { pick } from '../utils/pick.util';
import { request } from '../utils/request';
import { trimObjectKeys } from '../utils/trimObjectKeys.util';
import TabCard from './TabCard';
import UserCard from './UserCard';
import UserForm from './UserForm';
import OverlayBox from './OverlayBox';
import { adminEditUserDefault } from '../cnst/default.cnst';
import DeleteConfirmation from './DeleteConfirmation';
import { useState } from 'react';

type Props = {
  users: UserFM[];
  revalidate: () => void;
};

const AdminUsers = ({ users, revalidate }: Props) => {
  const { state, dispatch } = useGlobal();
  const [activeUsersTab, setActiveUsersTab] = useState(0);
  const [editUserFormData, setEditUserFormData] =
    useState<UserFormInput>(adminEditUserDefault);
  const [userDeletePending, setUserDeletePending] = useState<{
    active: boolean;
    user?: UserFM;
  }>({ active: false });

  const updateUserInit = (user: UserFM) => {
    setEditUserFormData({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
      phone: user.phone || '',
      active: true,
      _id: user._id,
      password: '',
      passwordRepeat: '',
    });
  };

  const deleteUserInit = (user: UserFM) => {
    setUserDeletePending({
      active: true,
      user: user,
    });
  };

  const deleteUser = async (user: UserFM) => {
    const { err } = await request({
      state,
      dispatch,
      method: 'DELETE',
      successText: 'Användaren raderades',
      path: `admin/delete-user/${user._id}`,
    });
    if (err) return;
    setUserDeletePending({ active: false });
    revalidate();
  };

  const updateUser = async (user?: UserFormInput) => {
    if (!user) return;
    const pickedFormData = pick(user, [
      'firstName',
      'lastName',
      'email',
      'phone',
      'role',
    ]);
    const cleanedFormData = trimObjectKeys(pickedFormData);
    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      body: { user: cleanedFormData },
      path: `admin/update-user/${user._id}`,
      successText: 'Användare uppdaterad',
    });

    if (err) {
      let message = 'Något gick fel';
      if (err === 'Email exists') {
        message = 'Mailadressen finns redan';
      }
      return dispatch({
        type: ActionType.ALERT,
        content: message,
      });
    }

    setEditUserFormData(adminEditUserDefault);
    revalidate();
  };

  const addUser = async (user?: UserFormInput) => {
    if (!user) return;
    if (user.password !== user.passwordRepeat) {
      return dispatch({
        type: ActionType.ALERT,
        content: 'Lösenorden matchar inte',
      });
    }

    const pickedFormData = pick(user, [
      'firstName',
      'lastName',
      'email',
      'phone',
      'role',
      'password',
    ]);
    const cleanedFormData = trimObjectKeys(pickedFormData);

    const { err } = await request({
      state,
      dispatch,
      successText: 'Användare sparad',
      method: 'POST',
      body: { user: cleanedFormData },
      path: 'admin/register-user',
    });

    if (err) {
      if (err === 'Email exists') {
        dispatch({
          type: ActionType.ALERT,
          content: 'Mailadressen finns redan',
        });
      }
      if ((err as any).details?.[0]?.path?.[0] === 'password') {
        dispatch({
          type: ActionType.ALERT,
          content:
            'Lösenordet måste vara minst 10 tecken långt och innehålla både stora och små bokstäver, samt minst en siffra',
        });
      }
      return;
    }
    setActiveUsersTab(0);
    revalidate();
  };

  const requestPasswordReset = async (user: UserFM) => {
    await request({
      state,
      dispatch,
      path: `admin/send-user-password-reset/${user._id}`,
      successText: 'Mailet har skickats',
      method: 'POST',
    });
  };

  return (
    <>
      <TabCard
        activeTab={activeUsersTab}
        setActiveTab={setActiveUsersTab}
        tabs={['Hantera användare', 'Lägg till användare']}
        contents={[
          users.map((user) => (
            <UserCard
              key={user._id}
              user={user}
              editUser={updateUserInit}
              deleteUser={deleteUserInit}
              requestPasswordReset={requestPasswordReset}
            />
          )),
          <UserForm handleSubmit={addUser} />,
        ]}
      />
      {userDeletePending.active && userDeletePending.user && (
        <DeleteConfirmation
          close={() => setUserDeletePending({ active: false })}
          commit={deleteUser}
          item={userDeletePending.user}
          itemName="användare"
          itemIdentifier={`${userDeletePending.user.firstName} ${
            userDeletePending.user.lastName
          }`}
        />
      )}
      {editUserFormData.active && (
        <OverlayBox
          active
          close={() => setEditUserFormData(adminEditUserDefault)}
          header="Ändra användare"
        >
          <UserForm handleSubmit={updateUser} data={editUserFormData} />
        </OverlayBox>
      )}
    </>
  );
};

export default AdminUsers;
