import Card from './Card';
import Button from './Button';
import styled from 'styled-components';
import { MunipRegBM } from '@bm-js/h2o-shared';
import { colors } from '../cnst/colors.cnst';
import { useState } from 'react';
import { munipRegEditDefault } from '../cnst/default.cnst';
import { pick } from '../utils/pick.util';
import { request } from '../utils/request';
import { useGlobal } from '../hooks/useGlobalState';
import MunipRegForm from './MunipRegForm';
import OverlayBox from './OverlayBox';
const StyledAdminMunipRegs = styled.div`
  .munip-reg-row {
    gap: 1rem;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: flex-start;
    position: relative;
    padding: 1rem 0;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: ${colors.mediumGrey};
      content: '';
    }
    input {
      margin: 0;
    }
    .munip-row-buttons-container {
      justify-self: flex-end;
      button {
        margin: 0;
        margin-left: 0.5rem;
      }
    }
  }
`;

type Props = {
  munipReg: MunipRegBM[];
  revalidate: () => void;
};

const AdminMunipRegs = ({ munipReg, revalidate }: Props) => {
  const { state, dispatch } = useGlobal();

  const [regFormOpen, setRegFormOpen] = useState(false);
  const [munipRegActiveChange, setMunipRegActiveChange] = useState<
    number | null
  >(null);

  const [munipRegActiveChangeData, setMunipRegActiveChangeData] =
    useState(munipRegEditDefault);

  const addMunipReg = async (munipReg: Partial<MunipRegBM>) => {
    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      successText: 'Sparad',
      path: 'munipreg',
      body: { munipReg },
    });
    if (err) return;
    setRegFormOpen(false);
    revalidate();
  };

  const updateMunipReg = async () => {
    const body = {
      munipReg: pick(munipRegActiveChangeData, ['customerName', 'munipName']),
    };

    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      successText: 'Uppdaterad',
      path: `munipreg/update/${munipRegActiveChangeData._id}`,
      body,
    });
    if (err) return;
    setMunipRegActiveChangeData(munipRegEditDefault);
    setMunipRegActiveChange(null);
    revalidate();
  };

  const deleteReg = async (id: string) => {
    const { err } = await request({
      state,
      dispatch,
      method: 'DELETE',
      path: `munipreg/${id}`,
      successText: 'Raderad',
    });
    if (err) return;
    setMunipRegActiveChangeData(munipRegEditDefault);
    revalidate();
    setMunipRegActiveChange(null);
  };

  const munipRegInitChange = (reg: MunipRegBM, i: number) => {
    setMunipRegActiveChange(i);
    setMunipRegActiveChangeData(
      pick(reg, ['munipName', 'customerName', '_id'])
    );
  };

  return (
    <StyledAdminMunipRegs>
      <Card>
        <div className="customer-card-header">
          <h3>Kommunregister</h3>
          <div className="customer-card-header-right-content">
            <Button noBg onClick={() => setRegFormOpen(true)}>
              Lägg till
            </Button>
          </div>
        </div>
        <div className="munip-reg-container">
          <div className="munip-reg-row">
            <p>
              <strong>Kommun</strong>
            </p>
            <p>
              <strong>Kundnamn</strong>
            </p>
          </div>
        </div>
        <div />
        {munipReg.map((reg, index) => (
          <div className="munip-reg-row" key={reg._id}>
            {munipRegActiveChange !== index ? (
              <>
                <p>{reg.munipName}</p>
                <p>{reg.customerName}</p>
                <div className="munip-row-buttons-container">
                  <Button
                    small
                    onClick={() => {
                      munipRegInitChange(reg, index);
                    }}
                  >
                    Ändra
                  </Button>
                </div>
              </>
            ) : (
              <>
                <input
                  type="text"
                  placeholder="Kommunnamn"
                  value={munipRegActiveChangeData.munipName}
                  name="munipName"
                  onChange={(e) => {
                    setMunipRegActiveChangeData({
                      ...munipRegActiveChangeData,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
                <input
                  type="text"
                  placeholder="Kundnamn"
                  value={munipRegActiveChangeData.customerName}
                  name="customerName"
                  onChange={(e) => {
                    setMunipRegActiveChangeData({
                      ...munipRegActiveChangeData,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
                <div className="munip-row-buttons-container">
                  <Button
                    small
                    onClick={() => {
                      updateMunipReg();
                    }}
                  >
                    Spara
                  </Button>
                  <Button
                    small
                    look="danger"
                    onClick={() => deleteReg(reg._id)}
                  >
                    Radera
                  </Button>
                </div>
              </>
            )}
          </div>
        ))}
      </Card>
      {regFormOpen && (
        <OverlayBox
          active
          close={() => setRegFormOpen(false)}
          header={'Lägg till i kommunregister'}
        >
          <MunipRegForm handleSubmit={addMunipReg} />
        </OverlayBox>
      )}
    </StyledAdminMunipRegs>
  );
};

export default AdminMunipRegs;
