import DocumentPage, { DocumentPageContext } from '../components/DocumentPage';
import WorkorderCard from '../components/WorkorderCard';
import { WorkorderBM } from '@bm-js/h2o-shared';

const context: DocumentPageContext<WorkorderBM> = {
  documentName: 'Arbetsorder',
  documentNameSpecific: 'Arbetsordern',
  documentKey: 'workorder',
  documentKeyPlural: 'workorders',
  documentNamePlural: 'Arbersordrar',
  internalPersonKey: 'technicians',
  contactPersonKey: 'orderPersons',
  cardComponent: WorkorderCard,
  internalPersonKeyRequired: false,
  cardGridTemplate: '2fr 2fr 2fr 1fr 1fr 20px',
  cardHeaders: [
    {
      text: 'Startdatum',
      mobile: true,
    },
    {
      text: 'Kontaktpersoner',
    },
    {
      text: 'Ansvariga tekniker',
    },
    {
      text: 'Antal rader',
    },
    {
      text: 'Status',
      mobile: true,
    },
  ],
};

const WorkordersRoute = () => {
  return <DocumentPage context={context} />;
};

export default WorkordersRoute;
