import { UserFM } from '@bm-js/h2o-shared';

export type UserFormInput = Omit<
  UserFM,
  | 'notifications'
  | 'oldId'
  | 'h2oAdmin'
  | 'createdAt'
  | 'updatedAt'
  | '__v'
  | 'role'
  | 'password'
  | 'lastPasswordChange'
> & { passwordRepeat: string; role: string; password: string; active: boolean };

export enum PasswordRequirementKey {
  TenChars = '10chars',
  OneCapital = 'oneCapital',
  OneLower = 'oneLower',
  OneNumber = 'oneNumber',
  SamePass = 'samePass',
}
