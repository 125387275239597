import { FormEventHandler, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import ReservoirCard from '../components/ReservoirCard';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { request } from '../utils/request';
import DataListSearchBox from '../components/DataListSearchBox';
import { BaseEventHandler, CustomerBM, ReservoirBM } from '@bm-js/h2o-shared';
import { Link, useNavigate } from 'react-router';

const StyledHomeRoute = styled.div`
  .customer-card-header-right-content {
    display: flex;
    gap: 0.5rem;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    a {
      margin-right: 1rem;
    }
  }
`;

const HomeRoute = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const navigate = useNavigate();

  const [customers, setCustomers] = useState<CustomerBM[]>([]);
  const [customerNamesAndIds, setCustomerNamesAndIds] = useState<
    { name: string; _id: string }[]
  >([]);
  const [reservoirNamesAndIds, setReservoirNamesAndIds] = useState<
    { name: string; _id: string; customerId: string }[]
  >([]);
  const [reservoirsSearchString, setReservoirsSearchString] = useState('');
  const [searchReservoirsHelperText, setSearchReservoirsHelperText] = useState(
    'Sök för att visa reservoarer'
  );
  const [searchString, setSearchString] = useState('');
  const [matchingReservoirs, setMatchingReservoirs] = useState<
    (ReservoirBM & { customerId: string })[]
  >([]);

  const getOverviewData = async (q?: string) => {
    const { data } = await request<{
      customers: CustomerBM[];
    }>({
      state,
      dispatch,
      path: `customers/overviewdata?q=${q || ''}`,
    });

    if (!data) return;
    setCustomers(data.customers);
  };

  const commitCustomerSearch: FormEventHandler = (e) => {
    e.preventDefault();
    if (searchString.length > 2) getOverviewData(searchString);
  };

  const searchReservoirs: FormEventHandler = async (e) => {
    e.preventDefault();
    if (reservoirsSearchString.length < 3) {
      setSearchReservoirsHelperText('Skriv minst 3 tecken för att söka');
      return;
    }

    const { data } = await request<(ReservoirBM & { customerId: string })[]>({
      state,
      dispatch,
      path: `customers/search-reservoirs/?q=${reservoirsSearchString}`,
    });
    if (!data) return;
    setMatchingReservoirs(data);
  };

  const getDataLists = async () => {
    const { data } = await request<{
      customers: { name: string; _id: string }[];
      reservoirs: { name: string; _id: string; customerId: string }[];
    }>({
      state,
      dispatch,
      path: 'customers/datalist-names?includeReservoirs=true',
      skipLoader: true,
    });
    if (!data) return;
    setCustomerNamesAndIds(data.customers);
    setReservoirNamesAndIds(data.reservoirs);
  };

  const getData = async () => {
    const promises = [getOverviewData(), getDataLists()];
    await Promise.all(promises);
  };

  const handleCustomerSearchStringChange: BaseEventHandler = (e) => {
    setSearchString(e.target.value);
    const exactMatch = customerNamesAndIds.find(
      (customer) => customer.name === e.target.value
    );
    if (exactMatch) {
      navigate(`/customers/single/?id=${exactMatch._id}`);
    }
  };

  const handleReservoirSearchStringChange: BaseEventHandler = (e) => {
    setReservoirsSearchString(e.target.value);
    const exactMatch = reservoirNamesAndIds.find(
      (reservoir) => reservoir.name === e.target.value
    );
    if (exactMatch) {
      navigate(
        `/reservoirs/single/?customerId=${exactMatch.customerId}&reservoirId=${exactMatch._id}`
      );
    }
  };

  useEffect(() => {
    if (state.token) getData();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  return (
    <StyledHomeRoute>
      <h1 className="page-header">Översikt</h1>
      <div className="single-customer-inner">
        <div className="single-customer-left-content">
          <Card>
            <div className="customer-card-header">
              <h3>Sök reservoarer</h3>
              <div className="customer-card-header-right-content">
                <DataListSearchBox
                  onSubmit={searchReservoirs}
                  onChange={handleReservoirSearchStringChange}
                  value={reservoirsSearchString}
                  items={reservoirNamesAndIds}
                  itemNameKey={'name'}
                  itemKey={'_id'}
                  identifier={'search-reservoirs-overview'}
                  placeholder="Sök reservoarer"
                />
              </div>
            </div>
            {searchReservoirsHelperText ? (
              <p>{searchReservoirsHelperText}</p>
            ) : null}
            {matchingReservoirs.map((reservoir) => (
              <ReservoirCard
                inner
                key={reservoir._id}
                reservoir={reservoir}
                customerId={reservoir.customerId}
              />
            ))}
          </Card>
          <Card>
            <div className="customer-card-header">
              <div>
                <h3>Kunder</h3>
                <Link to="/customers">
                  <Button noBg>Visa alla</Button>
                </Link>
              </div>
              <div className="customer-card-header-right-content">
                <DataListSearchBox
                  onChange={handleCustomerSearchStringChange}
                  value={searchString}
                  items={customerNamesAndIds}
                  itemNameKey={'name'}
                  itemKey={'_id'}
                  identifier={'search-customers-overview'}
                  placeholder="Sök kunder"
                  onSubmit={commitCustomerSearch}
                />
              </div>
            </div>
            {customers.map((customer) => (
              <CustomerCard inner customer={customer} key={customer._id} />
            ))}
          </Card>
        </div>
      </div>
    </StyledHomeRoute>
  );
};

export default HomeRoute;
