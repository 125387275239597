import { Link } from 'react-router';
import styled from 'styled-components';
import { parseDate } from '../utils/parseDate.util';
import { colors } from '../cnst/colors.cnst';
import Card from './Card';
import { ReservoirBM } from '@bm-js/h2o-shared';

const StyledReservoirCard = styled.div`
  .card {
    transition: 200ms ease-in-out;
    .customer-card-grid {
      display: grid;
      grid-template-columns: 7fr 3fr 3fr 3fr 3fr 3fr 3fr;
      gap: 0.5rem;
      p {
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .customer-card-headers {
      color: ${colors.mediumGrey};
      transition: 100ms ease-in-out;
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }
  }
  &:hover {
    .card {
      background: ${colors.lighterBlue};
      .customer-card-headers {
        color: ${colors.white};
      }
    }
  }
  @media (max-width: 1000px) {
    .card {
      .customer-card-grid {
        grid-template-columns: 2fr 1fr;
      }
    }
    p {
      display: none;
      &.mobile-visible {
        display: initial;
      }
    }
  }
`;

type Props = {
  reservoir: ReservoirBM;
  customerId: string;
  inner?: boolean;
};

const ReservoirCard = ({ reservoir, customerId, inner }: Props) => {
  const { basicInformation } = reservoir;
  return (
    <StyledReservoirCard className="customer-grid">
      <Link
        to={`/reservoirs/single/?customerId=${customerId}&reservoirId=${reservoir._id}`}
      >
        <Card className="card" inner={inner}>
          <div className="customer-card-grid customer-card-headers">
            <p className="mobile-visible">Namn</p>
            <p>Filterbyte</p>
            <p>Inspektion</p>
            <p>Kontroll</p>
            <p>Robotrengöring</p>
            <p>Manuell rengöring</p>
            <p>Statusbedömning</p>
          </div>
          <div className="customer-card-grid customer-card-information">
            <p className="mobile-visible">
              <strong>{basicInformation.name || '—'}</strong>
            </p>
            <p>
              {parseDate(reservoir.nextRecommendedAction?.filterChange) || '—'}
            </p>
            <p>
              {parseDate(reservoir.nextRecommendedAction?.inspection) || '—'}
            </p>
            <p>{parseDate(reservoir.nextRecommendedAction?.control) || '—'}</p>
            <p>
              {parseDate(reservoir.nextRecommendedAction?.robotCleaning) || '—'}
            </p>
            <p>
              {parseDate(reservoir.nextRecommendedAction?.manualCleaning) ||
                '—'}
            </p>
            <p>
              {parseDate(reservoir.nextRecommendedAction?.statusCheck) || '—'}
            </p>
          </div>
        </Card>
      </Link>
    </StyledReservoirCard>
  );
};

export default ReservoirCard;
