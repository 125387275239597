import axios from 'axios';
import React, {
  ChangeEventHandler,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import Breadcrumbs from '../components/Breadcrumbs';
import BtnNoStyle from '../components/BtnNoStyle';
import Button from '../components/Button';
import Card from '../components/Card';
import OverlayBox from '../components/OverlayBox';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { colors } from '../cnst/colors.cnst';
import { do_url } from '../cnst/server.cnst';
import { UserFM } from '@bm-js/h2o-shared';
import { ActionType } from '../types/dispatch.types';
import { useQuery } from '../hooks/useQuery';
const StyledDocumentsRoute = styled.div`
  #fileInput {
    display: none;
  }
  .documents-buttons-container {
    display: flex;
    .templates-outer-container {
      position: relative;
      .templates-list-container {
        box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        top: 2.5rem;
        position: absolute;
        right: 0;
        z-index: 2;
        background: ${colors.white};
        overflow: hidden;
        opacity: 0;
        pointer-events: none;
        transition: 200ms ease-in-out;
        .single-file-text {
          background: ${colors.white};
          white-space: nowrap;
          padding: 1rem 2rem;
          margin: 0;
          width: 100%;
          text-align: left;
          transition: 200ms ease-in-out;
          &:hover {
            background: ${colors.lightGrey};
          }
        }
      }
      &:hover {
        .templates-list-container {
          opacity: 1;
          pointer-events: initial;
        }
      }
    }
    button {
      margin: 0.3rem 0;
      margin-left: 0.5rem;
    }
  }
  .main-section {
    .file-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .single-document-buttons-container-outer {
    position: relative;
    img {
      width: 30px;
    }
    .single-document-buttons-container-inner {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: ${colors.white};
      padding: 1rem;
      border-radius: 10px;
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      transition: 100ms ease-in-out;
      button {
        white-space: nowrap;
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }
    &:hover {
      .single-document-buttons-container-inner {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
  .documents-page-upload-button {
    white-space: nowrap;
  }
  .select-year-sector {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    gap: 1rem;
    select {
      width: revert;
    }
    p {
      margin: 0;
      margin-bottom: 1rem;
    }
  }
  .documents-buttons-container {
    flex-direction: column;
    padding-right: 0.5rem;
  }

  @media (max-width: 1000px) {
    .main-section {
      .file-card {
        h5 {
          font-size: 1rem;
        }
      }
    }
    .documents-buttons-container {
      flex-direction: column;
      padding-right: 1rem;
      button {
        white-space: nowrap;
      }
    }
  }
`;

const DocumentsRoute = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const searchQuery = useQuery<{
    name?: string;
    type?: string;
    typeName?: string;
    id?: string;
  }>();

  const [filesList, setFilesList] = useState([]);
  const [templateFilesList, setTemplateFilesList] = useState([]);
  const [deleteDocumentPending, setDeleteDocumentPending] = useState({
    active: false,
    document: '',
  });
  const [documentYears, setDocumentYears] = useState<number[]>([]);
  const [selectedDocumentYear, setSelectedDocumentYear] = useState<
    string | number
  >('');

  const [moveFileModal, setMoveFileModal] = useState({
    active: false,
    filename: '',
  });

  const readFiles: ChangeEventHandler<HTMLInputElement> = (e) => {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (!e.target.files || e.target.files.length === 0) return;
    dispatch({ type: ActionType.LOADING_PROGRESS, progress: 1, value: true });
    uploadFiles(e.target.files);
    fileInput.value = '';
  };

  const uploadFiles = (currentFiles: FileList) => {
    const formData = new FormData();
    for (let index = 0; index < currentFiles.length; index++) {
      formData.append(encodeURI(currentFiles[index].name), currentFiles[index]);
    }
    axios
      .post(
        `${do_url}files/${state.token}/upload/${searchQuery.name}/pdf/${searchQuery.type}/${selectedDocumentYear}`,
        formData,
        {
          headers: { 'content-type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total!
            );
            dispatch({
              type: ActionType.LOADING_PROGRESS,
              progress: progress,
              value: true,
            });
          },
        }
      )
      .then(() => {
        dispatch({
          type: ActionType.ALERT,
          content: 'Filerna har laddats upp',
        });

        getFilesFilst();
      })
      .catch((err) => {
        console.log(err.response);
        dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
        dispatch({
          type: ActionType.LOADING_PROGRESS,
          progress: null,
          value: false,
        });
      });
  };

  const getFilesFilst = () => {
    dispatch({ type: ActionType.LOADING, value: true });
    axios
      .get(
        `${do_url}files/${state.token}/files-list/${searchQuery.name}/pdf/${searchQuery.type}/${searchQuery.typeName}/${selectedDocumentYear}`
      )
      .then((res) => {
        setFilesList(res.data.filesArray);
        if (!selectedDocumentYear && searchQuery.type !== 'servicedeal') {
          setSelectedDocumentYear(new Date().getFullYear());
        }
        dispatch({
          type: ActionType.LOADING_PROGRESS,
          progress: null,
          value: false,
        });
      })
      .catch(() => {
        dispatch({ type: ActionType.LOADING, value: false });
      });
  };

  const downloadSingleFile = (filename: string) => {
    dispatch({ type: ActionType.LOADING, value: true });
    axios
      .get(
        `${do_url}files/${state.token}/${searchQuery.name}/pdf/${searchQuery.type}/${filename}/${selectedDocumentYear}`
      )
      .then((res) => {
        const arrayBuf = new Uint8Array(res.data.data);
        const blob = new Blob([arrayBuf]);
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        dispatch({ type: ActionType.LOADING, value: false });
        a.click();
      })
      .catch(() => {
        dispatch({ type: ActionType.LOADING, value: false });
        dispatch({ type: ActionType.ALERT, content: 'Något gick fel.' });
      });
  };

  const previewSingleFile = (filename: string) => {
    dispatch({ type: ActionType.LOADING, value: true });
    axios
      .get(
        `${do_url}files/${state.token}/${searchQuery.name}/pdf/${searchQuery.type}/${filename}/${selectedDocumentYear}`
      )
      .then((res) => {
        const arrayBuf = new Uint8Array(res.data.data);
        const blob = new Blob([arrayBuf], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url);
        dispatch({ type: ActionType.LOADING, value: false });
      })
      .catch(() => {
        dispatch({ type: ActionType.LOADING, value: false });
        dispatch({ type: ActionType.ALERT, content: 'Något gick fel.' });
      });
  };

  const moveFileToYear = (filename: string, year: string | number) => {
    if (!filename) return;
    dispatch({ type: ActionType.LOADING, value: true });
    axios
      .post(
        `${do_url}files/${state.token}/rearrange-files-list/${
          searchQuery.name
        }/pdf/${searchQuery.type}/${searchQuery.typeName}/${year}/${
          selectedDocumentYear || 'unspecified'
        }/${filename}`
      )
      .then(() => {
        getFilesFilst();
        setMoveFileModal({
          filename: '',
          active: false,
        });
        dispatch({
          type: ActionType.ALERT,
          content: `Filen flyttades till ${
            year === 'unspecified' ? '"Ej specificerat"' : year
          }`,
        });
      })
      .catch(() => {
        dispatch({ type: ActionType.LOADING, value: false });
        dispatch({ type: ActionType.ALERT, content: 'Något gick fel.' });
      });
  };

  const downloadTemplate = (pdfTemplateType: string, fileName: string) => {
    dispatch({ type: ActionType.LOADING, value: true });
    const filePath = `${pdfTemplateType}/${fileName}`;
    axios
      .get(
        `${do_url}files/${state.token}/templates/single/${encodeURIComponent(
          filePath
        )}`
      )
      .then((res) => {
        const arrayBuf = new Uint8Array(res.data.data);
        const blob = new Blob([arrayBuf]);
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        dispatch({ type: ActionType.LOADING, value: false });
        a.click();
      })
      .catch(() => {
        dispatch({ type: ActionType.LOADING, value: false });
        dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
      });
  };

  const getTemplatesList = () => {
    dispatch({ type: ActionType.LOADING, value: true });
    axios
      .get(`${do_url}files/${state.token}/templates/list`)
      .then((res) => {
        dispatch({ type: ActionType.LOADING, value: false });
        setTemplateFilesList(res.data);
      })
      .catch(() => {
        dispatch({ type: ActionType.LOADING, value: false });
      });
  };

  const deleteFile = (filename: string) => {
    dispatch({ type: ActionType.LOADING, value: true });
    axios
      .delete(
        `${do_url}files/${state.token}/${searchQuery.name}/pdf/${searchQuery.type}/${filename}/${selectedDocumentYear}`
      )
      .then(() => {
        dispatch({ type: ActionType.ALERT, content: 'Fil raderad' });
        setDeleteDocumentPending({ ...deleteDocumentPending, active: false });
        getFilesFilst();
      })
      .catch(() => {
        dispatch({ type: ActionType.LOADING, value: false });
        dispatch({ type: ActionType.ALERT, content: 'Något gick fel' });
      });
  };

  //GET FILES WHEN YEAR-SELECTOR CHANGES
  useEffect(() => {
    getFilesFilst();
    //eslint-disable-next-line
  }, [selectedDocumentYear]);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      //SET YEARS FOR SELECTBOX
      const years = [];
      const START_YEAR = 2016;
      const DAY_TO_MS = 1000 * 60 * 60 * 24;
      const DIFF_LAST_YEAR = (30 + 31) * DAY_TO_MS;
      const currentYear = new Date(
        new Date().getTime() + DIFF_LAST_YEAR
      ).getFullYear();
      for (let year = START_YEAR; year <= currentYear; year++) {
        years.push(year);
      }
      setDocumentYears(years);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchQuery.name && state.token) {
      getFilesFilst();
      getTemplatesList();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, state.token]);

  return (
    <StyledDocumentsRoute>
      <h1 className="page-header">{searchQuery.typeName}</h1>
      <Breadcrumbs
        crumbs={[
          { to: '/home', p: 'Hem' },
          { to: '/customers', p: 'Kunder' },
          {
            to: `/customers/single?id=${searchQuery.id}`,
            p: searchQuery.name ? searchQuery.name.split('_').join(' ') : '',
          },
          { p: searchQuery.typeName },
        ]}
        rightContent={
          <div className="documents-buttons-container">
            <input
              type="file"
              onChange={readFiles}
              multiple
              id="fileInput"
              accept=".pdf,.pages"
            />
            {/* @ts-ignore */}
            {templateFilesList[searchQuery.type]?.length > 0 ? (
              <div className="templates-outer-container">
                <Button small white>
                  Ladda ned mall
                </Button>
                <div className="templates-list-container">
                  {/* @ts-ignore */}
                  {templateFilesList[searchQuery.type]?.map((file, index) => (
                    <div
                      className="single-file-template"
                      key={file + index + 'template'}
                    >
                      <BtnNoStyle
                        className="single-file-text"
                        onClick={() =>
                          downloadTemplate(searchQuery.typeName!, file)
                        }
                      >
                        {file}
                      </BtnNoStyle>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            <Button
              small
              white
              className="documents-page-upload-button"
              onClick={() => {
                document.getElementById('fileInput')?.click();
              }}
            >
              Ladda upp filer
            </Button>
          </div>
        }
      />
      {searchQuery.type === 'servicedeal' ? null : (
        <div className="select-year-sector">
          <p>År</p>
          {/* eslint-disable-next-line */}
          <select
            value={selectedDocumentYear}
            onChange={(e) => setSelectedDocumentYear(e.target.value)}
          >
            <option value="" disabled>
              Välj år
            </option>
            {documentYears.map((year) => (
              <option value={year} key={`documentYear_${year}`}>
                {year}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="main-section">
        {filesList.length > 0 ? (
          filesList.map((single) => (
            <React.Fragment key={single}>
              <Card className="file-card">
                <h5>{single}</h5>
                <div className="single-document-buttons-container-outer">
                  <BtnNoStyle>
                    <img src="/icons/3-dots.png" alt="" />
                  </BtnNoStyle>
                  <div className="single-document-buttons-container-inner">
                    <Button small onClick={() => downloadSingleFile(single)}>
                      Ladda ned
                    </Button>
                    <Button small onClick={() => previewSingleFile(single)}>
                      Förhandsvisa
                    </Button>
                    <Button
                      small
                      onClick={() =>
                        setMoveFileModal({
                          active: true,
                          filename: single,
                        })
                      }
                    >
                      Flytta fil
                    </Button>
                    {(state.user as UserFM).role === 'Admin' ? (
                      <Button
                        small
                        look="danger"
                        onClick={() =>
                          setDeleteDocumentPending({
                            active: true,
                            document: single,
                          })
                        }
                      >
                        Radera
                      </Button>
                    ) : null}
                  </div>
                </div>
              </Card>
            </React.Fragment>
          ))
        ) : (
          <p>Det finns inga filer här ännu</p>
        )}
      </div>
      <OverlayBox
        header={`Radera filen ${deleteDocumentPending.document}?`}
        active={deleteDocumentPending.active}
        close={() =>
          setDeleteDocumentPending({
            ...deleteDocumentPending,
            active: false,
          })
        }
      >
        <p>
          Vill du <strong>permanent radera </strong> filen{' '}
          {deleteDocumentPending.document}?
        </p>
        <p>
          Det går <strong>inte</strong> att återskapa en raderad fil.
        </p>
        <Button
          look="danger"
          med
          onClick={() => deleteFile(deleteDocumentPending.document)}
        >
          Radera
        </Button>
        <Button
          med
          onClick={() =>
            setDeleteDocumentPending({
              ...deleteDocumentPending,
              active: false,
            })
          }
        >
          Tillbaka
        </Button>
      </OverlayBox>
      <OverlayBox
        active={moveFileModal.active}
        close={() =>
          setMoveFileModal({
            filename: '',
            active: false,
          })
        }
        header="Flytta fil"
      >
        <p>Vart fill du flytta filen?</p>
        {/* eslint-disable-next-line */}
        <select
          value={selectedDocumentYear}
          onChange={(e) => {
            moveFileToYear(moveFileModal.filename, e.target.value);
          }}
        >
          <option disabled>Välj år</option>
          {documentYears.map((year) => (
            <option value={year} key={`moveFileModal_${year}`}>
              {year}
            </option>
          ))}
        </select>
      </OverlayBox>
    </StyledDocumentsRoute>
  );
};

export default DocumentsRoute;
