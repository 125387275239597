import { Link } from 'react-router';
import { CustomerBM } from '@bm-js/h2o-shared';
import AdminCard from './AdminCard';
import Button from './Button';

type Props = {
  customer: CustomerBM;
  deleteCustomer: (customer: CustomerBM) => void;
};

const AdminCustomerCard = ({ customer, deleteCustomer }: Props) => {
  return (
    <div>
      <AdminCard
        cardContent={customer.customerInformation?.name}
        menuContent={
          <>
            <Link to={`/customers/single?id=${customer._id}`}>
              <Button small>Visa</Button>
            </Link>
            <Link to="/admin/edit-customer" state={{ formData: customer }}>
              <Button small>Redigera</Button>
            </Link>
            <Button
              small
              look="danger"
              onClick={() => deleteCustomer(customer)}
            >
              Radera
            </Button>
          </>
        }
      />
    </div>
  );
};

export default AdminCustomerCard;
