import {
  CustomerBM,
  OfferBM,
  OrderConfirmationBM,
  ServicelogV2BM,
} from '@bm-js/h2o-shared';
import { request } from './request';
import { GlobalState } from '../types/state.types';
import { Dispatch } from 'react';
import { Action } from '../types/dispatch.types';
import { parseCustomerName } from './parseCustomerName.util';
import { NavigateFunction } from 'react-router';

interface CreateServicelogFromOfferInput {
  state: GlobalState;
  dispatch: Dispatch<Action>;
  customerId: string;
  customerName: string;
  offer: OfferBM;
  navigate: NavigateFunction;
}

export const _createServicelogFromOffer = async ({
  offer,
  state,
  dispatch,
  customerId,
  customerName,
  navigate,
}: CreateServicelogFromOfferInput) => {
  const { err: mongoErr, data } = await request<ServicelogV2BM>({
    state,
    dispatch,
    path: `offers/create-servicelog-from-offer/${offer._id}`,
    method: 'POST',
  });
  if (mongoErr || !data) return;

  const { data: customer, err: customerErr } = await request<CustomerBM>({
    state,
    dispatch,
    path: `customers/single/${customerId}`,
  });

  if (customerErr || !customer) return;

  const parsedCustomer = {
    ...customer,
    name: customerName,
    id: customerId,
    reservoirs: [],
    communications: [],
  };

  const { err: doErr } = await request({
    state,
    dispatch,
    method: 'POST',
    isDoServer: true,
    body: { ...data, customer: parsedCustomer },
    path: `servicelogs/{token}/create-document/${parseCustomerName(
      customerName
    )}`,
  });

  if (doErr) return;

  navigate(
    `/servicelogs/?id=${customerId}&name=${parseCustomerName(
      customerName
    )}&idToOpen=${data._id}`
  );
};

export const _createOrderConfirmationFromOffer = async ({
  offer,
  state,
  dispatch,
  customerId,
  customerName,
  navigate,
}: CreateServicelogFromOfferInput) => {
  const { err: mongoErr, data } = await request<OrderConfirmationBM>({
    state,
    dispatch,
    path: `offers/create-orderConfirmation-from-offer/${offer._id}`,
    method: 'POST',
  });
  if (mongoErr || !data) return;

  const { data: customer, err: customerErr } = await request<CustomerBM>({
    state,
    dispatch,
    path: `customers/single/${customerId}`,
  });

  if (customerErr || !customer) return;

  const parsedCustomer = {
    ...customer,
    name: customerName,
    id: customerId,
    reservoirs: [],
    communications: [],
  };

  const { err: doErr } = await request({
    state,
    dispatch,
    method: 'POST',
    isDoServer: true,
    body: { ...data, customer: parsedCustomer },
    path: `orderConfirmations/{token}/create-document/${parseCustomerName(
      customerName
    )}`,
  });

  if (doErr) return;

  navigate(
    `/order-confirmations/?id=${customerId}&name=${parseCustomerName(
      customerName
    )}&idToOpen=${data._id}`
  );
};
