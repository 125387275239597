import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { colors } from '../cnst/colors.cnst';

const StyledButton = styled.button<{
  $danger?: boolean;
  $small?: boolean;
  $med?: boolean;
  $inverted?: boolean;
  $noBg?: boolean;
  $white?: boolean;
}>`
  font-size: 1rem;
  font-weight: 600;
  font-family: inherit;
  padding: 1.2rem 5rem;
  margin-top: 0.5rem;
  border: none;
  background: ${colors.lightBlue};
  color: ${colors.white};
  border-radius: 10px;
  transition: 50ms ease-in-out;
  ${({ $danger }) =>
    $danger ? 'background: ' + colors.red + '; color: white;' : ''}
  cursor: pointer;
  &:disabled {
    pointer-events: none;
    color: ${colors.black};
    background: ${colors.lightGrey};
    opacity: 0.5;
  }
  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    color: white;
    background: ${colors.lightBlueFaded};
    ${({ $danger }) => ($danger ? 'background: ' + colors.redFaded + ';' : '')}
  }
  ${({ $small }) =>
    $small
      ? 'font-size: 1rem; font-weight: 400; margin: 0; padding: 0.5rem 1.5rem;'
      : ''}
  ${({ $med }) =>
    $med
      ? 'font-size: 1rem; font-weight: 400: margin: 0; padding: 1rem 2rem;'
      : ''}
  ${({ $inverted }) =>
    $inverted
      ? 'background: ' +
        colors.white +
        '; color: ' +
        colors.lightBlue +
        '; border: 1px solid transparent; &:hover{border: 1px solid ' +
        colors.white +
        ';}'
      : ''}
  ${({ $noBg }) =>
    $noBg
      ? 'background: transparent; color: ' +
        colors.lightBlue +
        ';  padding: 0; margin: 0; &:hover{color: ' +
        colors.lightBlueFaded +
        '; background: transparent;}'
      : ''}
  ${({ $noBg, $danger }) =>
    $noBg && $danger
      ? 'color: red; &:hover{ color: ' + colors.redFaded + ';}'
      : ''}
  ${({ $white }) =>
    $white
      ? 'background: ' +
        colors.white +
        '; color: ' +
        colors.black +
        '; box-shadow: 3px 3px 10px rgba(0,0,0,0.1);'
      : ''}
`;

type Props = {
  white?: boolean;
  style?: React.CSSProperties;
  noBg?: boolean;
  med?: boolean;
  inverted?: boolean;
  look?: 'danger';
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  id?: string;
  onClick?: () => void;
  small?: boolean;
  disabled?: boolean;
};

const Button = ({
  white,
  style,
  noBg,
  med,
  inverted,
  look,
  type,
  id,
  onClick,
  small,
  disabled,
  className,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <StyledButton
      $white={white}
      style={style}
      $noBg={noBg}
      $med={med}
      $inverted={inverted}
      $danger={look === 'danger'}
      type={type || 'button'}
      className={className}
      id={id}
      onClick={onClick}
      $small={small}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
