import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { parseDate } from '../utils/parseDate.util';
import { colors } from '../cnst/colors.cnst';
import BtnNoStyle from './BtnNoStyle';
import Button from './Button';
import Card from './Card';
import Checkbox from './Checkbox';
import { Notification } from '@bm-js/h2o-shared';
import { Link } from 'react-router';

type StyledProps = {
  $hasBatchSelection?: boolean;
  $noBtn?: boolean;
  $isInternal?: boolean;
  $alertColor?: string;
};

const StyledNotificationCard = styled.div<StyledProps>`
  .notification-card {
    display: grid;
    ${({ $hasBatchSelection }) =>
      $hasBatchSelection
        ? 'grid-template-columns: 20px auto;'
        : 'grid-template-columns: auto;'}
    gap: 1rem;
    align-items: center;
    background: ${({ $alertColor }) => $alertColor};
    ${({ $isInternal }) =>
      $isInternal &&
      'background: ' + colors.lightBlue + '; color: ' + colors.white + ';'}

    position: relative;
    .notification-unread-alert {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: ${colors.red};
    }
  }

  .notification-top {
    margin-bottom: 0.3rem;
    p {
      opacity: 0.5;
      font-size: 0.9rem;
    }
  }

  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  gap: 0.5rem;
  img {
    width: 100%;
    margin: 0;
  }
  .notification-button-container {
    position: relative;
    margin-top: 1rem;
    .notification-menu-container {
      position: absolute;
      top: 1.2rem;
      right: 0;
      padding: 0.5rem;
      background: ${colors.white};
      border-radius: 10px;
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);
      pointer-events: none;
      opacity: 0;
      z-index: 2;
      transition: 100ms ease-in-out;
      button {
        width: 100%;
        margin-bottom: 0.5rem;
        white-space: nowrap;
      }
    }
    &:hover {
      .notification-menu-container {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
`;

type Props = {
  showUnreadAlert?: boolean;
  notification: Notification;
  batchSelection?: Notification[];
  toggleBatchSelection?: (notification: Notification) => void;
  noBtn?: boolean;
  hasEdit?: boolean;
  archived?: boolean;
  my?: boolean;
  archiveNotification?: (notification: Notification) => void;
  showNotification?: (notification: Notification) => void;
  showInternalNotification?: (notification: Notification) => void;
  preDeleteNotification?: (notification: Notification) => void;
  editNotification?: (notification: Notification) => void;
  toggleReadStatus?: (notification: Notification) => void;
};

const NotificationCard = (props: Props) => {
  const {
    notification,
    batchSelection,
    toggleBatchSelection,
    noBtn,
    hasEdit,
    my,
    archiveNotification,
    showInternalNotification,
    showNotification,
    archived,
    preDeleteNotification,
    editNotification,
    showUnreadAlert,
    toggleReadStatus,
  } = props;
  const [alertLevel, setAlertLevel] = useState(0);

  const alertLevelColors = [
    colors.lightGrey,
    colors.yellowAlert,
    colors.orangeAlert,
    colors.redAlert,
  ];

  useEffect(() => {
    const TIME_TO_ALERT_3 = 0;
    const TIME_TO_ALERT_2 = 1000 * 60 * 60 * 24 * 7;
    const TIME_TO_ALERT_1 = 1000 * 60 * 60 * 24 * 30;
    const today = new Date().getTime();
    const notificationDate = new Date(notification.dateForHandling).getTime();
    const timeDiff = notificationDate - today;

    if (timeDiff < TIME_TO_ALERT_1) {
      setAlertLevel(1);
      if (timeDiff < TIME_TO_ALERT_2) {
        setAlertLevel(2);
        if (timeDiff < TIME_TO_ALERT_3) {
          setAlertLevel(3);
        }
      }
    } else {
      setAlertLevel(0);
    }
  }, [notification]);
  return (
    <StyledNotificationCard
      $hasBatchSelection={!!batchSelection}
      $noBtn={noBtn}
      $isInternal={notification.isInternal}
      $alertColor={
        notification.archived
          ? alertLevelColors[0]
          : alertLevelColors[alertLevel]
      }
    >
      <Card inner className="notification-card">
        {showUnreadAlert && !notification.read && (
          <div className="notification-unread-alert" />
        )}
        {batchSelection && (
          <div>
            <Checkbox
              id={`notificationCheck-${notification._id}`}
              checked={!!batchSelection.find((n) => n._id === notification._id)}
              onChange={() => toggleBatchSelection?.(notification)}
            />
          </div>
        )}
        <div>
          <div className="notification-top">
            <p>{`${parseDate(notification.dateForHandling)} - ${
              notification.recipientFirstName
            } ${notification.recipientLastName}`}</p>
            <p>
              {notification.customerName || ''}
              {notification.reservoirName
                ? ' - ' + notification.reservoirName
                : ''}
            </p>
          </div>
          <p>
            {notification.isInternal
              ? notification.subject
              : notification.textContent}
          </p>
        </div>
      </Card>
      <div className="notification-button-container">
        <BtnNoStyle>
          <img src="/icons/3-dots.png" alt="" />
        </BtnNoStyle>
        <div className="notification-menu-container">
          {!!notification.link && !noBtn && (
            <Link
              to={notification.link}
              state={{
                linkKey: notification.linkKey || null,
                notification: notification.linkKey ? notification : null,
              }}
            >
              <Button small>Gå till notis</Button>
            </Link>
          )}
          {!!hasEdit && (
            <Button small onClick={() => editNotification?.(notification)}>
              Redigera
            </Button>
          )}
          {!!noBtn && (
            <Button small onClick={() => showNotification?.(notification)}>
              Visa notis
            </Button>
          )}
          {!!notification.isInternal && (
            <Button
              small
              onClick={() => showInternalNotification?.(notification)}
            >
              Visa notis
            </Button>
          )}
          {!!showUnreadAlert && !!toggleReadStatus && (
            <Button
              small
              onClick={() => {
                toggleReadStatus(notification);
              }}
            >
              Markera som {notification.read ? 'oläst' : 'läst'}
            </Button>
          )}
          {!!my && (
            <>
              {!archived && (
                <Button
                  small
                  onClick={() => {
                    archiveNotification?.(notification);
                  }}
                >
                  Arkivera
                </Button>
              )}
              <Button
                small
                look="danger"
                onClick={() => preDeleteNotification?.(notification)}
              >
                Radera
              </Button>
            </>
          )}
        </div>
      </div>
    </StyledNotificationCard>
  );
};

export default NotificationCard;
