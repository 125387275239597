export const parseSize = (bytes?: number) => {
  if (!bytes) return '-';
  const kb = bytes / 1000;
  const mb = kb / 1000;
  const gb = mb / 1000;
  let string = `${bytes.toFixed(2)}B`;
  if (kb > 1) string = `${kb.toFixed(2)}KB`;
  if (mb > 1) string = `${mb.toFixed(2)}MB`;
  if (mb > 1) string = `${mb.toFixed(2)}MB`;
  if (gb > 1) string = `${gb.toFixed(2)}GB`;
  return string;
};
