import { FormEvent, useState } from 'react';
import AdminCustomerCard from './AdminCustomerCard';
import Button from './Button';
import CustomerForm from './CustomerForm';
import DeleteConfirmation from './DeleteConfirmation';
import TabCard from './TabCard';
import { CustomerBM } from '@bm-js/h2o-shared';
import { request } from '../utils/request';
import { useGlobal } from '../hooks/useGlobalState';
import { parseCustomerName } from '../utils/parseCustomerName.util';

const AdminCustomers = () => {
  const { state, dispatch } = useGlobal();
  const [customersSearchString, setCustomersSearchString] = useState('');
  const [customers, setCustomers] = useState<CustomerBM[]>([]);
  const [activeCustomersTab, setActiveCustomersTab] = useState(0);

  const [customerDeletePending, setCustomerDeletePending] = useState<{
    active: boolean;
    customer?: CustomerBM;
  }>({ active: false });

  const deleteCustomerInit = (customer: CustomerBM) => {
    setCustomerDeletePending({
      active: true,
      customer: customer,
    });
  };

  const deleteCustomer = async (customer: CustomerBM) => {
    await request({
      state,
      dispatch,
      path: `customers/${customer._id}`,
      method: 'DELETE',
    });
    await request({
      state,
      dispatch,
      isDoServer: true,
      path: `files/{token}/delete-customer-directory/${parseCustomerName(
        customer
      )}/${customer._id}`,
      successText: 'Kund raderad',
      method: 'DELETE',
    });

    getCustomers();
    setCustomerDeletePending({ active: false });
  };

  const addCustomer = async (formData: Partial<CustomerBM>) => {
    await request({
      state,
      dispatch,
      path: 'customers/add',
      method: 'POST',
      body: { customer: formData },
    });

    const customerName = parseCustomerName(formData);
    await request({
      state,
      dispatch,
      isDoServer: true,
      method: 'POST',
      path: `files/{token}/create-customer-directories/${customerName}`,
      successText: 'Sparat',
    });
    setActiveCustomersTab(0);
    getCustomers();
  };

  const getCustomers = async (e?: FormEvent) => {
    if (e) e.preventDefault();
    if (customersSearchString.length < 3) return;

    const { data } = await request<{ customers: CustomerBM[] }>({
      state,
      dispatch,
      path: `customers/?q=${customersSearchString}&page=${0}&sortOrder=name`,
      errorText: 'Kunde inte hämta kunder',
    });
    if (!data) return;
    setCustomers(data.customers);
  };

  return (
    <>
      <TabCard
        activeTab={activeCustomersTab}
        setActiveTab={setActiveCustomersTab}
        tabs={['Hantera kunder', 'Lägg till kund']}
        slots={[
          <form onSubmit={getCustomers} className="search-box">
            <input
              type="text"
              value={customersSearchString}
              onChange={(e) => setCustomersSearchString(e.target.value)}
              placeholder="Sök kund"
            />
            <Button
              small
              type="submit"
              disabled={customersSearchString.length < 3}
            >
              Sök
            </Button>
          </form>,
        ]}
        contents={[
          customers.map((customer) => (
            <AdminCustomerCard
              key={customer._id}
              customer={customer}
              deleteCustomer={deleteCustomerInit}
            />
          )),
          <CustomerForm handleSubmit={addCustomer} />,
        ]}
      />
      {customerDeletePending.active && customerDeletePending.customer && (
        <DeleteConfirmation
          itemIdentifier={
            customerDeletePending.customer.customerInformation?.name
          }
          itemName={'kund'}
          close={() => setCustomerDeletePending({ active: false })}
          item={customerDeletePending.customer}
          commit={deleteCustomer}
        />
      )}
    </>
  );
};

export default AdminCustomers;
