import styled from 'styled-components';
import Breadcrumbs from '../components/Breadcrumbs';
import MediaLibrary, {
  MediaContextSelection,
} from '../components/MediaLibrary';
import { useQuery } from '../hooks/useQuery';

const StyledMediaRoute = styled.div`
  padding-bottom: 20rem;
`;

const MediaRoute = () => {
  const crumbs = [{ p: 'Hem', to: '/' }, { p: 'Filer' }];
  const query = useQuery<MediaContextSelection>();

  return (
    <StyledMediaRoute>
      <h1 className="page-header">Filer</h1>
      <Breadcrumbs crumbs={crumbs} />
      <MediaLibrary context={query} />
    </StyledMediaRoute>
  );
};

export default MediaRoute;
