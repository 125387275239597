import { Link, useNavigate } from 'react-router';
import React, { PropsWithChildren, useContext } from 'react';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { ActionType } from '../types/dispatch.types';

type Props = {
  to: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  className?: string;
};

const LinkPreventWrapper = (props: PropsWithChildren<Props>) => {
  const { preventNavigation } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const navigate = useNavigate();

  return (
    <Link
      {...props}
      onClick={(e) => {
        props.onClick?.(e);
        if (preventNavigation) {
          e.preventDefault();
          dispatch({
            type: ActionType.SET_PREVENT_NAVIGATE_POPUP,
            value: { active: true, destination: props.to },
          });
        } else {
          navigate(props.to);
        }
      }}
    />
  );
};

export default LinkPreventWrapper;
