import { useContext } from 'react';
import DocumentPage, { DocumentPageContext } from '../components/DocumentPage';
import OfferCard from '../components/OfferCard';
import { OfferBM } from '@bm-js/h2o-shared';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { useQuery } from '../hooks/useQuery';
import {
  _createOrderConfirmationFromOffer,
  _createServicelogFromOffer,
} from '../utils/offer.util';
import { useNavigate } from 'react-router';

const context: DocumentPageContext<OfferBM> = {
  documentName: 'Offert',
  documentNameSpecific: 'Offerten',
  documentKey: 'offer',
  documentKeyPlural: 'offers',
  documentNamePlural: 'Offerter',
  internalPersonKey: 'sellerReferences',
  contactPersonKey: 'customerReferences',
  pdfNeedsFullCustomer: true,
  enablePdfUpload: true,
  documentIdentifier: 'offerNumber',
  cardComponent: OfferCard,
  internalPersonKeyRequired: true,
  cardGridTemplate: '1fr 1fr 1fr 1fr 1fr 20px',
  cardHeaders: [
    {
      text: 'Offertnummer',
      mobile: true,
    },
    {
      text: 'Offertdatum',
    },
    {
      text: 'Antal rader',
    },
    {
      text: 'Summa',
    },
    {
      text: 'Status',
      mobile: true,
    },
  ],
};

const OffersRoute = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const navigate = useNavigate();

  const { name: customerName, id: customerId } = useQuery<{
    name: string;
    id: string;
  }>();

  const createServicelogFromOffer = async (offer: OfferBM) => {
    _createServicelogFromOffer({
      offer,
      state,
      dispatch,
      customerId,
      customerName,
      navigate,
    });
  };

  const createOrderConfirmationFromOffer = async (offer: OfferBM) => {
    _createOrderConfirmationFromOffer({
      offer,
      state,
      dispatch,
      customerId,
      customerName,
      navigate,
    });
  };

  const extraActions = [
    {
      text: 'Skapa servicelogg',
      action: createServicelogFromOffer,
    },
    {
      text: 'Skapa orderkännande',
      action: createOrderConfirmationFromOffer,
    },
  ];

  return <DocumentPage context={context} extraActions={extraActions} />;
};

export default OffersRoute;
