import { ProtocolFM } from '@bm-js/h2o-shared';
import ProtocolImage from './ProtocolImage';
import { MediaContextSelection } from './MediaLibrary';

type Props = {
  setProtocolData: (data: Partial<ProtocolFM>) => void;
  protocolData: Partial<ProtocolFM>;
  imageKey: 'mainImage' | 'secondaryImage';
  context: MediaContextSelection;
};

const ProtocolMainImage = ({
  setProtocolData,
  protocolData,
  imageKey,
  context,
}: Props) => {
  return (
    <ProtocolImage
      context={context}
      src={protocolData[imageKey]!}
      handleDelete={() =>
        setProtocolData({
          ...protocolData,
          [imageKey]: '',
        })
      }
      onChange={(src) =>
        setProtocolData({
          ...protocolData,
          [imageKey]: src,
        })
      }
    />
  );
};

export default ProtocolMainImage;
