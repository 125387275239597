import { useMemo } from 'react';
import { parseDate } from '../utils/parseDate.util';
import { parseSEK } from '../utils/parseSEK.util';
import Button from './Button';
import Card from './Card';
import DotsDropdown from './DotsDropdown';
import { OrderConfirmationBM } from '@bm-js/h2o-shared';
import { DocumentPageExtraAction } from './DocumentPage';

type Props = {
  entry: OrderConfirmationBM;
  deleteEntryInit: (entry: OrderConfirmationBM) => void;
  editEntryInit: (entry: OrderConfirmationBM) => void;
  sendEntryInit: (entry: OrderConfirmationBM) => void;
  downloadPdf: (entry: OrderConfirmationBM) => void;
  previewPdf: (entry: OrderConfirmationBM) => void;
  duplicateEntry: (entry: OrderConfirmationBM) => void;
  extraActions?: DocumentPageExtraAction<OrderConfirmationBM>[];
};

const OrderConfirmationCard = ({
  entry,
  deleteEntryInit,
  editEntryInit,
  sendEntryInit,
  downloadPdf,
  previewPdf,
  duplicateEntry,
  extraActions,
}: Props) => {
  const totalSum = useMemo(() => {
    const rowSums = entry.table.map((row) => {
      return row.priceExVat * row.quantity;
    });
    const totalSum = rowSums.reduce((acc, curr) => acc + curr, 0);
    return totalSum;
  }, [entry]);
  return (
    <div>
      <Card className="order-grid">
        <p className="mobile">{entry.offerNumber}</p>
        <p>{parseDate(entry.offerDate)}</p>
        <p>{entry.table.length}</p>
        <p>{parseSEK(totalSum)}</p>
        <p className="mobile">{entry.status}</p>
        <DotsDropdown
          content={
            <>
              <Button small onClick={() => previewPdf(entry)}>
                Förhandsvisa
              </Button>
              <Button small onClick={() => downloadPdf(entry)}>
                Ladda ned
              </Button>
              <Button small onClick={() => editEntryInit(entry)}>
                Redigera
              </Button>
              <Button small onClick={() => duplicateEntry(entry)}>
                Duplicera
              </Button>
              <Button small onClick={() => sendEntryInit(entry)}>
                Skicka som e-post
              </Button>
              {extraActions?.map((extra, i) => (
                <Button
                  key={`${i}_${extra.text}`}
                  small
                  onClick={() => extra.action(entry)}
                >
                  {extra.text}
                </Button>
              ))}
              <Button
                small
                onClick={() => deleteEntryInit(entry)}
                look="danger"
              >
                Radera
              </Button>
            </>
          }
        />
      </Card>
    </div>
  );
};

export default OrderConfirmationCard;
