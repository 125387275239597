import { UserFormInput } from '../types/user.types';

export const reservoirFormDefault = {
  basicInformation: {
    name: '',
    protected: false,
    gps: {
      lat: '' as unknown as number,
      lon: '' as unknown as number,
    },
    adress: [''],
    type: '',
    implementation: '',
    heightOverGround: '' as unknown as number,
    innerHeight: '' as unknown as number,
    volume: '' as unknown as number,
    volume2: '' as unknown as number,
    volume3: '' as unknown as number,
    volume4: '' as unknown as number,
    area: '' as unknown as number,
    area2: '' as unknown as number,
    area3: '' as unknown as number,
    area4: '' as unknown as number,
    compartments: {
      quantity: '' as unknown as number,
      name: '',
    },
    innerMaterial: '',
    innerMaterialOther: '',
    feeding: '',
    feedingOther: '',
    electricity: {
      hasElectricity: false,
      type: '',
    },
    water: {
      hasWater: false,
      waterOut: false,
      waterPost: false,
      waterTank: false,
    },
    summerStatusNew: '-',
    isWinterWork: false,
  },
  establishment: {
    broughtTelfer: false,
    crane: false,
    elevator: false,
    telferCrane: false,
    innerTelfer: false,
    parkingReservoirDistance: '' as unknown as number,
    establishmentTime: '' as unknown as number,
    establishmentTimeManual: '' as unknown as number,
    reEstablishmentRequired: false,
    pumpPitUnderLadder: '',
  },
  hatch: {
    manlucka: {
      hasManlucka: false,
      brand: '',
      brandOther: '',
    },
    safetyHatch: {
      hasSafetyHatch: false,
      dateInstalled: '' as unknown as Date,
    },
    measurments: {
      w: '' as unknown as number,
      h: '' as unknown as number,
    },
  },
  ventilation: {
    hasVentilation: false,
    type: '',
    typeOther: '',
    h2oVentDate: '' as unknown as Date,
    quantity: '' as unknown as number,
  },
  cleaning: {
    robotPossible: false,
    hasDiagonalPipe: false,
    diagonalPipe: 'Ej valt ännu',
    canPushRobotOverPipe: false,
    canDriveAroundPipe: false,
    canDriveUnderPipe: false,
    noRobotReason: '',
    robotType: '',
    vr50reason: '',
    cleaningTime: '' as unknown as number,
    cleaningTimeManual: '' as unknown as number,
    tripodSpace: '',
    floater: '',
  },
};

export const workorderFormDefault = {
  orderPerson: '',
  orderPersons: [],
  status: 'Utkast',
  additionalTechnicians: [],
  sentTo: [],
  technicians: [],
  concreteEngineer: '-',
  startDateTime: '',
  startDateTimeNew: '',
  meetingSpot: [''],
  table: [],
  other: [''],
};

export const workorderServices = [
  'Inspektion under drift',
  'Kontroll under drift',
  'Inspektion tömd reservoar',
  'Kontroll tömd reservoar',
  'Robotrengöring VR-50',
  'Robotrengöring VR-600',
  'Manuell rengöring',
  'Statusbedömning',
  'Tillståndsbedömning betong',
  'Montage säkerhetslucka',
  'Montage ventilation',
  'Montage kranarm',
  'Inmätning manlucka',
  'Inmätning ventilation',
  'Övrigt',
  'Montage tätningslist',
  'Montage avfuktare',
  'Sanering svartmögel',
  'Byte pollenfilter',
];

export const workPlanFormDefault = {
  orderPerson: '',
  status: 'Utkast',
  sentTo: [],
  technicians: [],
  startDate: '',
  endDate: '',
  creationDate: '',
  reservoir: '',
  riskOverview: [],
  equipment: [],
  workMomentDescriptions: [],
};

export const adminEditUserDefault: UserFormInput = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  passwordRepeat: '',
  role: '',
  _id: '',
  active: false,
};

export const munipRegEditDefault = {
  customerName: '',
  munipName: '',
  _id: '',
};
