import { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../cnst/colors.cnst';
import Button from './Button';
import ProtocolImage from './ProtocolImage';
import { ProtocolFM, ProtocolOptionBM } from '@bm-js/h2o-shared';
import { MediaContextSelection } from './MediaLibrary';

const StyledCreateProtocolResult = styled.div`
  margin-bottom: 2rem;
  .dual {
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  .previous-protocol-toggle {
    margin-bottom: 1rem;
  }
  .previous-protocol-box {
    margin-bottom: 1rem;
    background: ${colors.lightGrey};
    padding: 0.5rem;
    p {
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
`;

type Props = {
  result: ProtocolFM['resultsInner'][0];
  resultIndex: number;
  protocolData: Partial<ProtocolFM>;
  setProtocolData: (data: Partial<ProtocolFM>) => void;
  mainKey: 'resultsInner' | 'resultsOuter';
  previousProtocol?: ProtocolFM['resultsInner'][0];
  protocolOptions: ProtocolOptionBM['keys'][0][] | null;
  type?: string;
  context: MediaContextSelection;
};

const CreateProtocolResult = ({
  result,
  resultIndex,
  protocolData,
  setProtocolData,
  mainKey,
  previousProtocol,
  protocolOptions,
  type,
  context,
}: Props) => {
  const [showPrevReport, setShowPrevReport] = useState(false);
  const [showPrevRecommendation, setShowPrevRecommendation] = useState(false);

  const handleDelete = () => {
    const tempResults = [...protocolData[mainKey]!];
    tempResults[resultIndex].image = '';
    setProtocolData({
      ...protocolData,
      [mainKey]: tempResults,
    });
  };

  const handleChange = (src: string) => {
    const tempResults = [...protocolData[mainKey]!];
    tempResults[resultIndex].image = src;
    setProtocolData({
      ...protocolData,
      [mainKey]: tempResults,
    });
  };

  return (
    <StyledCreateProtocolResult>
      <div className="dual">
        <div>
          <p>{result.placeholder}</p>
          <textarea
            placeholder={result.placeholder}
            value={result.report}
            onChange={(e) => {
              let tempResultsInner = [...protocolData[mainKey]!];
              tempResultsInner[resultIndex].report = e.target.value;
              setProtocolData({
                ...protocolData,
                [mainKey]: tempResultsInner,
              });
            }}
          />
          {protocolOptions?.length ? (
            //eslint-disable-next-line jsx-a11y/no-onchange
            <select
              onChange={(e) => {
                let tempResultsInner = [...protocolData[mainKey]!];
                tempResultsInner[resultIndex].report =
                  tempResultsInner[resultIndex].report + ' ' + e.target.value;
                setProtocolData({
                  ...protocolData,
                  [mainKey]: tempResultsInner,
                });
              }}
            >
              <option value="">Alternativ</option>
              {protocolOptions
                .filter(
                  (p) => p.key === protocolData[mainKey]![resultIndex].key
                )[0]
                ?.results.map((option, i) => (
                  <option key={'result' + option + i}>{option}</option>
                ))}
            </select>
          ) : null}
          {!!previousProtocol?.report?.length && (
            <div className="previous-protocol-toggle">
              <Button
                type="button"
                noBg
                onClick={() => setShowPrevReport(!showPrevReport)}
              >
                {showPrevReport ? 'Göm' : 'Visa'} föregående
              </Button>
            </div>
          )}
          {!!previousProtocol?.report?.length && showPrevReport && (
            <div className="previous-protocol-box">
              <p>{previousProtocol.report}</p>
              <Button
                type="button"
                noBg
                onClick={() => {
                  let tempResultsInner = [...protocolData[mainKey]!];
                  tempResultsInner[resultIndex].report =
                    previousProtocol.report;
                  setShowPrevReport(false);
                }}
              >
                Infoga
              </Button>
            </div>
          )}
        </div>
        {type !== 'RR' && type !== 'MR' ? (
          <div>
            <p>Rekommendation</p>
            <textarea
              placeholder="Rekommendation"
              value={result.recommendation}
              onChange={(e) => {
                let tempResultsInner = [...protocolData[mainKey]!];
                tempResultsInner[resultIndex].recommendation = e.target.value;
                setProtocolData({
                  ...protocolData,
                  [mainKey]: tempResultsInner,
                });
              }}
            />
            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
            <select
              onChange={(e) => {
                let tempResultsInner = [...protocolData[mainKey]!];
                tempResultsInner[resultIndex].recommendation =
                  tempResultsInner[resultIndex].recommendation +
                  ' ' +
                  e.target.value;
                setProtocolData({
                  ...protocolData,
                  [mainKey]: tempResultsInner,
                });
              }}
            >
              <option value="">Alternativ</option>
              {(protocolOptions || [])
                .filter(
                  (p) => p.key === protocolData[mainKey]![resultIndex].key
                )[0]
                ?.recommendations.map((option) => (
                  <option key={'recommendation' + option}>{option}</option>
                ))}
            </select>
            {!!previousProtocol?.recommendation?.length && (
              <div className="previous-protocol-toggle">
                <Button
                  type="button"
                  noBg
                  onClick={() =>
                    setShowPrevRecommendation(!showPrevRecommendation)
                  }
                >
                  {showPrevReport ? 'Göm' : 'Visa'} föregående
                </Button>
              </div>
            )}
            {!!previousProtocol?.recommendation?.length &&
              showPrevRecommendation && (
                <div className="previous-protocol-box">
                  <p>{previousProtocol.recommendation}</p>
                  <Button
                    type="button"
                    noBg
                    onClick={() => {
                      let tempResultsInner = [...protocolData[mainKey]!];
                      tempResultsInner[resultIndex].recommendation =
                        previousProtocol.recommendation;
                      setShowPrevRecommendation(false);
                    }}
                  >
                    Infoga
                  </Button>
                </div>
              )}
          </div>
        ) : null}
      </div>
      {type !== 'RR' && type !== 'MR' && (
        <ProtocolImage
          context={context}
          handleDelete={handleDelete}
          onChange={handleChange}
          src={result.image}
        />
      )}
      <hr />
    </StyledCreateProtocolResult>
  );
};

export default CreateProtocolResult;
