import { ProtocolOptionBM } from '@bm-js/h2o-shared';

export enum ProtocolType {
  INSP = 'insp',
  KONT = 'kont',
  MR = 'MR',
  RR = 'RR',
}

type ProtocolOptionKey = ProtocolOptionBM['keys'][0];
export type AdminProtocolOptionsType = Record<
  ProtocolType,
  ProtocolOptionKey[]
>;
