import styled from 'styled-components';
import { allCounties } from '../cnst/data.cnst';
import Button from './Button';
import { CustomerBM } from '@bm-js/h2o-shared';
import StringArrayInput from './StringArrayInput';
import { useEffect, useState } from 'react';

const StyledCustomerForm = styled.form`
  .customer-form-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .dual {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .customer-form-part {
    flex-direction: column;
    display: flex;
    margin-bottom: 1.5rem;
    p {
      font-size: 1.2rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
  .customer-form-array {
    flex-direction: column;
    display: flex;
    margin-bottom: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: 1000px) {
    .customer-form-inner {
      grid-template-columns: 1fr;
    }
  }
`;

type Props = {
  formData?: Partial<CustomerBM>;
  handleSubmit: (v: Partial<CustomerBM>) => void;
};

const CustomerForm = (props: Props) => {
  const [formData, setFormData] = useState<Partial<CustomerBM>>({
    mainComment: '',
    customerInformation: {
      name: '',
      postalAdress: '',
      zipCode: '',
      postalArea: '',
      county: '',
      phone: '',
    },
    invoiceInformation: {
      email: '',
      name: '',
      postalAdress: '',
      zipCode: '',
      postalArea: '',
      reference: '',
      markings: [],
      eInvoiceInfo: [],
      invoiceMethod: '',
      anbudNumbers: [],
    },
    deliveryInformation: {
      name: '',
      postalAdress: '',
      zipCode: '',
      postalArea: '',
    },
    visitorInformation: {
      name: '',
      postalAdress: '',
      zipCode: '',
      postalArea: '',
    },
    contactPersons: [
      {
        name: '',
        title: '',
        phone: '',
        mobile: '',
        email: '',
      },
    ],
  });

  useEffect(() => {
    if (props.formData) {
      setFormData(props.formData);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledCustomerForm
      onSubmit={(e) => {
        e.preventDefault();
        props.handleSubmit(formData);
      }}
    >
      <Button type="submit">Spara</Button>
      <div style={{ height: 16 }} />
      <div className="customer-form-inner">
        <div className="customer-form-part">
          <p>Kundinformation</p>
          <input
            type="text"
            name="name"
            value={formData.customerInformation!.name}
            required
            placeholder="Namn"
            onChange={(e) => {
              setFormData({
                ...formData,
                customerInformation: {
                  ...formData.customerInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />

          <input
            type="text"
            name="postalAdress"
            value={formData.customerInformation!.postalAdress}
            placeholder="Postadress"
            onChange={(e) => {
              setFormData({
                ...formData,
                customerInformation: {
                  ...formData.customerInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />
          <div className="dual">
            <input
              type="text"
              name="zipCode"
              value={formData.customerInformation!.zipCode}
              placeholder="Postnummer"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  customerInformation: {
                    ...formData.customerInformation!,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
            <input
              type="text"
              name="postalArea"
              value={formData.customerInformation!.postalArea}
              placeholder="Postort"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  customerInformation: {
                    ...formData.customerInformation!,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
          </div>
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            name="county"
            value={formData.customerInformation!.county}
            onChange={(e) => {
              setFormData({
                ...formData,
                customerInformation: {
                  ...formData.customerInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          >
            <option disabled value="">
              Välj ett län
            </option>
            {allCounties.map((county) => (
              <option value={county} key={county}>
                {county}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="phone"
            value={formData.customerInformation!.phone}
            placeholder="Telefon (växel)"
            onChange={(e) => {
              setFormData({
                ...formData,
                customerInformation: {
                  ...formData.customerInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />
        </div>
        <div className="customer-form-part">
          <p>Fakturainformation</p>
          <input
            type="text"
            name="name"
            value={formData.invoiceInformation!.name}
            placeholder="Namn"
            onChange={(e) => {
              setFormData({
                ...formData,
                invoiceInformation: {
                  ...formData.invoiceInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />

          <input
            type="text"
            name="postalAdress"
            value={formData.invoiceInformation!.postalAdress}
            placeholder="Postadress"
            onChange={(e) => {
              setFormData({
                ...formData,
                invoiceInformation: {
                  ...formData.invoiceInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />
          <div className="dual">
            <input
              type="text"
              name="zipCode"
              value={formData.invoiceInformation!.zipCode}
              placeholder="Postnummer"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  invoiceInformation: {
                    ...formData.invoiceInformation!,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
            <input
              type="text"
              name="postalArea"
              value={formData.invoiceInformation!.postalArea}
              placeholder="Postort"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  invoiceInformation: {
                    ...formData.invoiceInformation!,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
          </div>

          <input
            type="email"
            name="email"
            value={formData.invoiceInformation!.email}
            placeholder="E-post"
            onChange={(e) => {
              setFormData({
                ...formData,
                invoiceInformation: {
                  ...formData.invoiceInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />
          <select
            name="invoiceMethod"
            value={formData.invoiceInformation!.invoiceMethod}
            onChange={(e) => {
              setFormData({
                ...formData,
                invoiceInformation: {
                  ...formData.invoiceInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          >
            <option value="">Faktureringsmetod (ej vald)</option>
            <option>E-faktura</option>
            <option>E-post</option>
            <option>Brev</option>
          </select>
          {formData.invoiceInformation?.invoiceMethod === 'E-faktura' && (
            <textarea
              name="eInvoiceInfo"
              value={formData.invoiceInformation!.eInvoiceInfo.join('\n')}
              placeholder="Uppgifter E-fakturing"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  invoiceInformation: {
                    ...formData.invoiceInformation!,
                    [e.target.name]: e.target.value.split('\n'),
                  },
                });
              }}
            />
          )}
          <StringArrayInput
            value={formData.invoiceInformation!.anbudNumbers}
            name="anbudNumbers"
            label="Anbudsnummer"
            onChange={(e) => {
              setFormData({
                ...formData,
                invoiceInformation: {
                  ...formData.invoiceInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />
          <StringArrayInput
            value={formData.invoiceInformation!.markings}
            name="markings"
            label="Märkningar"
            onChange={(e) => {
              setFormData({
                ...formData,
                invoiceInformation: {
                  ...formData.invoiceInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />
        </div>
        <div className="customer-form-part">
          <p>Leveransinformation</p>
          <input
            type="text"
            name="name"
            value={formData.deliveryInformation!.name}
            placeholder="Namn"
            onChange={(e) => {
              setFormData({
                ...formData,
                deliveryInformation: {
                  ...formData.deliveryInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />
          <input
            type="text"
            name="postalAdress"
            value={formData.deliveryInformation!.postalAdress}
            placeholder="Postadress"
            onChange={(e) => {
              setFormData({
                ...formData,
                deliveryInformation: {
                  ...formData.deliveryInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />
          <div className="dual">
            <input
              type="text"
              name="zipCode"
              value={formData.deliveryInformation!.zipCode}
              placeholder="Postnummer"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  deliveryInformation: {
                    ...formData.deliveryInformation!,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
            <input
              type="text"
              name="postalArea"
              value={formData.deliveryInformation!.postalArea}
              placeholder="Postort"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  deliveryInformation: {
                    ...formData.deliveryInformation!,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="customer-form-part">
          <p>Besöksadress</p>
          <input
            type="text"
            name="name"
            value={formData.visitorInformation!.name}
            placeholder="Namn"
            onChange={(e) => {
              setFormData({
                ...formData,
                visitorInformation: {
                  ...formData.visitorInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />
          <input
            type="text"
            name="postalAdress"
            value={formData.visitorInformation!.postalAdress}
            placeholder="Postadress"
            onChange={(e) => {
              setFormData({
                ...formData,
                visitorInformation: {
                  ...formData.visitorInformation!,
                  [e.target.name]: e.target.value,
                },
              });
            }}
          />
          <div className="dual">
            <input
              type="text"
              name="zipCode"
              value={formData.visitorInformation!.zipCode}
              placeholder="Postnummer"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  visitorInformation: {
                    ...formData.visitorInformation!,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
            <input
              type="text"
              name="postalArea"
              value={formData.visitorInformation!.postalArea}
              placeholder="Postort"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  visitorInformation: {
                    ...formData.visitorInformation!,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="customer-form-part">
          <p>Kontaktpersoner</p>
          {formData.contactPersons!.map((person, index) => (
            <div className="customer-form-array" key={'person' + index}>
              <input
                type="text"
                name="name"
                value={person.name}
                placeholder="Namn"
                onChange={(e) => {
                  const tempPerson = { ...person };
                  const tempPersons = [...formData.contactPersons!];
                  tempPerson.name = e.target.value;
                  tempPersons[index] = tempPerson;
                  setFormData({
                    ...formData,
                    contactPersons: tempPersons,
                  });
                }}
              />
              <input
                type="text"
                name="title"
                value={person.title}
                placeholder="Titel"
                onChange={(e) => {
                  const tempPerson = { ...person };
                  const tempPersons = [...formData.contactPersons!];
                  tempPerson.title = e.target.value;
                  tempPersons[index] = tempPerson;
                  setFormData({
                    ...formData,
                    contactPersons: tempPersons,
                  });
                }}
              />
              <input
                type="text"
                name="phone"
                value={person.phone}
                placeholder="Telefon"
                onChange={(e) => {
                  const tempPerson = { ...person };
                  const tempPersons = [...formData.contactPersons!];
                  tempPerson.phone = e.target.value;
                  tempPersons[index] = tempPerson;
                  setFormData({
                    ...formData,
                    contactPersons: tempPersons,
                  });
                }}
              />
              <input
                type="text"
                name="mobile"
                value={person.mobile}
                placeholder="Mobil"
                onChange={(e) => {
                  const tempPerson = { ...person };
                  const tempPersons = [...formData.contactPersons!];
                  tempPerson.mobile = e.target.value;
                  tempPersons[index] = tempPerson;
                  setFormData({
                    ...formData,
                    contactPersons: tempPersons,
                  });
                }}
              />
              <input
                type="text"
                name="email"
                value={person.email}
                placeholder="E-post"
                onChange={(e) => {
                  const tempPerson = { ...person };
                  const tempPersons = [...formData.contactPersons!];
                  tempPerson.email = e.target.value;
                  tempPersons[index] = tempPerson;
                  setFormData({
                    ...formData,
                    contactPersons: tempPersons,
                  });
                }}
              />
              {formData.contactPersons!.length > 1 ? (
                <Button
                  med
                  type="button"
                  look="danger"
                  onClick={() => {
                    const tempPersons = [...formData.contactPersons!];
                    tempPersons.splice(index, 1);
                    setFormData({
                      ...formData,
                      contactPersons: tempPersons,
                    });
                  }}
                >
                  Ta bort
                </Button>
              ) : null}
            </div>
          ))}
          <Button
            med
            type="button"
            onClick={() => {
              const tempPersons = [...formData.contactPersons!];
              tempPersons.push({
                name: '',
                title: '',
                phone: '',
                mobile: '',
                email: '',
              });
              setFormData({
                ...formData,
                contactPersons: tempPersons,
              });
            }}
          >
            Lägg till kontaktperson
          </Button>
        </div>
      </div>
      <Button type="submit">Spara</Button>
    </StyledCustomerForm>
  );
};

export default CustomerForm;
