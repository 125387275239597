import styled from 'styled-components';

const StyledPlus = styled.div<{
  $cross?: boolean;
  $minus?: boolean;
  $color?: string;
}>`
  height: 15px;
  width: 15px;
  position: relative;
  ${({ $cross }) => ($cross ? '' : 'transform: rotateZ(45deg);')}
  div {
    transition: 200ms ease-in-out;
    position: absolute;
    width: 1.5px;
    height: 15px;
    background: ${({ $color }) => ($color ? $color : '#000')};

    &:nth-child(1) {
      left: calc(50% - 1px);
      transform: rotateZ(45deg);
    }
    &:nth-child(2) {
      left: calc(50% - 1px);
      transform: ${({ $minus }) =>
        $minus ? 'rotateZ(45deg)' : 'rotateZ(-45deg)'};
      opacity: ${({ $minus }) => ($minus ? 0 : 1)};
    }
  }
`;

type Props = {
  cross?: boolean;
  color?: string;
  minus?: boolean;
  className?: string;
};

const Plus = ({ className, color, cross, minus }: Props) => {
  return (
    <StyledPlus
      $cross={cross}
      $color={color}
      $minus={minus}
      className={className}
    >
      <div></div>
      <div></div>
    </StyledPlus>
  );
};

export default Plus;
