import {
  ChangeEventHandler,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { progressRequest, request } from '../utils/request';
import Button from './Button';
import FileCard, { MediaContext } from './FileCard';
import MediaLibraryHeader from './MediaLibraryHeader';
import FileContextSelector from './FileContextSelector';
import OverlayBox from './OverlayBox';
import { getProtocolString } from '../utils/getProtocolFilename.util';
import { protocolMap } from '../cnst/data.cnst';
import { colors } from '../cnst/colors.cnst';
import Switch from './Switch';
import { MediaItemFM } from '@bm-js/h2o-shared';

const StyledMediaLibrary = styled.div`
  #uploadInput,
  #uploadInputCam {
    display: none;
  }
  .filters-container {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    .filters-container-inner-lower {
      display: flex;
      align-items: center;
      justify-content: space-between;
      select {
        width: 200px;
      }
      .upload-button-container {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
      }
    }
  }
  .batch-actions-container {
    display: none;
    margin-top: 1rem;
    p {
      font-weight: 700;
    }
    &.active {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    &-actions {
      display: flex;
      gap: 0.5rem;
    }
  }
  ul {
    margin-top: 1rem;
  }
  .multiselect-button-container {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 1000px) {
    .hide-mobile {
      display: none;
    }
    .filters-container {
      .filters-container-inner-lower {
        display: block;
        select {
          width: 100%;
        }
        .upload-button-container {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .batch-actions-container {
      &.active {
        display: block;
        text-align: center;
        p {
          margin-bottom: 0.5rem;
        }
      }
      &-actions {
        flex-direction: column;
      }
    }
  }
`;

const acceptTypeMap = {
  image: 'image/*',
};

export type MediaContextSelection = {
  customer?: string;
  reservoir?: string;
  protocol?: string;
};

const emptyContext: MediaContextSelection = {
  customer: '',
  reservoir: '',
  protocol: '',
};

type Props = {
  context: MediaContextSelection;
  onSelect?: (v: MediaItemFM[] | MediaItemFM) => void;
  type?: string;
  disableContextSwitching?: boolean;
  multiple?: boolean;
};

const MediaLibrary = ({
  context: inputContext,
  onSelect,
  type: preselectedType,
  disableContextSwitching,
  multiple = false,
}: Props) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [contexts, setContexts] = useState<MediaContext[]>([]);
  const [contextSelection, setContextSelection] = useState(
    inputContext || emptyContext
  );
  const [filesList, setFilesList] = useState<MediaItemFM[]>([]);
  const [type, setType] = useState(preselectedType);
  const [loadingFilesList, setLoadingFilesList] = useState(true);
  const [sort, setSort] = useState('uploadedAt:desc');
  const [changeFileContextModal, setChangeFileContextModal] = useState({
    active: false,
    context: emptyContext,
    updateFilename: true,
    files: [] as string[],
  });
  const [batchSelection, setBatchSelection] = useState<string[]>([]);
  const [batchDeleteModal, setBatchDeleteModal] = useState({
    active: false,
    usedFiles: [] as string[],
  });

  const getContexts = async () => {
    const { err, data } = await request<MediaContext[]>({
      state,
      dispatch,
      path: `media/contexts`,
      errorText: 'Kunde inte hämta filer',
    });

    if (err || !data) return;
    setContexts(data);
  };

  const query = useMemo(() => {
    let queryString = (
      Object.keys(contextSelection) as (keyof typeof contextSelection)[]
    )
      .map((key) => `${key}=${contextSelection[key]}`)
      .join('&');
    if (type) queryString += `&type=${type}`;
    return queryString;
  }, [contextSelection, type]);

  const getFilesList = async () => {
    if (!contextSelection.customer) return setFilesList([]);
    setLoadingFilesList(true);
    const { err, data } = await request<MediaItemFM[]>({
      state,
      dispatch,
      path: `media/{token}?${query}`,
      isDoServer: true,
      errorText: 'Kunde inte hämta filer',
    });
    if (err || !data) return setLoadingFilesList(false);
    setFilesList(data);
    setBatchSelection([]);
    setLoadingFilesList(false);
  };

  const uploadInit = (isCam: boolean) => {
    const inputElement = document.getElementById(
      isCam ? 'uploadInputCam' : 'uploadInput'
    );
    if (!inputElement) return;
    inputElement.click();
  };

  const getPlainTextContext = (_context?: MediaContextSelection) => {
    const _contextSelection = _context || contextSelection;
    const textContext = {
      customer: '',
      reservoir: '',
      protocol: '',
    };
    if (!_contextSelection.customer) return textContext;
    const customerContext = contexts.find(
      (c) => c._id === _contextSelection.customer
    );
    if (!customerContext) return textContext;
    textContext.customer = customerContext.name;

    if (!_contextSelection.reservoir) return textContext;
    const reservoirContext = customerContext.reservoirs.find(
      (r) => r._id === _contextSelection.reservoir
    );
    if (!reservoirContext) return textContext;
    textContext.reservoir = reservoirContext.name;

    if (!_contextSelection.protocol) return textContext;
    const protocolContext = reservoirContext.protocols.find(
      (p) => p._id === _contextSelection.protocol
    );
    if (!protocolContext) return textContext;
    textContext.protocol = getProtocolString(
      protocolContext.dateForInspection,
      protocolMap[protocolContext.type]
    );

    return textContext;
  };

  const afterUpload = (ids: string[]) => {
    const cards = ids.map((id) => document.getElementById(`fileCard_${id}`));
    cards.forEach((card) => {
      if (card) {
        card.style.outline = `1px solid ${colors.lightBlue}`;
      }
    });
    if (cards[0]) {
      cards[0].scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  const getFilenameFromUuid = (uuid: string) => {
    return filesList.find((file) => file.uuid === uuid)?.name || '-';
  };

  const handleUpload: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const { files } = e.target;
    if (!files) return;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append(file.name, file);
    }
    const fileContext = JSON.stringify(getPlainTextContext());
    formData.append('__fileContext__', fileContext);
    const { data: ids } = await progressRequest<string[]>({
      state,
      dispatch,
      path: `media/{token}?${query}`,
      method: 'POST',
      errorText: 'Kunde inte ladda upp filer',
      body: formData,
    });
    const inputElement =
      document.querySelector<HTMLInputElement>('#uploadInput');
    if (inputElement) {
      inputElement.value = '';
    }
    await getFilesList();
    if (ids) {
      afterUpload(ids);
    }
  };

  const deleteFile = async (uuid: string) => {
    await request({
      state,
      dispatch,
      isDoServer: true,
      path: `media/{token}/${uuid}`,
      method: 'DELETE',
      successText: 'Filen raderades',
    });
    getFilesList();
  };

  const changeFilename = async (uuid: string, name: string, cb: () => void) => {
    const { err } = await request({
      state,
      dispatch,
      isDoServer: true,
      method: 'PUT',
      body: { name },
      path: `media/{token}/${uuid}`,
      successText: 'Filnamn uppdaterat',
    });
    if (err) return;
    cb();
    getFilesList();
  };

  const changeFileContextInit = (file: MediaItemFM) => {
    setChangeFileContextModal({
      active: true,
      updateFilename: true,
      files: [file.uuid],
      context: {
        customer: file.customer || '',
        reservoir: file.reservoir || '',
        protocol: file.protocol || '',
      },
    });
  };

  const changeFileContext = async () => {
    if (!changeFileContextModal.files.length) return;

    let path = `media/{token}/batch/context`;
    if (changeFileContextModal.updateFilename) path += '?updateFilename=true';
    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      isDoServer: true,
      path,
      body: {
        plainTextContext: getPlainTextContext(changeFileContextModal.context),
        context: changeFileContextModal.context,
        uuids: changeFileContextModal.files,
      },
      successText: 'Koppling uppdaterad',
    });
    if (err) return;
    setChangeFileContextModal({
      active: false,
      updateFilename: true,
      files: [],
      context: emptyContext,
    });
    getFilesList();
  };

  const batchChangeContextInit = () => {
    const file = filesList.find((file) => file.uuid === batchSelection[0]);
    setChangeFileContextModal({
      active: true,
      updateFilename: true,
      files: [...batchSelection],
      context: {
        customer: file?.customer || '',
        reservoir: file?.reservoir || '',
        protocol: file?.protocol || '',
      },
    });
  };

  const batchDeleteInit = async () => {
    const { err, data } = await request<string[]>({
      state,
      dispatch,
      method: 'POST',
      path: 'media/batch/is-used',
      body: batchSelection,
    });
    if (err || !data) return;
    setBatchDeleteModal({ active: true, usedFiles: data });
  };

  const batchDelete = async () => {
    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      path: 'media/{token}/batch/delete',
      body: batchSelection,
      isDoServer: true,
      successText: 'Filerna raderades',
    });
    if (err) return;
    setBatchDeleteModal({ active: false, usedFiles: [] });
    getFilesList();
  };

  const onSelectMultiple = () => {
    const files = batchSelection
      .map((id) => filesList.find((file) => file.uuid === id))
      .filter((f) => f !== undefined);
    onSelect?.(files);
  };

  const batchSelectAllFiles = () => {
    const allUuids = filesList.map((file) => file.uuid);
    setBatchSelection(allUuids);
  };

  const sortedFiles = useMemo(() => {
    const sortName = (a: MediaItemFM, b: MediaItemFM) =>
      a.name.localeCompare(b.name, 'sv');
    const sortDate = (a: MediaItemFM, b: MediaItemFM) =>
      new Date(a.uploadedAt).getTime() - new Date(b.uploadedAt).getTime();
    const sortSize = (a: MediaItemFM, b: MediaItemFM) => a.size - b.size;
    const sorters = {
      name: sortName,
      uploadedAt: sortDate,
      size: sortSize,
    };
    const [key, dir] = sort.split(':');
    const sorted = [...filesList].sort(
      sorters[key as 'name' | 'uploadedAt' | 'size']
    );
    if (dir === 'asc') return sorted;
    return [...sorted].reverse();
  }, [filesList, sort]);

  const uploadDisabled = useMemo(() => {
    return !(contextSelection.customer && contextSelection.reservoir);
  }, [contextSelection]);

  useEffect(() => {
    if (state.token) getFilesList();
    //eslint-disable-next-line
  }, [query, state.token]);

  useEffect(() => {
    if (state.token) getContexts();
    //eslint-disable-next-line
  }, [state.token]);
  return (
    <StyledMediaLibrary>
      <input
        id="uploadInput"
        type="file"
        multiple
        onChange={handleUpload}
        accept={(type && acceptTypeMap[type as 'image']) || '*/*'}
      />
      <input
        id="uploadInputCam"
        capture="environment"
        type="file"
        multiple
        onChange={handleUpload}
      />
      <div className="filters-container">
        <p className="mb-4">
          Välj kund, reservoar och protokoll för att visa filer för en specifik
          kund, reservoar eller protokoll. När ett val har gjorts i
          rullgardinsmenyerna så laddas nya filer upp på vald kund, reservoar
          eller protokoll.
        </p>
        <FileContextSelector
          isModal={!!onSelect}
          disableContextSwitching={disableContextSwitching}
          contexts={contexts}
          contextSelection={contextSelection}
          setContextSelection={setContextSelection}
        />
        <div className="filters-container-inner-lower">
          <select
            value={type}
            disabled={!!preselectedType}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="">Alla filer</option>
            <option value="image">Bilder</option>
            <option value="pdf">PDF</option>
            <option value="video">Video</option>
          </select>
          <div className="upload-button-container">
            <Button
              small
              onClick={() => uploadInit(true)}
              disabled={uploadDisabled}
            >
              Ta foto
            </Button>
            <Button
              small
              onClick={() => uploadInit(false)}
              disabled={uploadDisabled}
            >
              Ladda upp filer
            </Button>
          </div>
        </div>
      </div>
      <div
        className={`batch-actions-container ${
          !multiple && batchSelection.length ? 'active' : ''
        }`}
      >
        <p>Åtgärder för markerade filer:</p>
        <div className="batch-actions-container-actions">
          <Button small onClick={() => setBatchSelection([])}>
            Avmarkera alla
          </Button>
          <Button small onClick={() => batchSelectAllFiles()}>
            Markera alla
          </Button>
          <Button small onClick={batchChangeContextInit}>
            Ändra koppling
          </Button>
          <Button small look="danger" onClick={batchDeleteInit}>
            Radera
          </Button>
        </div>
      </div>
      {sortedFiles.length && !loadingFilesList ? (
        <MediaLibraryHeader
          setSort={setSort}
          sort={sort}
          onSelect={!!onSelect}
        />
      ) : (
        <p className="mt-4">
          {contextSelection.customer
            ? 'Inga filer hittades'
            : 'Välj en kund för att visa filer'}
        </p>
      )}
      {sortedFiles.map((file) => (
        <FileCard
          changeFileContextInit={changeFileContextInit}
          contexts={contexts}
          key={file._id}
          file={file}
          deleteFile={deleteFile}
          changeFilename={changeFilename}
          multiple={multiple}
          onSelect={onSelect}
          batchSelection={batchSelection}
          setBatchSelection={setBatchSelection}
        />
      ))}
      {multiple && (
        <div className="multiselect-button-container">
          <Button
            small
            disabled={!batchSelection.length}
            onClick={onSelectMultiple}
          >
            Ladda upp filer
          </Button>
        </div>
      )}
      {changeFileContextModal.active && (
        <OverlayBox
          header={
            changeFileContextModal.files.length <= 1
              ? 'Ändra koppling'
              : `Ändra kopping för ${changeFileContextModal.files.length} filer`
          }
          active
          close={() =>
            setChangeFileContextModal({
              ...changeFileContextModal,
              active: false,
            })
          }
        >
          <FileContextSelector
            isChangeContext
            contexts={contexts}
            disableContextSwitching={false}
            contextSelection={changeFileContextModal.context}
            setContextSelection={(e) => {
              setChangeFileContextModal({
                ...changeFileContextModal,
                context: e,
              });
            }}
          />
          <Switch
            name="updatefilename"
            checked={changeFileContextModal.updateFilename}
            onChange={(e) =>
              setChangeFileContextModal({
                ...changeFileContextModal,
                updateFilename: e.target.checked,
              })
            }
            secondPlaceholder="Uppdatera filnamn till ny koppling"
          />
          <Button small onClick={changeFileContext}>
            Spara
          </Button>
        </OverlayBox>
      )}
      {batchDeleteModal.active && (
        <OverlayBox
          active
          header={
            batchDeleteModal.usedFiles.length
              ? `${batchDeleteModal.usedFiles.length} filer används`
              : `Radera ${batchSelection.length} filer?`
          }
          close={() => setBatchDeleteModal({ active: false, usedFiles: [] })}
        >
          {batchDeleteModal.usedFiles.length ? (
            <>
              <p>
                Följande filer används och kan därför inte raderas. Avmarkera
                dessa filer och försök igen.
              </p>
              <ul>
                {batchDeleteModal.usedFiles.map((uuid) => (
                  <li key={`batchUsedIn_${uuid}`}>
                    {getFilenameFromUuid(uuid)}
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <>
              <p>
                Är du säker på att du vill radera följande filer? Det går inte
                att återskapa raderade filer.
              </p>
              <ul>
                {batchSelection.map((uuid) => (
                  <li key={`batchdeleteconf_${uuid}`}>
                    {getFilenameFromUuid(uuid)}
                  </li>
                ))}
              </ul>
              <Button small look="danger" onClick={batchDelete}>
                Radera {batchSelection.length} filer
              </Button>
              <Button
                small
                onClick={() =>
                  setBatchDeleteModal({ active: false, usedFiles: [] })
                }
              >
                Avbryt
              </Button>
            </>
          )}
        </OverlayBox>
      )}
    </StyledMediaLibrary>
  );
};

export default MediaLibrary;
