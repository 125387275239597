import DocumentPage, { DocumentPageContext } from '../components/DocumentPage';
import { ServicelogV2BM } from '@bm-js/h2o-shared';
import ServicelogCard from '../components/ServicelogCardV2';

const context: DocumentPageContext<ServicelogV2BM> = {
  documentName: 'Servicelogg',
  documentNameSpecific: 'Serviceloggen',
  documentKey: 'servicelog',
  documentKeyPlural: 'servicelogs',
  documentNamePlural: 'Serviceloggar',
  internalPersonKey: 'sellerReferences',
  contactPersonKey: 'customerReferences',
  pdfNeedsFullCustomer: true,
  documentIdentifier: 'offerNumber',
  displayFileUploads: false,
  cardComponent: ServicelogCard,
  internalPersonKeyRequired: true,
  cardGridTemplate: '1fr 1fr 1fr 1fr 1fr 20px',
  cardHeaders: [
    {
      text: 'Offertnummer',
      mobile: true,
    },
    {
      text: 'Offertdatum',
    },
    {
      text: 'Antal rader',
    },
    {
      text: 'Summa',
    },
    {
      text: 'Status',
      mobile: true,
    },
  ],
};

const ServicelogsRoute = () => {
  return <DocumentPage context={context} />;
};

export default ServicelogsRoute;
