import { BrowserRouter } from 'react-router';
import Layout from './components/Layout';
import Router from './routes/Router';
import GlobalContextProvider from './context/GlobalContextProvider';
import LoaderOverlay from './components/LoaderOverlay';
import Alert from './components/Alert';
import LinkPreventPopup from './components/LinkPreventPopup';

const App = () => {
  return (
    <BrowserRouter>
      <GlobalContextProvider>
        <Alert />
        <LoaderOverlay />
        <Layout>
          <Router />
        </Layout>
        <LinkPreventPopup />
      </GlobalContextProvider>
    </BrowserRouter>
  );
};

export default App;
