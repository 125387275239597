import { OfferBM } from '@bm-js/h2o-shared';
import { useMemo } from 'react';
import { serviceOptions } from '../cnst/data.cnst';
import Plus from './Plus';
import { parseSEK } from '../utils/parseSEK.util';
import { colors } from '../cnst/colors.cnst';
import Button from './Button';
import styled from 'styled-components';

const StyledOfferFormTable = styled.div`
  .service-row {
    .row-sum {
      align-self: center;
      margin-bottom: 1rem;
      text-align: center;
    }
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr 1fr 20px;
    column-gap: 0.5rem;
    align-items: flex-start;
    p {
      margin: 0;
      font-weight: 12px;
      margin-bottom: 0.3rem;
    }
    .service-input-wrapper {
      position: relative;
      padding-right: 1rem;
      .cross {
        display: none;
        cursor: pointer;
        position: absolute;
        align-items: center;
        height: calc(100% - 0.5rem);
        top: 0;
        display: flex;
        right: 0rem;
      }
    }
    input,
    select,
    textarea {
      margin-bottom: 0.5rem;
      font-size: 12px;
      padding: 0.8rem;
    }
  }

  @media (max-width: 1000px) {
    .service-row {
      grid-template-columns: 3fr 3fr 2fr;
      &.headers {
        display: none;
      }
      &.user-row {
        margin-bottom: 1rem;
        position: relative;
        textarea {
          grid-column: 1 / 3;
        }
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1px;
          width: 100%;
          width: 100%;
          background: ${colors.lightGrey};
          content: '';
        }
      }
    }
  }
`;

type Props<T extends { table: OfferBM['table'] }> = {
  formData: T;
  setFormData: (v: T) => void;
};

const sortedServices = [...serviceOptions].sort((a, b) => {
  if (a.sortBottom) return 1;
  return a.name.localeCompare(b.name, 'sv');
});

const OfferFormTable = <T extends { table: OfferBM['table'] }>({
  formData,
  setFormData,
}: Props<T>) => {
  const totalSum = useMemo(() => {
    const rowSums = formData.table.map((row) => {
      return row.priceExVat * row.quantity;
    });
    const totalSum = rowSums.reduce<number>((acc, curr) => acc + curr, 0);
    return totalSum;
  }, [formData.table]);

  return (
    <StyledOfferFormTable>
      <div className="service-row headers">
        <p>Benämning</p>
        <p>Antal</p>
        <p>Enhet</p>
        <p>Á-pris</p>
        <p className="c">Summa</p>
      </div>

      {formData.table.map((row, i) => (
        <div className="service-row user-row" key={`service_row_${i}`}>
          <div>
            <datalist id="offer-form-datalist">
              {sortedServices.map((service) => (
                <option
                  key={`offer_service_option_${i}_${service.name}`}
                  disabled={service.disabled}
                >
                  {service.name}
                </option>
              ))}
            </datalist>
            <div className="service-input-wrapper">
              <input
                list="offer-form-datalist"
                required
                value={row.service}
                onChange={(e) => {
                  const updated = [...formData.table];
                  updated[i].service = e.target.value;
                  const service = serviceOptions.find(
                    (s) => s.name === e.target.value
                  );
                  if (service) {
                    updated[i].id = service.id as string;
                    updated[i].priceExVat =
                      serviceOptions.find((s) => s.name === e.target.value)
                        ?.priceExVat || 0;
                  }
                  setFormData({
                    ...formData,
                    table: updated,
                  });
                }}
              />
              {/* eslint-disable-next-line */}
              <div
                className="cross"
                onClick={() => {
                  const updated = [...formData.table];
                  updated[i].service = '';
                  updated[i].id = '';
                  updated[i].priceExVat = 0;
                  setFormData({ ...formData, table: updated });
                }}
              >
                <Plus color={'black'} cross />
              </div>
            </div>
          </div>
          <input
            placeholder="Antal"
            type="number"
            value={row.quantity}
            required
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].quantity = e.target.value as unknown as number;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          />

          {/* eslint-disable-next-line */}
          <select
            required
            value={row.unit}
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].service = e.target.value;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          >
            <option value="" disabled>
              Välj enhet
            </option>
            <option>st</option>
          </select>
          <input
            placeholder="Á-pris"
            type="number"
            value={row.priceExVat}
            required
            onChange={(e) => {
              const updated = [...formData.table];
              updated[i].priceExVat = e.target.value as unknown as number;
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          />
          <p className="row-sum">{parseSEK(row.priceExVat * row.quantity)}</p>
          {/* eslint-disable-next-line */}
          <div
            className="pointer asc mbottom1"
            onClick={() => {
              const updated = [...formData.table];
              updated.splice(i, 1);
              setFormData({
                ...formData,
                table: updated,
              });
            }}
          >
            <Plus cross color={colors.red} />
          </div>
        </div>
      ))}

      <Button
        type="button"
        small
        onClick={() => {
          const updated = [...formData.table];
          // @ts-ignore
          updated.push({
            service: '',
            quantity: 1,
            unit: 'st',
            priceExVat: 0,
          });
          setFormData({
            ...formData,
            table: updated,
          });
        }}
      >
        Lägg till rad
      </Button>
      <div className="flex jcfe mright2 mbottom05">
        <p className="m0">
          Summa ex moms: <span>{parseSEK(totalSum)}</span>
        </p>
      </div>
      <div className="flex jcfe mright2">
        <p className="m0">
          Summa ink moms: <span>{parseSEK(totalSum * 1.25)}</span>
        </p>
      </div>
    </StyledOfferFormTable>
  );
};

export default OfferFormTable;
